import React,{Component} from "react";
import {connect} from "react-redux";
import {getDataAut} from "../../util/general";
import {cargarForrosTematicos, asignarForroTematico } from "../../util/asyncForrosTematicos";
import ModalSpinner from "../modales/ModalSpinner";
import {esDefinido} from "../../util/general";
import ModalesError from "../ModalesError";
import {ERROR_CARGAR_FORROS_TEMATICOS,ERROR_ASIGNAR_FORRO_TEMATICO} from "../../util/errores";
import {clearBuckets} from "../../util/images";
class SeleccionForroTematico extends Component{
    
    componentDidMount = ()=>{
        if(!esDefinido(this.props.volumenSeleccionado)){
            this.props.history.push("/proyectos");
        }
        this.props.dispatch(cargarForrosTematicos());
    }
    asignarForroTematico = (forroId)=>{
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        this.props.dispatch(asignarForroTematico(dataAut,this.props.volumenSeleccionado,forroId,()=>{
            clearBuckets(["portadasVol","portadasProy"]);
            this.atras();
        }));
    }
    getModalesError = ()=>{
        let errores = [];
        errores.push(ERROR_CARGAR_FORROS_TEMATICOS(this.props.error === "CARGAR_FORROS_TEMATICOS",
        ()=>{this.props.dispatch({type:"LIMPIAR_ERRORES_FORROS_TEMATICOS"})})
        )
        errores.push(ERROR_ASIGNAR_FORRO_TEMATICO(this.props.error === "ASIGNAR_FORRO_TEMATICO",
            ()=>{
                this.props.dispatch({type:"LIMPIAR_ERRORES_FORROS_TEMATICOS"});
                this.props.dispatch(cargarForrosTematicos());
            }
                )
        )

        return errores;
    }
    atras = ()=>{
        this.props.history.push({
            pathname: '/proyectos/editar',
            state: { 
                from: this.props.location.pathname
            }
          })
    }
    render(){

        return(<div>
            <div className="title-bar">
                        <span className="back-icon" onClick = {this.atras}> </span>
                        SELECCIÓN DE PORTADA TEMÁTICA
                    </div>
            <ModalSpinner show = {this.props.cargando}/>
            <ModalesError modales = {this.getModalesError()}/>
            <div className = " inner-content cont-forro-tematico">
            {this.props.forros.map((forro,index)=>{
                return <div key = {index} 
                        className = "forro-tematico"
                        >
                        <img src = {forro.asset_url} onClick = {(ev)=>this.asignarForroTematico(forro.id)}
                            style ={{height:"100%",width:"100%"}}/>
                    </div>
            })}
            </div>
        </div>);
    }
}
const mapStateToProps = (state)=>{
    return {
        forros:state.forrosTematicos.forros||[],
        token: state.clientes.token,
        uuid: state.clientes.uuid,
        cargando: state.app.cargando,
        volumenSeleccionado: state.proyectos.volumenSeleccionado,
        error: state.forrosTematicos.error

    }
}
export default connect(mapStateToProps)(SeleccionForroTematico);