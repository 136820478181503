export const esDefinido = (valor)=>{
    return valor !== null && typeof valor !== "undefined" 
}
export const vacios = (variables)=>{
    for(let i = 0; i<variables.length;i ++){
        if(!esDefinido(variables[i]) || variables[i]===""){
            return true;

            }
    }
    return false;
}
export const getDataAut=(token,uuid)=>{
    let porToken = esDefinido(token)
    let dataAut = porToken?{token:token}:{uuid: uuid};
    return dataAut;
}