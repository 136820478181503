import {esDefinido} from "./general";
const ERRORES_GENERICOS = [
    {    clave: "AUTENTICACION", status: 401    },
    {   clave:"CONEXION", status: 0     }
]
function esGenerico(status){
    const error = ERRORES_GENERICOS.find(el=>el.status === status);
    return esDefinido(error);
}
function manejarErrorGenerico(status,dispatch){
    let error = ERRORES_GENERICOS.find(el=>el.status === status);
    dispatch({type:error.clave})
}

export const ERROR_PROYECTOS = {
    titulo: "ERROR",
    mensaje: "Ocurrió un error al cargar los proyectos, refresca la página o intenta más tarde. ",
    handleClose: (ev)=>{},
    textoBoton:"",
    showVar: "errores",
    showVal: "PROYECTOS"
}

export const  ERROR_CALIDAD=(show,handleClose)=>{

    return {
        titulo:"ALERTA",
        mensaje:"Algunas fotos no tienen la calidad mínima recomendada.",
        textoBoton:"ACEPTAR",
        handleClose: handleClose,
        show:show
    }
} 
export const ERROR_SUBIR_FOTOS = (show,handleClose)=>{
    return {
        titulo:"ERROR",
        mensaje:"Ocurrió un error en la carga de fotos, intenta de nuevo. ",
        textoBoton: "ACEPTAR",
        show:show,
        handleClose:handleClose

    }
}
export const ERROR_ENTRADA_PROYECTO = (show,handleClose)=>{
    return {
        titulo:"ALERTA",
        mensaje: "No has seleccionado ningún proyecto, selecciónalo en la lista de proyectos. ",
        textoBoton: "ACEPTAR",
        show:show,
        handleClose:handleClose
        }

}
export const ERROR_GUARDAR_CONFIG  = (show,handleClose)=>{
    return{
        titulo: "ERROR",
        mensaje: "Ha ocurrido un error al guardar la configuración, intenta de nuevo.",
        textoBoton: "ACEPTAR",
        show: show,
        handleClose: handleClose
    }
}
export const ERROR_CARGAR_CONFIG = (show,handleClose)=>{
    return {
        textoBoton: "ACEPTAR",
        titulo: "ERROR",
        mensaje:"Ocurrió un error al cargar la configuración.",
        show:show,
        handleClose: handleClose
    }
}
export const ERROR_ELIMINAR_PROY = (show,handleClose)=>{
    return {
        titulo: "ERROR",
        mensaje: "No se pudo eliminar el proyecto, intenta más tarde. ",
        handleClose:handleClose,
        textoBoton: "ACEPTAR",
        show:show
    }
}
export const ERROR_REORDENAMIENTO = (show,handleClose)=>{
    return {
        titulo:"ERROR",
        mensaje:"No se pudo reordenar la foto, intenta de nuevo.",
        handleClose:handleClose, 
        textoBoton: "ACEPTAR",
        show: show
    }    
}
export const ERROR_SELECCION_PORTADA =(show,handleClose)=>{
    return {
        titulo: "ERROR",
        mensaje: "Ocurrió un error al subir la portada, intenta de nuevo.",
        handleClose:handleClose,
        textoBoton: "ACEPTAR",
        show :show
    }
}
export const ERROR_AGREGAR_CARRITO = (show,handleClose)=>{
    return {
        titulo:"ERROR",
        mensaje: "Ocurrió un error al agregar al carrito, intenta de nuevo.",
        handleClose: handleClose,
        textoBoton:"ACEPTAR",
        show:show
    }
}
export const ERROR_FOTO_INDEFINIDA = (show,handleClose)=>{
    return {
        titulo:"ERROR",
        mensaje: "No se ha seleccionado ninguna foto.",
        handleClose:handleClose,                
        textoBoton:"ACEPTAR",
        show:show
    }
}
export const ERROR_ELIMINAR_FOTO = (show,handleClose)=>{
    return {
        titulo:"ERROR ELIMINAR FOTO",
        mensaje:"No se pudo eliminar la foto, intenta de nuevo.",
        handleClose:handleClose,                
        textoBoton: "ACEPTAR",
        show:show
    }
}
export const ERROR_EDITAR_CAPTION = (show,handleClose)=>{
    return {
        titulo:"ERROR",
        mensaje:"No se pudo guardar el caption",
        handleClose: handleClose,
        textoBoton:"ACEPTAR",
        show:show
    }
}
export const ERROR_CARGAR_CARRITO = (show,handleClose)=>{
    return {
        titulo:"ERROR",
        mensaje:"No se pudo cargar el carrito, intenta de nuevo.",
        handleClose:handleClose,
        textoBoton: "ACEPTAR",
        show:show
    }
}
export const ERROR_EDITAR_CANTIDAD = (show,handleClose)=>{
    return {
        titulo:"ERROR",
        mensaje:"Ocurrió un error al actualizar la cantidad de volúmenes.",
        handleClose:handleClose,
        textoBoton: "ACEPTAR",
        show:show
    }
}
export const ERROR_ELIMINAR_PROYECTO_CARRITO = (show,handleClose)=>{
    return {
        titulo:"ERROR",
        mensaje:"No se pudo eliminar el proyecto del carrito",
        handleClose:handleClose,
        textoBoton: "ACEPTAR",
        show:show
    }
}
export const ERROR_NO_VOLUMENES_CARRITO = (show,handleClose)=>{
    return {
        titulo:"ALERTA",
        mensaje: "Debe haber al menos un volumen en el carrito.",
        handleClose: handleClose,
        textoBoton: "ACEPTAR",
        show:show
    }
}
export const ERROR_REGISTRAR_CLIENTE = (show,handleClose,detalle)=>{
    console.log("CREANDO ERROR REGISTRAR CLIENTE");
    console.log(detalle);
    let msj = esDefinido(detalle)? detalle:"No se pudo registrar el cliente nuevo, intenta más tarde";
    return {
        titulo:"ERROR REGISTRAR CLIENTE",
        mensaje:msj,
        handleClose: handleClose,
        show: show,
        textoBoton: "ACEPTAR"
    }
}
export const ERROR_RECUPERAR_CONTRASENA = (show,handleClose)=>{
    return{
        titulo:"ERROR",
        mensaje:"Ocurrió un error, intenta de nuevo.",
        handleClose: handleClose,
        show: show,
        textoBoton: "ACEPTAR"
    }
}
export const ERROR_GUARDAR_DATOS = (show,handleClose)=>{
    return {
        titulo: "ERROR",
        mensaje: "Ocurrió un error al guardar los datos, intenta de nuevo.",
        handleClose:handleClose,
        show:show,
        textoBoton:"ACEPTAR"
    }
}

export const ERROR_CARGAR_DATOS = (show,handleClose)=>{
    return {
        titulo: "ERROR",
        mensaje: "Ocurrió un error al cargar los datos, intenta de nuevo.",
        handleClose:handleClose,
        show:show,
        textoBoton:"ACEPTAR"
    }
}

export const ERROR_CORREO_NO_ENCONTRADO = (show,handleClose)=>{
    return{
        titulo: "ALERTA",
        mensaje: "La dirección de correo electrónico no se encuentra registrada",
        handleClose: handleClose,
        show: show,
        textoBoton: "ACEPTAR"
    }
}
export const ERROR_APLICAR_TIPO_ENVIO = (show,handleClose)=>{
    return {
        titulo: "ERROR",
        mensaje: "No se pudo aplicar el tipo de envío",
        textoBoton: "ACEPTAR",
        show:show,
        handleClose: handleClose
    }
}
export const ERROR_APLICAR_DESCUENTO = (show,handleClose,detalle)=>{
    return {
        titulo: "ERROR",
        mensaje: detalle,
        textoBoton: "ACEPTAR",
        show:show,
        handleClose: handleClose
    }
}

export const ERROR_PROMO_NO_ENCONTRADO = (show,handleClose)=>{
    return {
        titulo: "ALERTA",
        mensaje: "El código que proporcionaste es inválido",
        textoBoton: "ACEPTAR",
        show:show,
        handleClose: handleClose
    }
}

export const ERROR_ORDENAR = (show,handleClose)=>{
    return {
        titulo:"ERROR",
        mensaje:"Ocurrió un error al procesar tu orden, intenta de nuevo.",
        textoBoton: "ACEPTAR",
        show:show,
        handleClose: handleClose
    }
}
export const ERROR_PROCESAMIENTO_PAGO = (show,handleClose)=>{
    return {
        titulo: "ERROR",
        mensaje: "Ocurrió un error al procesar la orden, verifica la información de tu tarjeta e intenta de nuevo.",
        textoBoton: "ACEPTAR",
        show: show,
        handleClose: handleClose
    }
}

export const ERROR_AUTENTICACION = (show,handleClose)=>{
    return {
        titulo: "ERROR DE AUTENTICACIÓN",
        mensaje: "Vuelve a iniciar Sesión",
        textoBoton: "ACEPTAR",
        show: show,
        handleClose: handleClose
    }
}

export const ERROR_CODIGO_POSTAL = (show, handleClose)=>{
    return {
        titulo: "ERROR",
        mensaje: "El código postal introducido no es válido.",
        textoBoton: "ACEPTAR",
        show:show,
        handleClose: handleClose
    }
}

export const ERROR_CARGAR_GOOGLE_PHOTOS = (show,handleClose)=>{
    return{
        titulo: "ERROR",
        mensaje: "Ocurrió un error al intentar obtener tus fotos de Google Photos",
        textoBoton: "CONTINUAR",
        show:show,
        handleClose: handleClose
    }
}

export const ERROR_CARGAR_GOOGLE_ALBUMES = (show,handleClose)=>{
    return{
        titulo: "ERROR",
        mensaje: "Ocurrió un error al intentar obtener tus álbumes de Google Photos",
        textoBoton: "CONTINUAR",
        show:show,
        handleClose: handleClose
    }
}

export const ERROR_SUBIR_GOOGLE_PHOTOS = (show,handleClose)=>{
    return{
        titulo: "ERROR",
        mensaje: "Ocurrió un error al intentar subir tus fotos de Google Photos",
        textoBoton: "CONTINUAR",
        show:show,
        handleClose: handleClose
    }
}
export const PAGINA_VACIA_GOOGLE_PHOTOS = (show,handleClose)=>{
    return{
        titulo: "ERROR",
        mensaje: "La página descargada no contiene medios válidos para subir a la app, vuelve a intentar",
        textoBoton: "CONTINUAR",
        show:show,
        handleClose: handleClose
    }
}

export const ERROR_INICIAR_GOOGLE_PHOTOS = (show,handleClose)=>{
    return{
        titulo: "ERROR",
        mensaje: "No se pudo conectar con Google Photos",
        textoBoton: "ACEPTAR",
        show:show,
        handleClose: handleClose
    }
}

export const ERROR_LOGIN_GOOGLE_PHOTOS = (show,handleClose)=>{
    return{
        titulo: "ERROR",
        mensaje: "No se pudo iniciar sesión con Google Photos",
        textoBoton: "ACEPTAR",
        show:show,
        handleClose: handleClose
    }
}

export const ERROR_BORRADO_MASIVO = (show,handleClose)=>{
    return{
        titulo: "ERROR",
        mensaje: "No se pudieron borrar todas las fotos",
        textoBoton:"ACEPTAR",
        show:show,
        handleClose:handleClose}
    }
export const ERROR_CARGAR_FORROS_TEMATICOS = (show,handleClose)=>{
    return{
        titulo: "ERROR",
        mensaje: "No se pudieron obtener los forros temáticos",
        textoBoton: "ACEPTAR",
        show:show,
        handleClose: handleClose
    }
}
export const ERROR_ASIGNAR_FORRO_TEMATICO = (show,handleClose)=>{
    return{
        titulo: "ERROR",
        mensaje: "No se pudo asignar el forro temático seleccionado, intente mas tarde",
        textoBoton: "ACEPTAR",
        show:show,
        handleClose: handleClose
    }
}

export const ERROR_GUARDAR_LOMO = (show,handleClose)=>{
    return{
        titulo: "ERROR",
        mensaje: "No se pudieron guardar los cambios en el lomo, intente mas tarde",
        textoBoton: "ACEPTAR",
        show:show,
        handleClose: handleClose
    }
}

