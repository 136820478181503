import {bucket} from "./const";
import Compressor from "compressorjs";
import { esDefinido } from "./general";
export const DIM_RESIZE = 2000;
const MAX_DIM_THUMBNAIL = 400;
export const MIN_QUALITY = 1080;
const canvas = window.document.createElement('canvas');

const canvasC = canvas;
const tnCanvasContext = canvas.getContext('2d');
export const imgBucket = function(){    
    
}
        
export function nuevaImagen(queryObj){
    let img = new Image();
    img.src = queryObj.source;  
    img.setAttribute("width","100%");   
    img.setAttribute("class","d-block w-100");
    return img;

}
export function validar(fotos){
    let errores =[]; 
    let formatos = ["image/jpeg","image/png"];
    let errorFormato = "La foto debe tener formato png, jpg o jpeg";
    let errorDimensiones = "La foto de portada debe ser cuadrada";
    for(let i=0;i<fotos.length;i++){
        if(!formatos.includes(fotos[i].type)){
            
            errores.push({nombre:fotos[i].name,error:errorFormato})
        }        
    }
    return errores;
}
function getRandomInt() {
    return Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER));
}
export function renombrar(fotos){
    return Array.from(fotos).map(el=>{
        let copia =el.slice (0,el.size,el.type)
        let nombre = Date.now().toString() + "_" + getRandomInt().toString() +".jpeg"; 
        return new File([copia],nombre,{type:el.type})

    })
}
export function removeFromBucket(categoria,identificador){

    let copia = bucket[categoria].filter(el=>{

        return el.identificador !== identificador});

    bucket[categoria] = copia;

}

export function getFromBucket(categoria,queryObj){
    //console.log("BUCKET");
    //console.log(bucket);
    let ref = bucket[categoria].find(el=>
        el.identificador === queryObj.identificador
    )
    if (!esDefinido(ref)){
        ref = {
            identificador:queryObj.identificador,            
            elemento:nuevaImagen(queryObj)
        }
        //console.log("AGREGANDO A LISTA");
        //console.log(queryObj.identificador);
        bucket[categoria].push(ref);
    }
    else{
        //console.log("YA ESTABA EN LISTA");
        //console.log(queryObj.identificador);
    }
    return ref.elemento;
}
 

export function clearBuckets(buckets){
    for(let i = 0;i<buckets.length;i++){
        bucket[buckets[i]] = [];
    }    
}

export function obtenerImg(src){
    return new Promise((resolve, reject) => {
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        let url = _URL.createObjectURL(src);
        img.onload = () => {
            resolve(img);
        }
        img.onerror = reject
      img.src = url;
    })

}
export function obtenerDimensiones(img){
    return {height:img.naturalHeight,width:img.naturalWidth}
}



export function comprimir(foto,quality){
    return new Promise((resolve, reject) => {
        new Compressor(foto,{
            quality:quality,
            mimeType:"image/jpeg",
            checkOrientation:true,
             success(result){
                //console.log("COMPRIMIDO EN PROMESA");
                //console.log(result);
                resolve(result);        
            },
            error(err){
                reject(err);
            }
        });        
      })
}
export function resize(img){
    //console.log("RESIZING");
    return new Promise((resolve,reject)=>{
        let _URL = window.URL || window.webkitURL;
            let dims = obtenerDimsResize(img);
            //console.log("RESIZING FROM ");
            //console.log(img.naturalWidth);
            //console.log(img.naturalHeight);
            //console.log("TO");
            //console.log(dims);
            var canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d");

            canvas.width = dims["width"];
            canvas.height= dims["height"];
            ctx.drawImage(img, 0, 0, dims["width"], dims["height"]);            
            //console.log(canvas);
            let url = canvas.toBlob(function(blob){
                //console.log("RESIZED to");
                //console.log(blob);
                resolve(blob);
            },"image/jpeg",1);
    return img;    
    });
    

}
export function resizeCanvas(canvasCtx,img){
    let canvas = canvasCtx["canvas"];
    let ctx = canvasCtx["ctx"];

    return new Promise((resolve,reject)=>{
      let _URL = window.URL || window.webkitURL;
          let dims = obtenerDimsResize(img);
              resolve({canvasCtx:canvasCtx, dims: dims});
  });
  
}
export function hacerThumbnail(foto){
    //console.log("HACER THUMBNAIL");
    //console.log(foto);
    return new Promise((resolve,reject)=>{        
        let _URL = window.URL || window.webkitURL;
        let url = _URL.createObjectURL(foto);
        let img = new Image();        
        img.onload = function(){
            let dims = obtenerDimsThumbnail(img);
            //var canvas = document.createElement("canvas"),
            // ctx = canvas.getContext("2d");
            let canvas = canvasC;
            let ctx = tnCanvasContext;
            canvas.width = dims["width"];
            canvas.height= dims["height"];
            ctx.drawImage(img, 0, 0, dims["width"], dims["height"]);            
            let url = canvas.toBlob(function(blob){
                let fullname = foto.name;
                //blob.name = fullname.split(".")[0] + ".jpeg";
                resolve(blob);
            },"image/jpeg",1);
        }
        img.src = url;
        img.error = function(err){
            //console.log("ERROR THUMBNAIL");
            reject(err)};
    });
}
function obtenerDimsThumbnail(img){
    //console.log("OBTENER DIMS");
    //console.log(img.naturalHeight);
    //console.log(img.naturalWidth);
    let maxDim =  "naturalWidth";
    let minDim = "naturalHeight";
    if(img.naturalWidth < img.naturalHeight){
        maxDim = "naturalHeight";
        minDim = "naturalWidth";
    }
    let dims = {};
    dims[maxDim] = MAX_DIM_THUMBNAIL;
    dims[minDim] = MAX_DIM_THUMBNAIL * img[minDim]/img[maxDim];    
    dims["width"] = dims["naturalWidth"];
    dims["height"] = dims["naturalHeight"];
    return dims;
}
function obtenerDimsResize(img){
    //console.log("OBTENER DIMS");
    //console.log(img.naturalHeight);
    //console.log(img.naturalWidth);
    let maxDim =  "naturalWidth";
    let minDim = "naturalHeight";
    if(img.naturalWidth < img.naturalHeight){
        maxDim = "naturalHeight";
        minDim = "naturalWidth";
    }
    let dims = {};
    dims[maxDim] = DIM_RESIZE;
    dims[minDim] = DIM_RESIZE * img[minDim]/img[maxDim];    
    dims["width"] = dims["naturalWidth"];
    dims["height"] = dims["naturalHeight"];
    return dims;
}
export function cropear(img){
    
    return new Promise((resolve,reject)=>{
        //console.log("CROPEAR");
        var canvas = document.createElement('canvas');
        var tnCanvasContext = canvas.getContext('2d');
        //let canvas = canvas;
        //let tnCanvasContext = tnCanvasContext;
        let cropDims = obtenerCropDims(img);
        //console.log("GOT IMG DIMS");
        canvas.width = cropDims.size ;
        canvas.height = cropDims.size;
        //console.log("set canvas things");
        tnCanvasContext.drawImage(img, cropDims.startX, cropDims.startY, cropDims.size, cropDims.size,0,0,cropDims.size,cropDims.size);
        //console.log("DRAWN IMAGE");
        let url = canvas.toBlob(function(blob){
            resolve(blob);
        },"image/jpeg",1);
            });
}
function obtenerCropDims(img){
    
    let menor = img.naturalHeight < img.naturalWidth? "naturalHeight":"naturalWidth";
    let res = {size:img[menor],startX:0, startY: 0};    
    if(menor === "naturalHeight"){
        res["startX"] = (img.naturalWidth - img.naturalHeight)/2;
    }
    else{
        res["startY"] = (img.naturalHeight - img.naturalWidth)/2;
    }

    return res;
}
export function esDeficiente(foto){

    if(esDefinido(foto.identificador_dispositivo) && foto.identificador_dispositivo.includes("cdninstagram")){
        return false;
    }
    if(!esDefinido(foto.ancho_original) && (!esDefinido(foto.alto_original))){
        return false;
    }
    if(foto.ancho_original<MIN_QUALITY && foto.alto_original < MIN_QUALITY){
        return true;
    }
    return false;   
}
export function procesarArrayBufferParaPortada(arrayBuffer,datosFoto){
    return new Promise(async (resolve,reject)=>{
        try{

            const blob = new Blob([arrayBuffer],{type:"image/jpeg"});

            let img = new Image();
            img.onload = async function(){

                let result = await cropear(img);
                resolve(result);
            }
            img.src =  URL.createObjectURL(blob);
        }catch(err){
            reject(err);
        }   
    })
    
}