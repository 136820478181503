import React,{Component}  from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import logo from "../images/logo-small.svg";
class Header extends Component{
    render(){
        return(

	        <Navbar expand="lg">
 	            <Navbar.Brand href="#home"><img src = {logo} alt = "Booksnap"/></Navbar.Brand>
 	       </Navbar>

       )
    }
}

export default Header;