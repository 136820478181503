import React,{Component} from "react";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import Navegacion from "./Navegacion";
import ModalError from "./ModalError";
import{connect} from "react-redux";
import validar from "../util/validar";
import Alert from "react-bootstrap/Alert";
import {iniciarSesion,iniciarSesionTardia} from "../util/asyncActions";
import {esDefinido} from "../util/general";
class Login extends Component{
    constructor(props){
        super(props);
        this.state = {
            email:"",
            password:"",
            errores :{}
        }
    }
    validarLogin= ()=>{
        let errores = {};
        validar("email", this.state.email,["notEmpty","email"],errores);
        validar("password", this.state.password,["notEmpty"],errores);
        return errores;
    }
    handleEmail = (ev)=>{
        let res = Object.assign({},this.state,{email:ev.target.value});
        this.setState(res);
    }
    handlePassword =(ev)=>{
        let res = Object.assign({},this.state,{password:ev.target.value});
        this.setState(res);
    }
    entrar =()=>{
        let errores = this.validarLogin();
        if(this.hayErrores(errores)){  
            let copia = Object.assign({},this.state,{errores:errores})          
            this.setState(copia);
        }
        else{
            
            
            let prevPath = this.props.location.state.prevPath.pathname;
            
            if(this.props.location.state.prevPath === "/" || prevPath === "/landing/recuperar_contrasena"){
                
                this.props.dispatch(iniciarSesion(this.state.email,this.state.password,this.props.history))
            }
            else{
                
                this.props.dispatch(iniciarSesionTardia(this.state.email,this.state.password,this.props.uuid,this.props.history,prevPath,
                    this.props.preIniciarSesion));
            }

        }
        
    }
    hayErrores = (errores)=>{
        return !(Object.keys(errores).reduce((acc,el)=>{return acc &errores[el].length === 0},true))
    }
    atras =()=>{

        this.props.history.goBack()
    }
    mostrarErrores = ()=>{
        let err = this.state.errores;
        return Object.keys(err).map((el,index) =>{
            if(err[el].length === 0){
                return null
            }
            else{
             return <div key = {index}>
                 
                {err[el].map((el2,index2)=>(
                        <Alert key={index2} variant={"danger"}>
                            {el2}
                        </Alert>))}
             </div>
                
            }
        
        });
    }
   mostrarErroresPara(campo){
    let err = this.state.errores[campo];
        
            if(!esDefinido(err) ||err.length === 0){
                return null
            }
            else{
             return <div key = {`errores_${campo}_index`}>                 
                {err.map((el2,index2)=>(
                        <Alert key={index2} variant={"danger"}>
                            {el2}
                        </Alert>))}
             </div>
                
            }        
        
   }
    listarErrorArray = (array)=>{
         
    }
    
    render(){
        
            return(
                <div id = "inner-content-wrapper" className = {this.props.navegable?"d-flex flex-column":" d-flex flex-column noNav"}>
                    <ModalError
                    titulo = {"ACCESO DENEGADO"}
                    mensaje ={"El usuario o la contraseña son incorrectos"}
                    handleClose = {(ev)=>{
                        
                        this.props.dispatch({ type:"ERRORES", errores:"" })
                    }}
                    textoBoton = {"CONTINUAR"}
                    show = {this.props.errores === "AUTENTICACION"}
                    />
                    <ModalError
                    titulo = {"FALLA AUTENTICACIÓN"}
                    mensaje ={"Ya hay una sesion iniciada"}
                    handleClose = {(ev)=>{
                        
                        this.props.history.push("/proyectos")
                    }}
                    textoBoton = {"CONTINUAR"}
                    show = {(this.props.token !==null && typeof this.props.token !== "undefined")}
                    />
                    
                    
                    <Navegacion/>
                    <div className="title-bar">
                        <span className="back-icon" onClick = {this.atras}> </span> 
                        LOGIN 
                    </div>
                    <div className=" inner-content login d-flex flex-column flex-fill justify-content-center align-items-center">
                        <div className="form-wrapper">
                            <div className="form-group">      
                                <label>CORREO ELECTRÓNICO</label>
                                <input className="form-control" type = "text"
                                    onChange = {this.handleEmail}/>
                                    {this.mostrarErroresPara("email")}
                            </div>
                            <div className="form-group">    
                                <label>CONTRASEÑA</label>  
                                <input type = "password" className="form-control"
                                    onChange = {this.handlePassword}/>
                                    {this.mostrarErroresPara("password")} 
                            </div>
                            <Button className='btn btn-primary btn-big btn-block' onClick = {this.entrar}>ENTRAR</Button>
                            <Link to={this.props.navegable?"/recuperar_contrasena":"/landing/recuperar_contrasena"} className="link-password">Olvidé mi Contraseña</Link>
                            <div className="link-register">
                                ¿No estás registrado? 
                                <Link to={this.props.navegable?"/registrar":"/landing/registrar"} onClick = {this.irRegistrar} className="link-item"> Registrate ahora</Link>
                            </div>
                        </div>
                    </div>

                </div>

                )

    }
}
const mapStateToProps = (state)=>{
    return {
        hayUsuario: state.clientes.hayUsuario,
        token: state.clientes.token,
        errores: state.clientes.errores,
        uuid:state.clientes.uuid,
        preIniciarSesion: state.navegacion.preIniciarSesion
    }
}
export default connect(mapStateToProps)(Login);