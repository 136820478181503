const estadoInicial = {
    forros:[],
    error:""
}
const forrosTematicosReducer = (state = estadoInicial,action)=>{
    let res = {};
    switch(action.type){
        case "ERROR_FORROS_TEMATICOS":
            res = Object.assign({},{error: action.valor});
            return res;
        case "LIMPIAR_ERRORES_FORROS_TEMATICOS":
            res = Object.assign({},{error: ""});
            return res;
        case "CARGAR_FORROS_TEMATICOS":
            res = Object.assign({},{forros:action.forros});
            return res;
        default:
            return state;

    }
}

export default forrosTematicosReducer;