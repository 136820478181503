
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
    
import { createStore, applyMiddleware } from 'redux';
import thunk from "redux-thunk";
import { Provider } from 'react-redux';
import mainReducer from "./reducers/mainReducer";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
    
const store = createStore(mainReducer,applyMiddleware(thunk));

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Route path="/" component={App} />
        </Router>
    </Provider>, document.getElementById('root'));


