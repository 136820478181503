import React, {Component} from "react";
import HTML5Backend from "react-dnd-html5-backend"
import { DragDropContext } from "react-dnd"

import DroppableBox from  "./DroppableBox";
import iconDeletePhoto from "../../images/design_icon_close.svg";
import {connect} from "react-redux";
import {reqReordenar} from "../../util/fotos";
import {esDefinido} from "../../util/general";
import Observer from "../../util/imageObserver";
import {esDeficiente} from "../../util/images";
import NoDragFoto from "./NoDragFoto";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


const CHUNK_SIZE = 60;
class PreviewFotos extends Component{
    constructor(props){
        console.log("CONSTRUCTOR PREVIEW");
        super(props);
        let vols = this.props.volumenes.map(vol=>vol.map(foto=>Object.assign({},foto)));
        this.state = {volumenes: vols,scrolling:false, direction:"none"}
        let that = this;
        setInterval(function(){
            if(that.state.scrolling){
                let dir = that.state.direction
                let cant = dir === "none"?0: dir === "up"?-25:25;
                console.log("STILL SCROLLING");
                scroll.scrollMore(cant,   {duration:20});
            }
        },20)
    }
    componentDidMount (){
        window.onpopstate= ()=>{
            //this.props.dispatch({type:"CARGANDO_PROYECTOS"});
            console.log("ACABAS DE IR PARA ATRAS");
            //this.props.history.push("/proyectos");
        }
    }
    componentWillUnmount(){
        this.props.dispatch({type:"SALIR_MODO_BORRADO_MASIVO"})
    }
    componentDidUpdate(prevProps, prevState){
        console.log("PREVIEW FOTOS DID UPDATE");
        if(prevProps.volumenes.length === 0 && this.props.volumenes.length >0){
            console.log("POBLADO");
            let vols = this.props.volumenes.map(vol=>vol.map(foto=>Object.assign({},foto)));            
            this.setState({volumenes: vols});
        }
        else if(cambiaVolumenes(prevProps.volumenes,this.props.volumenes)){
            this.setState({volumenes:[]});
            console.log("CAMBIAN VOLUMENES");
            let vols = this.props.volumenes.map(vol=>vol.map(foto=>Object.assign({},foto)));            
            this.setState({volumenes: vols});
        }
        console.log("OBSERVING FOTOS ");
        const imgs = document.querySelectorAll('div[data-src]');
        imgs.forEach(img=>{
            Observer.observe(img);
        })
    }

    mover = (id,posicion)=>{ 
        console.log("MOVER");
        console.log(id);
        console.log(posicion);    
        this.props.dispatch({type:"CARGANDO_FOTOS"});
        let porToken = this.props.token !== null && typeof this.props.token !=="undefined";
        let dataAut = porToken?{token:this.props.token}:{uuid: this.props.uuid}
        let posiciones = obtenerPosicionesReordenamiento(this.state.volumenes,id, posicion);
        reqReordenar(dataAut,this.props.proyectoSeleccionado,posiciones).then(resp =>{
            let plano = this.state.volumenes.map(vol=>vol.map(foto=>Object.assign({},foto))).flat();
            let posAntes = plano.findIndex(foto => foto.id === id);
            let foto = plano[posAntes];
            
            plano = plano.filter(foto=>foto.id !== id);
            let vols =[];
            let tail = 0;
            let head = 0;
            let result = {};

            head = plano.slice(0,posicion); 
            tail = plano.slice(posicion);
            result = head.concat([foto]).concat(tail);
            
            
            result = result.map(el=>Object.assign({},el));
            let idv = 0;
            result.forEach((foto,index)=>{
                
                let ifo = index%60;
                foto.orden =  ifo + 1;
            })
            
            for(let index = 0;index<result.length; index+=CHUNK_SIZE ){
                let sliced = result.slice(index, index + CHUNK_SIZE);
                vols.push(sliced);
            }
            this.props.dispatch({type:"CARGADO_FOTOS"})
            this.setState({volumenes:vols});
        }).catch(err=>{
            console.log("ERROR AL REORDENAR");
            this.props.dispatch({type:"ERROR_FOTO",valor:"REORDENAMIENTO"});
            this.props.dispatch({type:"CARGADO_FOTOS"});
        });
        
    }
    togglePorBorrar = (ev)=>{
        this.props.dispatch({type:"TOGGLE_POR_BORRAR",id:parseInt(ev.target.value)})
    }
    listarVolumenes = ()=>{
        let volumenes = this.state.volumenes;
        let vols = volumenes.map(vol=>{
            let iteraciones = Math.floor(vol.length/2)+1;
            let pags = []
            let ordenado = vol.sort((a,b)=>{
                return a.orden - b.orden;
            })
            for(let i = 0; i<iteraciones;i++){
                if(i==0){
                    pags.push([ordenado[0]]);
                    continue;
                }                
                pags.push(ordenado.slice(2*i-1,2*i+1));
            } 
            return pags;  
        })
       return  vols.map((vol,index)=>{
           let that = this;
            return <div className="fotosVolWrapper" key = {"volumen_"+(index+1)}>
                Volumen {index + 1}
                <div className = "fotosVolPreview">
                {
                    vol.map((slide,index2) =>{
                        return <div className={"fotosSlidePreview "+ (this.props.isOver?"isOver":"notOver")} key = {`slide_v${index+1}_s${index2}`}>
                            {slide.map((foto,index3)=>{
                                return <div className = {"fotosFotoPreview "+(index2==0?"right ":"") +(esDeficiente(foto)?"deficiente ":"")}  key = {`foto_v${index+1}_s${index2+1}_f${index3+1}`}>
                                    <div className="delete-check">
                                    <input className="styled-checkbox" id={`styled-checkbox-${foto.id}`}
                                     type="checkbox" checked = {this.props.fotosPorBorrar.includes(foto.id)}
                                     value = {foto.id}
                                     x ={foto.id}
                                        onChange = {
                                        this.togglePorBorrar}/>
                                    <label htmlFor={`styled-checkbox-${foto.id}`}><span></span></label>
                                    </div>
                                       
                                    <div className = {"contFoto " } 
                                    categoria = "fotos"
                                    identificador = {foto.id}
                                    onClick = {()=>this.props.irEdicionCaption(foto.id)}>
                                        
                                        {this.props.modoBorradoMasivo? 
                                        
                                        <NoDragFoto src = {foto.url} id = {foto.id}/>
                                        :
                                        <DroppableBox handleDrop = {that.mover} src = {foto.url} id = {foto.id} posicion = {obtenerPosicion(index,index2,index3)}/>
                                        }
                                    </div>
                                </div>
                            })}
                        </div>
                    })
                }
                </div>
            </div>
        });
    }
    startScrollUp = ()=>{
        this.setState({scrolling:true,direction:"up"});
    }
    startScrollDown = ()=>{
        this.setState({scrolling:true,direction:"down"});
    }
    stopScroll = ()=>{
        console.log("STOP SCROLL");
        this.setState({scrolling:false,direction:"none"});
    }
    render(){
        //console.log("REORDENAMIENTO");
        //console.log(this.props);
        
        return <div className = "contReorden">                    
                <div 
                    style = {{width:"100%",visibility:"visible"}}
                    onDragEnter ={this.startScrollUp}
                    onDragLeave = {this.stopScroll}
                    onDrop = {()=>{console.log("INVALID DROP")}}
                    onDragOver={()=>{console.log("DRAG OVER")}}
                    className = "sticky-top">
                     
                </div>
                {this.listarVolumenes()}
                <div 
                    style = {{width:"100%",visibility:"visible"}}
                    onDragEnter ={this.startScrollDown}
                    onDragLeave = {this.stopScroll}
                    onDrop = {()=>{console.log("INVALID DROP")}}
                    onDragOver={()=>{console.log("DRAG OVER")}}
                    className = "sticky-bottom">
                     
                </div>
            <a className="btn btn-primary btn-general" onClick = {this.props.continuar}>CONTINUAR</a>
            </div>
        
        
        
    }
}
function obtenerPosicion(index,index2,index3){
    
    let res = 60*index + (index2 == 0? 0:(2*index2-1) + index3);
    
    return res;
}
function obtenerPosicionesReordenamiento(volumenes,idFoto, nuevaPosicion){
    console.log("BUSCANDO VOL CON foto con ID " + idFoto);
    console.log(volumenes);
    let volAnt = volumenes.findIndex(vol=>{
        console.log("BUSCANDO EN VOL");
        console.log(vol);
        let found = vol.find(foto=> foto.id === idFoto);
        console.log(found);
        if (esDefinido(vol.find(foto=> foto.id === idFoto))){
            return true;
        }
        return false;
    })
    volAnt++;
    let foto = volumenes.flat().find(foto=>foto.id === idFoto);
    let ordenAnt = foto.orden;
    let volNuevo = 1+ Math.floor(nuevaPosicion/60);
    let ordenNuevo = 1+ nuevaPosicion%60;
    let res = {
        anterior:{
            vol:volAnt,
            orden:ordenAnt},
        
        nuevo:{
            vol:volNuevo,
            orden: ordenNuevo
        }
    }
    console.log(res);
    return res;
}
function cambiaVolumenes(vol1,vol2){
    if(vol1.length!== vol2.length){
        return true;
    }
    for(let i = 0; i < vol1.length; i++){
        if(vol1[i]!==vol2[i]){
            return true;
        }
    }
    return false;
}
const mapStateToProps = (state)=>{
    return {
        modoBorradoMasivo: state.proyectos.modoBorradoMasivo,
        fotosPorBorrar: state.proyectos.fotosPorBorrar
    }
}
export default connect(mapStateToProps)(DragDropContext(HTML5Backend)(PreviewFotos));