const estadoInicial = {
    seleccionadaPasta:false,
    subidasFotos:false,
    configurado:false,
    error:"",
    codigoError:"",
    creando:false
}
 const crearProyecto = (state = estadoInicial, action) => {
    let res = {};
    switch(action.type){       
        case "SELECCIONADA_PASTA":     
            res = Object.assign({},state,{seleccionadaPasta:true})
            return res;
        case "SUBIDAS_FOTOS":
        console.log("SUBIDAS FOTOS");
            if(!state.creando){
                return state;
            }
            res = Object.assign({},state,{subidasFotos:true})
            return res;
        case "CONFIGURADO":
            res = Object.assign({},state,{configurado:true,creando:false})
            return res;
        case "ERROR_SUBIR_FOTOS_CREAR_PROY":
            console.log("ERROR SUBIR FOTOS");
            res = Object.assign({},state,{error:"SUBIR_FOTOS",codigoError: action.codigoError});
            return res;
        case "OCULTAR_ERROR_CREAR":
            res = Object.assign({},state,{error:""});
            return res;
        case "CREANDO":
            res = Object.assign({},state,{creando:true});
            return res;
        case "CREACION_TERMINADA":
            res = Object.assign({},state,{creando:false,seleccionadaPasta:false,subidasFotos:false,configurado:false});
            return res;
        default:
            return state;
    }
}
export default crearProyecto;