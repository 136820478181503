
 let development = {
     urlBackend: "https://bspmvpdev.sisu.mx",
     //urlBackend: "https://192.168.8.10",
     //urlBackend: "http://localhost:5000",
     bucketName: 'booksnap-test',
     bucketRegion: 'us-east-1',
     IdentityPoolId: 'us-east-1:87827d9f-9799-489e-b591-233328ab86b7',
     googleApiKey: "AIzaSyDn_sV4-Lfxj_i2CmXQnoKWLKDdB2ttR-4",
     stripe_publishable_key:"pk_test_9qOk00nmGRUoFo2iKZx5gNWh",
     googlePhotos:{
          apiKey:"AIzaSyBuPtk5mcMVLiONNF5AQX1wWJagkd7oFwo",
          clientId:"162659202240-bq84ha84op80mq2jvuc96s6s7qpgqdj6.apps.googleusercontent.com",                    
          subirFotosFunctionUrl:"https://us-central1-booksnap-f7ccb.cloudfunctions.net/subirFotosDev",
          bajarPortadaUrl: "https://us-central1-booksnap-f7ccb.cloudfunctions.net/bajarPortadaDev"
     }
 }
 
 let production = {
     urlBackend: "https://admin.booksnap.co/",
     bucketName: 'booksnap-production',
     bucketRegion: 'us-east-1',
     IdentityPoolId: 'us-east-1:4e439e48-c16f-4b85-a4a5-b921fa8e1d2a',
     googleApiKey:"AIzaSyCsAhizgLYLxydHD9Ae7Oi1y48K3QWvWvY",
     stripe_publishable_key:"pk_live_1G4ZO1VcX98HtjQkmeFkn6or",
     googlePhotos:{
      apiKey:"AIzaSyBSeQsogtWbCTD34_KN3D-q2pqHDGjMDx0",
      clientId:"814204130197-b8j91dgfre16jqvjo7v0ulkoa257r56e.apps.googleusercontent.com",
      subirFotosFunctionUrl:"https://us-central1-booksnap-f7ccb.cloudfunctions.net/subirFotosProd",
      bajarPortadaUrl: "https://us-central1-booksnap-f7ccb.cloudfunctions.net//bajarPortadaProd"
      }
 }
 production = development;
 let exp = development;
 if (process.env.NODE_ENV==="production"){
 
      exp = production;
 }
 export default exp;