import {subir} from "./aws";
import {writeLog} from "./logs";
import {codigoErrorOrigen, codigoErrorFase, codigoErrorTipo,generarCodigoError} from "./const";
import {obtenerImg,obtenerDimensiones,comprimir,resize,DIM_RESIZE,resizeCanvas} from "./images";
import {esDefinido,vacios} from "./general";
import {dmsADecimal,obtenerLugar} from "./geo";
import moment from "moment";    
import EXIF from "exif-js";
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext("2d");
const DIM_COMPRESION = 2000;
//Todo el proceso asincrono
export function subirAltAsync(fotos,cb){

    let promesas = [];
    for(let i = 0;i<fotos.length;i++){
        let foto = fotos[i];
        let proceso = procesarAlt(foto,cb).then(resp => subir(resp,cb));
        promesas.push(proceso);
    }
    return Promise.all(promesas);
}
//procesamiento secuencial y subida asincrona
export function subirAlt(fotos,cb){

    
    return new Promise(async (resolve,reject)=>{
        
        let count = 0;
        let values = [];
        for(let i = 0;i<fotos.length;i++){
            let foto = fotos[i];
            try{
            let proceso = await procesarAlt(foto,cb)
             subir(proceso,cb).then(resp=>{
                 
                 count ++;
                 values.push(resp);
                if(count == fotos.length){
                    resolve(values);
                }
             }).catch(err=>{
                 console.log("ERROR AL SUBIR S3");
                 console.log(err);
                 let payload = {
                    error: JSON.stringify(err),
                    message: err.message,
                    function: "src/util/fotobsp/subiralt",
                    browser:window.navigator.appVersion,
                    
                }
                console.log(payload);
                writeLog(payload).then(resp=>console.log("SE HA CREADO LOG")).catch(err2=>{
                    console.log("no se pudo crear log")
                    console.log(err2);
                });
                reject( new Error(generarCodigoError("dispositivo","subida","upload")));
             });
            }
            catch(err){
                console.log("ERROR AL PROCESAR");
                console.log(err.message);
                let payload = {
                    error: JSON.stringify(err),
                    message: err.message,
                    function: "app/proyectos/SeleccionarFotos/enviar",
                    browser:window.navigator.appVersion,
                    
                }
                console.log(payload);
                writeLog(payload).then(resp=>console.log("SE HA CREADO LOG")).catch(err2=>{
                    console.log("no se pudo crear log")
                    console.log(err2);
                });
                reject(new Error(generarCodigoError("dispositivo","procesamiento","desconocido")));
            }
        }
        
    });
    
}
export async function procesarAlt(foto,cb){
    //console.log("PROCESANDO");  
    return new Promise(async (resolve,reject)=>{
      try{
          
        //console.log(foto);
        let img = await obtenerImg(foto);
        console.log("SE OBTUVO IMG");
        console.log(img);
        console.log(foto);        
        
        let dims =obtenerDimensiones(img);
        //console.log("DIMENSIONES:");
        //console.log(dims);
        //console.log(foto);
       let result = null;
       let canvasCtx = getCanvasContext(img);
       
       //await reorientarImg(foto,img);
       if (dims["height"] >=DIM_COMPRESION && dims["width"] >= DIM_COMPRESION){
        console.log("COMPRIMIENDO");
        //comprimirCanvas(canvasCtx,img,0.8).then(result=>{
            cb("procesadas");
            let res = await reorientarImg(foto,img,canvasCtx,0.9,dims)
            //canvasCtx["canvas"] = null;
            //canvasCtx["ctx"] = null;
            resolve(res);
        //})
         //console.log('COMPRIMIDO');
       } 
       else{
           if(dims["height"] > DIM_RESIZE || dims["width"] > DIM_RESIZE){
               resizeCanvas(canvasCtx,img).then(async result=>{
                   //result.name = foto.name;
                   cb("procesadas");
                   console.log("POR REORIENTAR RESIZED");
                   console.log(result);
                   let res = await reorientarImg(foto,img,result["canvasCtx"],1,result["dims"]);
                   console.log("REORIENTADO ");
                   console.log(res);
                   resolve(res);
               }).catch(err=>{
                   console.log("ERROR RESIZE CANVAS");
                   console.log(err);
               })
           }
           else{
                //comprimirCanvas(canvasCtx,img,1).then(result=>{
                    cb("procesadas");
                    console.log("SIN PROCESAR")
                    let res = await reorientarImg(foto,img,canvasCtx,1,dims);
                    resolve( res);
               //})
           }
       }   
        }catch(e){
            reject(e);
        }     
    });
}
function getCanvasContext(img){
    //let canvas = document.createElement('canvas');
    //let ctx = canvas.getContext("2d");
    return {canvas:canvas, ctx:ctx};
}
export function reorientarImg(archivo,img,canvasCtx,quality,dims){
    let canvas = canvasCtx["canvas"];
    let ctx = canvasCtx["ctx"];
    
    return new Promise(async (resolve,reject)=>{
        try{
            
            await extraerExif([archivo]);
            let srcOrientation = archivo.exifdata.Orientation;
            if(!esDefinido(srcOrientation) || srcOrientation === 0 || srcOrientation === 1){
                console.log("PODRIA SALTAR DRAW");
                //resolve(archivo);
            }
            console.log("REORIENTAR IMG");
            console.log(srcOrientation);
            let foto = img;
            
            console.log("FOTO = IMG");
            let _URL = window.URL || window.webkitURL;
            var width = dims.width,
            height = dims.height;
            //canvas = canvasCtx["canvas"]
            //let ctx = canvasCtx["ctx"];
                canvas.width = width;
                canvas.height = height;
                //SE ANULA REORIENTACION
                // set proper canvas dimensions before transform & export
                /*
                if (4 < srcOrientation && srcOrientation < 9) {
                    canvas.width = height;
                    canvas.height = width;
                } else {
                    canvas.width = width;
                    canvas.height = height;
                }
                
                switch (srcOrientation) {
                    case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
                    case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
                    case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
                    case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
                    case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
                    case 7: ctx.transform(0, -1, -1, 0, height, width); break;
                    case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
                    default: break;
                }*/
                console.log("TRANSFORMED");
                ctx.drawImage(foto, 0, 0,width,height);            
                let url = canvas.toBlob(function(blob){
                    blob.name = archivo.name;
                    resolve(blob);
                },"image/jpeg",quality);   
                console.log("URL REORIENTADA");
                console.log(canvas.toDataURL()); 
           }catch(err){
               console.log("ERROR REORIENTAR");
                console.log(err);
                reject(err);
        }
    });
     

}
export function reorientar(img){
    
    let srcOrientation = img.exifdata.Orientation;
    
    //console.log("RESIZING");
    return new Promise((resolve,reject)=>{
        try{
            
            if(!esDefinido(srcOrientation) || srcOrientation === 1 || srcOrientation === 0){
            
                let blob = new Blob([img.src], {type : 'image/jpeg'});
                resolve(img);
            }
        
        let foto = new Image();
        foto.onload = function(){
            let _URL = window.URL || window.webkitURL;
            var width = foto.width,
            height = foto.height,
            canvas = document.createElement('canvas');
            let ctx = canvas.getContext("2d");

                // set proper canvas dimensions before transform & export
                if (4 < srcOrientation && srcOrientation < 9) {
                    canvas.width = height;
                    canvas.height = width;
                } else {
                    canvas.width = width;
                    canvas.height = height;
                }

                switch (srcOrientation) {
                    case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
                    case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
                    case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
                    case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
                    case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
                    case 7: ctx.transform(0, -1, -1, 0, height, width); break;
                    case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
                    default: break;
                }
                
                ctx.drawImage(foto, 0, 0);            
                let url = canvas.toBlob(function(blob){
                    blob.name = img.name;
                    resolve(blob);
                },"image/jpeg",1);
        }
        
        foto.src = URL.createObjectURL(img);
           }catch(err){
        reject(err);
    }
    });
     

}
function promesaGetData(foto){
    let claves = ["GPSLatitude","GPSLongitude","GPSLatitudeRef","GPSLongitudeRef",
        "DateTimeOriginal","Timezone"];
    let res = {}
    
    return new Promise(async (resolve,reject)=>{
        let img = await obtenerImg(foto);
        EXIF.getData(foto , function(){
            //console.log("GOT DATA");
            //console.log(foto);
            
            claves.forEach((clave)=>{              
                //console.log("CLAVE");
                //console.log(clave);  
                let dato = EXIF.getTag(foto,clave);
                //console.log(dato);
                
                res[clave] = dato;  
                //console.log(res);
                              
            });
            res["lastModifiedDate"] = foto.lastModifiedDate || foto.lastModified;
            res["nombre"] = foto.name;
            res["ancho_original"] = img.naturalWidth;
            res["alto_original"] = img.naturalHeight;
            console.log("PRE PROCESAR META");
            console.log(res);
            resolve( procesarMeta(res));
        });
    });
}

async function procesarMeta(meta){
    let res = {};
    console.log("PROCESAR META");
    console.log(meta);
    //console.log(typeof meta["DateTimeOriginal"]);
    res["latitud"]= "";
    res["longitud"] = "";
    res["fecha"] = "";
    if(esDefinido(meta["GPSLatitude"]) && esDefinido(meta["GPSLatitudeRef"]))
        res["latitud"] = dmsADecimal(meta["GPSLatitude"],meta["GPSLatitudeRef"]);
    if(esDefinido(meta["GPSLongitude"]) && esDefinido(meta["GPSLongitudeRef"]))
        res["longitud"] = dmsADecimal(meta["GPSLongitude"],meta["GPSLongitudeRef"]);
    
    res["nombre"] = meta["nombre"];
    res["fecha"] = procesarFecha(meta["DateTimeOriginal"],meta["Timezone"],meta["lastModifiedDate"]);                 
    res["caption"] = "";
    res["lugar"] = "";
    res["ancho_original"] = meta["ancho_original"];
    res["alto_original"] = meta["alto_original"];
    console.log(res);
    if(!vacios([res["latitud"],res["longitud"]])){
        
        res["lugar"] = await obtenerLugar(res["latitud"], res["longitud"]);     
    }
    console.log(res);
    return res;
}
function procesarFecha(original,timezone,modified){    
    if(!esDefinido(original)){
        return Math.floor(modified/1000);
    }
    let fecha = moment(original + timezone, "YYYY:MM:DD HH:mm:ssZ",true);
    if(!fecha.isValid()){
        fecha = moment(original, "YYYY:MM:DD HH:mm:ss",true);
    }
    if(!fecha.isValid()){
        fecha = modified;
    }
    else{
        
        fecha = fecha.valueOf();
    }
    fecha = Math.floor(fecha/1000);
    return fecha;
}
export function extraerExif(fotos){
    return new Promise(async (resolve,reject)=>{
        //console.log("EXTRAER META");
        let arr = [];
        let cuenta=0;
        try{
            for(let i = 0;i < fotos.length; i++){
                //console.log("INTO ARRAY");
                let foto = fotos[i];
                //console.log(foto);            
                EXIF.getData(foto , function(){
                    cuenta ++;
                    if (cuenta === fotos.length){
                        resolve("BIEN");
                    }
                });
                //console.log(res);
            }
        }
        catch(err){
            console.log("ERROR AL EXTRAER METADATOS");
            reject(err);
        }
    });
}

 export function extraerMetadatos (fotos,cb){
    console.log("EXTRAER METADATOS");
    console.log(fotos);
    return new Promise(async (resolve,reject)=>{
        //console.log("EXTRAER META");
        let arr = [];
        try{
            for(let i = 0;i < fotos.length; i++){
                //console.log("INTO ARRAY");
                let foto = fotos[i];
                //console.log(foto);            
                let res = await promesaGetData(foto);
                
                //console.log(res);
                arr.push(res);
                cb("procesadas");
            }
        //console.log("TENEMOS META");
        //console.log(arr);
        resolve(arr);}
        catch(err){
            console.log("ERROR AL EXTRAER METADATOS");
            reject(err);
        }
    });
}

