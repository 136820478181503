import config from "./config";
import {request} from "./axiosReqs";
import {COLONIA_MAX_LENGTH,DIRECCION_MAX_LENGTH} from "../app/carrito/FormularioEnvioPago";
export function reqCargarCarrito(dataAut){
    let url = `${config.urlBackend}/carrito/`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {method:"GET",
        headers: headers,
        url
    };
    return request(options);
}
export function reqEditarVolumen(dataAut,volumenId,cantidad){
    let url = `${config.urlBackend}/carrito/editar`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {method:"POST",
        headers: headers,
        data: {volumen_id: volumenId, cantidad:cantidad},
        url
    };
    return request(options);
}
export function reqEliminarProyecto(dataAut,proyId){
    let url = `${config.urlBackend}/carrito/proyecto/${proyId}`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {method:"DELETE",
        headers: headers,        
        url
    };
    return request(options);
}
export function reqAplicarTipoEnvio(dataAut,tipoEnvio){
    let url = `${config.urlBackend}/clientes/aplicar_tipo_envio`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut);
    const options = {method:"POST",
        headers: headers,
        data: {tipo_envio:tipoEnvio},
        url
    };
    return request(options);
}
export function reqAplicarDescuento(dataAut,codigoDescuento){
    let url = `${config.urlBackend}/clientes/aplicar_promo`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut);
    const options = {method:"POST",
        headers: headers,
        data: {
            promo_code:codigoDescuento,
            acepto_descuento_monto:true},
        url
    };
    return request(options);
}

export function reqObtenerResumenCompra(dataAut,codigoDescuento, tipoEnvio){
    let url = `${config.urlBackend}/clientes/resumen_compra`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut);
    const options = {method:"POST",
        headers: headers,
        data: {
            promo_code:codigoDescuento,
            tipo_envio:tipoEnvio,
            acepto_descuento_monto:true},
        url
    };
    return request(options);
}

export function reqOrdenar(dataAut,datos,cardToken){
    let url = `${config.urlBackend}/clientes/ordenar`;
    let data = {
        destinatario: datos.destinatario,
        direccion: datos.direccion,
        codigo_postal: datos.codigoPostal,
        estado: datos.estado,
        ciudad: datos.ciudad,
        colonia: datos.direccion2,
        celular: datos.telefono,
        tipo_envio: datos.tipoEnvio,
        promo_code: datos.codigoDescuento,
        card_token: cardToken,
        plataforma:"Web"

    }
    const headers = Object.assign({'content-type': 'application/json'},dataAut);
    const options = {method:"POST",
        headers: headers,
        data:data,
        url
    };
    return request(options);
}