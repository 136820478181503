import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import HotSale from "../../images/HOTDEALS_POPUP.jpg";
class PromoHotSale extends Component{
    constructor(props){
        super(props);
        this.state = {mostrar:false}
    }
    componentDidMount(){
        let hotSale = localStorage.getItem("hotSale");
        if(hotSale){
            this.setState({mostrar:false});
        }
        else{
            this.setState({mostrar:true})
        }
    }
    onHide = ()=>{
        localStorage.setItem("hotSale","done");
        this.setState({mostrar:false});
    }
    render(){
        
        return (            
       <Modal show = {false} onHide ={this.onHide} dialogClassName="modal-lg modal-dialog-centered ">
           <Modal.Body>
               <img src= {HotSale} />
           </Modal.Body>
       </Modal>
        )
    }
}
export default PromoHotSale;