import {reqGuardarLomo} from "./volumenes";
export const guardarLomo = (dataAut,volId, textoLomo,cb)=>{
    return function(dispatch,getState){
        dispatch({type:"CARGANDO"});
        reqGuardarLomo(dataAut,volId,textoLomo).then(resp=>{
            
            dispatch({type:"ACTUALIZAR_LOMO",textoLomo, volId});
            cb();
        }).catch(err=>{
            dispatch({type:"ERROR_APP", valor:"GUARDAR_LOMO"});
        }).finally(()=>{
            dispatch({type:"CARGADO"});
        });
    }
}