import AWS from "aws-sdk";
import config from "./config";
import {hacerThumbnail} from "./images";

AWS.config.update({
    region: config.bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: config.IdentityPoolId
    })
});

const s3 = new AWS.S3({
    params: {Bucket: config.bucketName,
        useAccelerateEndpoint: true
    }
});
 

 export function  subir(foto,cb){                
    //console.log("SUBIENDO");        

    return new Promise((resolve,reject)=>{
        Promise.all([subirFoto(foto),subirThumbnail(foto)]).then(function(){            
            cb("subidas");
            resolve(foto);
        }).catch(err=>{reject(err)});
    });
    
    
}
 export function subirFoto(foto){
    return new Promise((resolve,reject)=>{

        s3.upload({
            Key:"fotos/" + foto.name,
            Body:foto,
            ACL:"private"
        }, function(err, data) {
            if (err) {
                console.log(err);
                console.log('There was an error uploading your photo: ');
                reject(err);
            }
           
            resolve("HECHO");
          })
    })
}
 function subirThumbnail(foto){
    
    return new Promise((resolve, reject)=>{
        hacerThumbnail(foto).then(thumbnail=>{
        s3.upload({
            Key:"fotos/thumbnails/" + foto.name,
            Body:thumbnail,
            ACL:"private"
        }, function(err, data) {
            if (err) {
                console.log(err);
                console.log('There was an error uploading your photo: ');
                reject(err);
            }            
            resolve("HECHO THUMBNAIL");
          })
        });
    })
}
export default s3;    