import React, { Component } from 'react'
import { DragSource } from 'react-dnd';

const Types = {
  FOTO: 'foto'
};

const itemSource = {
  beginDrag(props) {
    const item = { src: props.src, id: props.id }
    return item
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

class DraggableFoto extends Component {
  render() {
    const { isDragging, connectDragSource, src,id } = this.props;
    return connectDragSource(
      <div 
        className='draggableFoto' 
        data-src ={src}
        categoria = "fotos"
        identificador = {id}
        >
      </div>
    )
  }
}

export default DragSource(Types.FOTO, itemSource, collect)(DraggableFoto)