import React,{Component} from "react";
import {connect} from "react-redux";
import {Redirect,Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {comenzar} from "../util/asyncActions";
import logo from "../images/logo-grande.svg";
import ModalError from "./ModalError";
import PromoHotSale from "./promos/PromoHotSale";
import PromoPopUp from "./promos/PromoPopUp";
const comenzarSt = "Comenzar";

const tienesCuenta = "¿Ya tienes una cuenta de Booksnap?";

class Landing extends Component{    
    constructor(props){
        super(props);
        this.props.dispatch({type:"PRE_REGISTRO",previo:this.props.location.pathname})
        console.log(this.props.estado);
    }
    comenzar = ()=>{
        //registrar usuario
        this.props.dispatch(comenzar());
    }
    handleClose = ()=>{
        this.props.dispatch({type:"ERRORES",errores:""})
    }
    
    render(){
        console.log(this.props.estado);

        if (!this.props.hayUsuario){            
            return (
                
                <div className="landing d-flex flex-column align-items-center justify-content-end">
                    
                    <PromoPopUp />
                    <ModalError titulo = {"Error registro"} 
                        mensaje = {`Ocurrió un error al registrar dispositivo, 
                        intente de nuevo mas tarde`}
                        show = {this.props.errores==="REGISTRO"}
                        onHide = {this.handleClose}
                        textoBoton = {""}/>
                    <span></span>
                    <div className="landing-logo">
                        <img  src = {logo} alt = "Booksnap"/> 
                    </div>
                    
 
                        <Button className="landing-start" onClick={this.comenzar}>{comenzarSt}</Button>                    
                        
                        <Link to ={{pathname:  '/landing/login', state: { prevPath: this.props.location.pathname }}} className="landing-login">
                        {tienesCuenta}
                        
                        </Link>
                        <p className="landing-terms">Al ingresar aceptas nuestros <br/> <Link to = "/landing/terminos">términos y condiciones</Link> </p>   
                </div>)
        }
        else{
            //console.log("Ya hay usuario");
            //console.log(this.props.uuid);
            //  console.log(this.props.token);
            return <Redirect to='/proyectos' />
        }
    }
}
const mapStateToProps = (state)=>{
    return {
        estado:state,
        hayUsuario: state.clientes.hayUsuario,
        uuid:state.clientes.uuid,        
        token:state.clientes.token,
        errores: state.clientes.errores
    }
}
export default connect(mapStateToProps)(Landing);