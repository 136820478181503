import config from "./config";
import {request} from "./axiosReqs";
export function reqRegistrarCliente(dataAut,data){
    let url = `${config.urlBackend}/clientes`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {method:"POST",
        headers: headers,
        data: data,
        url
    };
    return request(options);
}

export function reqRecuperarContrasena(email){
    let url = `${config.urlBackend}/clientes/recuperar_password`;
    const headers = Object.assign({'content-type': 'application/json'})
    const options = {method:"POST",
        headers: headers,
        data: {email:email},
        url
    };
    return request(options);
}
export function reqCargarDatos(dataAut){
    let url = `${config.urlBackend}/clientes/datos_cliente`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {method:"GET",
        headers: headers,
        url
    };
    return request(options);
}
export function reqGuardarDatos(dataAut,datos){
    let url = `${config.urlBackend}/clientes/editar`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {method:"POST",
        headers: headers,
        data:datos,
        url
    };
    return request(options);
}