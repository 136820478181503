import React,{Component} from "react";
import {esDefinido} from "../../../util/general";
class Albumes extends Component{
    render(){
        return(<div className="row">
            
            {this.props.albumes.map((album,index)=>{
               return  	<div className="album-wrapper col-xl-4 col-lg-6 col-sm-6" key = {index} onClick = {(ev)=>this.props.irAlbum(album.id)}>
               				<div className="album-cover"  style={{backgroundImage: `url("${album.coverUrl}=w150-h150")`}}></div>
               				<div className="album-info">
	               				<div className="album-name">{album.nombre}</div>
                                   {esDefinido(album.count)?
                                        <div className="album-count">{album.count} Fotos</div>
                                   :
                                        null
                                   }
                                   
    	           			</div>
	           			</div>
            })}
        </div>)
    }
}

export default Albumes;