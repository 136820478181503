//errores es un objeto
//campo es un string
let validar = function(campo,valor,reglas,errores){
    if (!(campo in errores)){ 
        errores[campo] = [];
    }
    if(reglas.includes("notEmpty")){
        if (valor=== ""){
            errores[campo].push(`El campo ${campo} no puede estar vacío` );    }
    }
    if(reglas.includes("email")){
        if (!esEmail(valor)){
            errores[campo].push(`El campo ${campo} debe ser un email válido`)
        }
    }
}
function esEmail(string){
    return (/^.+@([^.\s]+\.{1})+[^.\s]+$/).test(string)
}
function isNumber(string){
    return (/^-?\d*(\.\d+)?$/).test(string);
}
export default validar;