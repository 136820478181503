import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import DraggableFoto from "./DraggableFoto";
const Types = {
  FOTO: 'foto'
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }
}
const itemSource = {
    drop(props, monitor, component) {
        console.log("DROPPED");
        console.log(props);
        console.log(monitor.getItem());
        console.log(component);
        return props.handleDrop(monitor.getItem().id,props.posicion )
    }
}
class Box extends Component {
  render() {
      
    const { connectDropTarget } = this.props;
    return connectDropTarget(
      <div className={"box "+ (this.props.isOver?"isOver":"notOver")}>
        <DraggableFoto handleDrop = {this.props.handleDrop} src = {this.props.src} id = {this.props.id} />
      </div>
    )
  }
}

export default DropTarget(Types.FOTO, itemSource, collect)(Box);