import React,{Component} from "react";
import portadaVacia from "../../images/portada-vacia@3x.png"

class VolumenTematico extends Component{
    render(){
        let vol = this.props.vol;
        return <div className = "contImagenVolumen tematico" onClick = {(ev)=>this.props.irSeleccionarForroTematico(vol.id)}>
            {vol.url_forro === ""?
                <img src = {portadaVacia}/>
            :
                <img src = {vol.url_forro} alt = "Portada"/>
            }
            
            
        
    </div>
    }
}
export default VolumenTematico;