import React,{Component} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "../images/logo-sidebar.svg";
 
import {Link} from "react-router-dom";
 import { LinkContainer } from 'react-router-bootstrap';
import {connect} from "react-redux";
class Sidebar extends Component{
    cerrarSesion=()=>{
        console.log("CERRANDO SESION");
        this.props.dispatch({type:"CERRAR_SESION"});
    }
    irProyectos = ()=>{
        //this.props.dispatch({type:"CARGANDO_PROYECTOS"});
    }
    iniciarSesion = ()=>{
        this.props.dispatch({type:"PRE_INICIAR_SESION",previo:this.props.location.pathname})
        this.props.dispatch({type:"PRE_REGISTRO",previo:this.props.location.pathname})
    }
    render(){
        console.log("RENDER SIDEBAR");
        console.log(this.props);
        console.log(this.props.location);
        return <div className=" border-right" id="sidebar-wrapper">
            <div className="sidebar">
              <div className="sidebar-heading"><Link to ="/" ><img src={logo} alt = "logo-app"/></Link></div>
              <div className="list-group list-group-flush">
                
                
              <Nav activeKey = {null}> 
                <div className="nav-block">
           
                <LinkContainer 
                     onClick = {this.irProyectos} className="list-group-item  list-group-item-action " to = "/proyectos" >
                <Nav.Link  >PROYECTOS</Nav.Link>
                </LinkContainer>
                <LinkContainer  className="list-group-item list-group-item-action " to = "/carrito">
                    <Nav.Link >CARRITO DE COMPRAS</Nav.Link>
                </LinkContainer>
                <a  className="list-group-item list-group-item-action " href = "https://booksnap.co/faq.html"  target="_blank">
                    PREGUNTAS FRECUENTES 
                </a>            
                </div>
              
              { (this.props.token!==null && typeof this.props.token !== "undefined")?
                <div className="nav-block">
                <LinkContainer  className="list-group-item list-group-item-action " to = "/cuenta">
                    <Nav.Link >MI CUENTA</Nav.Link>
                </LinkContainer>            
                
                <LinkContainer  className="list-group-item list-group-item-action " activeClassName = "routerActive" to = "/">
                    <Nav.Link  onClick = {this.cerrarSesion}>CERRAR SESIÓN</Nav.Link>
                </LinkContainer>
                </div>
                :
                <div className = "nav-block"> 

                <LinkContainer  className="list-group-item list-group-item-action " to = {{pathname: '/login', state: { prevPath: this.props.location }}}>
                    <Nav.Link  onClick = {this.iniciarSesion}>INICIAR SESIÓN</Nav.Link>
                </LinkContainer>
                </div>      
                }
                       
            </Nav>
                
       
                  
              </div>
              <a className="web-link link-item" href = "https://booksnap.co"  target="_blank">
                    booksnap.co
                </a> 
          
            </div>
            </div>


       ;
    }
}
const mapStateToProps =(state)=>{
    return {
        token: state.clientes.token}
}
export default connect(mapStateToProps)(Sidebar);