import React,{Component} from "react";
import {connect} from "react-redux";
import {Redirect,Link} from "react-router-dom";
import Navegacion from "../Navegacion";
import iconDelete from "../../images/design_icon_delete.svg";
import ModalConfirmacion from "../modales/ModalConfirmacion";   
import {listarProyectos} from "../../util/asyncActions";
import Observer from "../../util/imageObserver";
import ModalError from "../ModalError";
import {getDataAut} from "../../util/general";
import ModalSpinner from "../modales/ModalSpinner";
import {eliminarProyecto} from "../../util/asyncProyectos";
import ProyectoTematico from "../portadasTematicas/ProyectoTematico";
class Proyectos extends Component{
    constructor(props){
        super(props);
        

        if(!this.props.hayUsuario){
            this.props.history.push("/");
            return;
        }
        this.props.dispatch({type:"CARGANDO_PROYECTOS"});
        let dataAut = getDataAut(this.props.token, this.props.uuid);
        this.props.dispatch(listarProyectos(dataAut));
        
    }
    componentDidUpdate(prevProps,prevState){
        if(!this.props.cargando){
            const imgs = document.querySelectorAll('div[data-src]');
            imgs.forEach(img=>{
                Observer.observe(img);
            })
        }

        if(!this.props.cargando && this.props.proyectos.length === 0){
            this.props.history.push("/proyectos/crear");
        }        
    }
    clickProyecto = (id,nombre)=>{
        this.props.dispatch({type:"SELECCIONAR_PROYECTO",id:id,nombre:nombre});
        this.props.history.push("./proyectos/editar");
    }
    clickEliminar = (id)=>{

        this.props.dispatch({type:"SELECCIONAR_PROYECTO",id:id,nombre:""});
        this.props.dispatch({type:"ALERTAR_PROY",valor:"ELIMINAR"})
    }
    eliminarProyecto = ()=>{        
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        this.props.dispatch({type:"LIMPIAR_ALERTA_PROY"});
        this.props.dispatch(eliminarProyecto(dataAut,this.props.proyectoSeleccionado,this.props.history));        
    }   
    listarProyectos = ()=>{

        return this.props.proyectos.map((proy,index)=>{
            return <div className="proyecto d-flex d-sm-block  col-sm-4  text-sm-center" key = {index}
                onClick =  {()=>{this.clickProyecto(proy.id, proy.nombre)}}>
                {
                    sinPortada(proy)?
                    <div className = "proyecto-libro">
                    <a className="delete-btn"
                        onClick = {(e)=>{
                            e.stopPropagation();
                            this.clickEliminar(proy.id);
                            
                        }}>
                        <img src = {iconDelete} alt = "Eliminar"/>
                    </a>
                        <div className = "proyecto-libro-inner">
                        </div>
                    </div>
                    :
                        proy.has_tematico?
                        <ProyectoTematico onClickDelete = {(e)=>{
                            e.stopPropagation();
                            this.clickEliminar(proy.id);
                            
                            }}
                            src = {proy.forro_portada}
                            id = {proy.id}
                            />
                        :
                        <div className = "proyecto-libro">
                        <a className="delete-btn" 
                            onClick = {(e)=>{
                                e.stopPropagation();
                                this.clickEliminar(proy.id);
                                
                                }}>
                            <img src = {iconDelete} alt = "Eliminar"/>
                        </a>
                        <div data-src = {proy.portada} 
                            categoria = "portadasProy"
                            identificador = {proy.id}
                            className = "proyecto-libro-inner">
                        </div>
                        </div>
                }
                <div className="proyecto-info">
                <div className="proyecto-nombre">{proy.nombre}</div>
                <div className="proyecto-pasta">{proy.portada_rigida?"Pasta Dura":"Pasta Suave"}</div>
                <div className="proyecto-count">{proy.fotos.count+"/"+proy.fotos.objetivo} Fotos</div>
                </div>
            </div>
        });
    }
    render(){       
        

        
        return(<div>
            <ModalConfirmacion show = {this.props.alertar && this.props.alerta === "ELIMINAR"}
                mensaje= "¿Estás seguro que deseas eliminar este proyecto? Esta acción es irreversible." 
                titulo = "Eliminar proyecto" 
                confirmarbtn="Eliminar" confirmar={this.eliminarProyecto} cancelar = {(ev)=>{this.props.dispatch({type:"LIMPIAR_ALERTA_PROY"})}}/>
            <ModalSpinner show = {this.props.cargando}/>
            <ModalError
                titulo = {"ERROR PROYECTOS"}
                mensaje ={"Ocurrió un error al cargar proyectos, refresque la página o intente más tarde "}
                handleClose = {(ev)=>{
                  
                }}
                textoBoton = {""}
                show = {this.props.errores === "PROYECTOS"}
                />
                <ModalError
                titulo = {"ERROR AUTENTICACIÓN"}
                mensaje ={"Ocurrió un error de autenticación, se borrará la información de sesión"}
                handleClose = {(ev)=>{
                    this.props.dispatch({ type:"ERRORES", errores:"" })
                    this.props.history.push("/");
                }}
                textoBoton = {"CONTINUAR"}
                show = {this.props.errores === "AUTENTICACION"}
                />

                <Navegacion location = {this.props.location.pathname}/>
                <div id = "inner-content-wrapper">
                    <div className = "title-bar"> MIS PROYECTOS</div>
                    <div className='inner-content'>
                    
                        <div className="inner-content row">
                            {this.props.errores ===""?this.listarProyectos():null}
                        </div>
                    
                    <Link to = "proyectos/crear" className="btn-general btn btn-primary">  NUEVO PROYECTO</Link>
                    </div>
                </div>
         </div>)
    }
}
function sinPortada(proy){
    return (proy.has_tematico && proy.forro_portada === "") || (!proy.has_tematico && proy.portada === "")
}
const mapStateToProps = (state)=>{
    return {
        hayUsuario: state.clientes.hayUsuario,
        uuid:state.clientes.uuid,
        errores:state.clientes.errores,
        token: state.clientes.token,
        cargando:state.proyectos.cargando,
        proyectos:state.proyectos.proyectos,
        mostrarSidebar: state.navegacion.mostrarSidebar,
        alertar: state.proyectos.alertar,
        alerta: state.proyectos.alerta,
        proyectoSeleccionado: state.proyectos.proyectoSeleccionado
    }
}
export default connect(mapStateToProps)(Proyectos);