import React, { Component } from 'react';
import Navegacion from "./Navegacion";
import textoFaq from "../util/textofaq";
class FAQ extends Component{
    listar = ()=>{
       return  textoFaq.map((el,index)=>
            <div className="pregunta-block" key ={index}>
                <div className="pregunta" >
                    {el.pregunta}
                </div>
                <div  className="respuesta">
                    {el.respuesta}
                </div>
            </div>

        )
    }
    render(){
        return <div id = "inner-content-wrapper">
            <Navegacion/>

            
                
                <div className="title-bar">
                    PREGUNTAS FRECUENTES
                </div>

            <div className = "preguntas">{this.listar()}</div>
            
            </div>
    }
}

export default FAQ;