import {reqRegistrarFotos} from "./proyectos";
import {reqBorrarMasivo} from "./fotos";
import {writeLog} from "./logs";
export function registrarFotos(dataAut,meta,proy,history,creando){
    return function(dispatch,getState){
        
        reqRegistrarFotos(dataAut,meta,proy).then((RESP)=>{
            
            dispatch({type:"TERMINAR_CARGA"});
            dispatch({type:"SUBIDAS_FOTOS"});
            if(!creando)
                history.push("/proyectos/editar")
        }).catch((err)=>{
            
            console.log("ERROR ASYNC REGISTRAR FOTOS");
            console.log(err);
            dispatch({type:"ERROR_PROYECTO",valor:"SUBIR_FOTOS"})
            let payload = {
                error: err.message,
                function: "app/proyectos/integraciones/GooglePhotos/componentDidMount",
                browser:window.navigator.appVersion,
                
            }
            
            writeLog(payload).then(resp=>console.log("SE HA CREADO LOG")).catch(err2=>{
                console.log("no se pudo crear log")
                console.log(err2);
            });
        })
    }
}

export  function eliminarSeleccion(dataAut,fotosPorBorrar,proyId,cb){
    return async function(dispatch,getState){
        /*
        let status = {
            objetivo: fotosPorBorrar.length,
            terminadas: 0,
            rechazadas:0
        }
        dispatch({type:"CARGANDO_FOTOS"});
        for(let x = 0; x< fotosPorBorrar.length;x++){
            let foto = fotosPorBorrar[x];
            try{  
                console.log("ELIMINANDO ");
                console.log(foto);
                await reqEliminarFoto(dataAut,foto);
                console.log("ELIMINADA SIN ERROR");
                status.terminadas ++;
            }catch(err){
                console.log("ERROR AL BORRAR");
                console.log(foto);
                status.terminadas ++;
                status.rechazadas ++;
            }finally{
                if(status.terminadas === status.objetivo){
                    console.log("TERMINADO PROCESA");
                    console.log(status);
                    if(status.rechazadas > 0){
                        dispatch({type:"ERROR_PROYECTO",valor:"BORRADO_MASIVO"});
                    }
                    else{
                        cb();   
                    }
                    dispatch({type:"CARGADO_FOTOS"});
                    
                }
            }
        }    
        */
       dispatch({type:"CARGANDO_FOTOS"});
       reqBorrarMasivo(dataAut,fotosPorBorrar,proyId).then(resp=>{
        cb();  
       }).catch(err=>{
        dispatch({type:"ERROR_PROYECTO",valor:"BORRADO_MASIVO"});
       }).finally(
           ()=>dispatch({type:"CARGADO_FOTOS"})
        )
    }
}

