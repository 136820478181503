import React, { Component } from 'react';
import {  Route,Switch,Redirect } from 'react-router-dom';
import Landing from "./app/Landing";
import Proyectos from "./app/proyectos/Proyectos";
import Terminos from "./app/Terminos";
import Login from "./app/Login";
import FAQ from "./app/FAQ";
import {connect} from "react-redux";
import Editor from "./app/proyectos/Editor";
import Creador from "./app/proyectos/Creador";
import Configurar from "./app/proyectos/Configurar";
import SeleccionarFotos from "./app/proyectos/SeleccionarFotos";
import EditarCaption from "./app/EditarCaption";
import Carrito from './app/carrito/Carrito';
import RegistroLogin from "./app/RegistroLogin";
import Registrar from "./app/Registrar";
import RecuperarContrasena from "./app/clientes/RecuperarContrasena";
import Sidebar from "./app/Sidebar";
import Cuenta from "./app/clientes/Cuenta";
import Pago from "./app/carrito/Pago";
import ModalSpinner from "./app/modales/ModalSpinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {esIOS, esMovil,esAndroid} from "./util/movil";
import GooglePhotos from "./app/proyectos/Integraciones/GooglePhotos";
import SeleccionForroTematico from "./app/proyectos/SeleccionForroTematico";
import SeleccionPortada from "./app/proyectos/SeleccionPortada";
import "intersection-observer";
const pathsValidos = `/:path(proyectos|cuenta|recuperar_contrasena|registrar|cuenta_requerida|carrito|fotos|terminos|faq|login|seleccionForroTematico|seleccionPortada)`
class App extends Component {
  constructor(props){
    super(props);
   
    this.props.dispatch({type:"INICIALIZAR"});
    this.props.dispatch({type:"CARGANDO_PROYECTOS"});
  }

  render() {
    if(esIOS()){
      
      window.location.replace("https://itunes.apple.com/mx/app/booksnap-fotolibros/id1388117306?mt=8");
     return null;
    }
    if(esAndroid()){
      
      window.location.replace("https://play.google.com/store/apps/details?id=app.booksnap.co.booksnap&hl=es_MX");
      return null;
    }
    let clase = this.props.mostrarSidebar?"":"toggled";
    
    if(!this.props.iniciado){
      return <ModalSpinner show = {true}/>
    }
    return (
      <div className={`d-flex flex-column`} id="wrapper">
      {this.props.mostrarAdvertenciaNavegador?
       <div id="ChromeAlert" className="d-flex flex-column flex-sm-row " >
        <div>Para tener una mejor experiencia con nuestra App te recomendamos usar <a href = "https://www.google.com/chrome/" className="font-weight-bold">Google Chrome</a></div>
        <a href className="ChromeAlertOK btn btn-outline-light" onClick ={ ()=>this.props.dispatch({type:"VISTA_ADVERTENCIA_NAVEGADOR"})}>ACEPTAR</a>
      </div>
       
       :null
       }
       
       <div className={`d-flex flex-fill`} >
       
       
        <Switch>
        
          <Route exact path = "/" component = {Landing}/>
          <Route path = {"/landing/login"} render = {(props) => <Login {...props} navegable={false}  />}/>
          <Route path = {"/landing/terminos"} render = {(props) => <Terminos {...props} navegable={false}  />}/>
          <Route path = {"/landing/recuperar_contrasena"} render = {(props) => <RecuperarContrasena {...props} navegable={false}  />}/>
          <Route path = "/landing/registrar" component = {Registrar} render = {(props)=><Registrar {...props} navegable = {false}/>}/>
          <Route path = {pathsValidos} render = {(props) => <Navegable {...props} clase={clase}  />}/>
          
        </Switch>
      </div>
      </div>
    );
  }
}
class Navegable extends Component{  
  render(){
    
     return(<div className={`inner-wrapper d-flex ${this.props.clase}`} >
     
     <Sidebar location = {this.props.location}/>  
      <div id="page-content-wrapper">
      <Route path = "/seleccionPortada" component = {SeleccionPortada}/>
      <Route path = "/seleccionForroTematico" component = {SeleccionForroTematico}/>
      <Route path = "/carrito/pago" component = {Pago}/>
      <Route path = "/cuenta" component = {Cuenta}/>
      <Route path = "/recuperar_contrasena" render = {(props) => <RecuperarContrasena {...props} navegable={true}  />}/>
      <Route path = "/registrar" component = {Registrar}/>
      <Route path = "/cuenta_requerida" component = {RegistroLogin}/>
      <Route exact path = "/carrito" component = {Carrito}/>
      <Route path = "/fotos/editarCaption" component = {EditarCaption}/>
      <Route path = "/proyectos/seleccionarFotos" component = {SeleccionarFotos}/>
      <Route path = "/proyectos/configuracion" component = {Configurar}/>
      <Route path = "/proyectos/editar" component = {Editor}/>
      <Route path = "/proyectos/crear"component = {Creador}/>
      <Route exact path = "/proyectos" component = {Proyectos}/>
      <Route path = "/terminos" component = {Terminos}/>
      <Route path = "/faq" component = {FAQ}/>
      <Route path = "/login" render ={(props)=><Login {...props} navegable={true}/>}/>

      </div>
      </div>)
  }
}
const mapStateToProps = (state)=>{
  return {mostrarSidebar: state.navegacion.mostrarSidebar,iniciado:state.clientes.iniciado,
    mostrarAdvertenciaNavegador: state.clientes.mostrarAdvertenciaNavegador}
}
export default connect(mapStateToProps)(App);
