import React,{Component} from "react";
import iconExito from "../../images/icon-exito.svg";
import Navegacion from "../Navegacion";

class CompraExitosa extends Component{
    render(){
        return <div id = "inner-content-wrapper" className = "d-flex flex-column">
                        <Navegacion/>

            <div className="title-bar">                        
                PAGO EXITOSO
            </div>
            <div className=" inner-content text-center d-flex flex-column flex-fill justify-content-center align-items-center">
                <img className="mb-5" src={iconExito} alt="Compra exitosa"/>

                <p className="">COMPRA EXITOSA</p>
                <p>Tu compra se ha realizado con éxito.<br/>
                Te enviaremos un correo con toda la información</p>
            </div>
        </div>
    }
}
export default CompraExitosa;