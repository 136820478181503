const textofaq = [
    {pregunta:"¿Qué es Booksnap?", respuesta:"Booksnap es un App móvil para poder imprimir fotolibros desde tu celular en segundos! :) Imprime tus mejores recuerdos!"},
    {pregunta:"¿Qué tipos de fotolibros puedo realizar?",respuesta:"Actualmente ofrecemos dos tipos de álbumes. Pasta Suave ($299 MXN) y Pasta Dura ($349 MXN). Los precios incluyen envío a toda la Republica Mexicana!"},
    {pregunta:"¿Qué características tiene un Booksnap?",respuesta:"Cada fotolibro puede ser de pasta dura o pasta suave. Mide 16x16 cm y consta de 60 páginas, una foto por página. Más adelante se podrán agregar más páginas :)."},
    {pregunta:"¿Cuáles son las políticas de envío?",respuesta:`Hacemos envíos a toda la República Mexicana por medio de FedEx y el envío es sin costo! Recibirás tu Booksnap dentro de 8-10 días hábiles*. Una vez que realices tu compra recibirás el número de rastreo FedEx con el que viajará tu paquete. Puedes rastrearlo en todo momento en la página web: www.fedex.com
    Ofrecemos un Envío EXPRESS para que recibas tu Booksnap dentro de 6-8 días hábiles*. Este envío tendrá un costo adicional de $50 MXN.
    Es importante considerar que los días de entrega empiezan a correr a partir del siguiente día hábil de cuando se realizó el pedido.
    *Los días de entrega pueden variar si el código postal de entrega es considerado una zona extendida para FedEx.
    Si no estás satisfecho con tus Booksnaps, contáctese con contacto@booksnap.co dentro de los 30 días de su compra, para solicitar un reembolso completo y aproveche nuestra Garantía de devolución de dinero de 30 días. Todos los reembolsos se acreditarán a la tarjeta de crédito utilizada en el momento de la compra. Los reembolsos tardan de 5 a 10 días habiles en procesarse, una vez que se ha emitido el reembolso.`},
    {pregunta:"¿Cuál es la política de devolución de Booksnap?",respuesta:"¡Queremos asegurarnos de que estés 100% satisfecho con tus Booksnaps! Por favor, póngase en contacto con contacto@booksnap.co si tienes algún problema con tu pedido, y estaremos encantados de hacer todo lo posible para hacerlo correctamente."},
    {pregunta:"¿Cómo procesamos tus pagos?",respuesta:"Todos los pagos se realizan a través de Stripe (https://www.stripe.com/) y aceptamos las principales tarjetas de crédito (VISA, MASTERCARD Y AMERICAN EXPRESS)."},
    {pregunta:"¿De qué plataformas puedo agregar fotos?",respuesta:"Actualmente contamos con integración con INSTAGRAM y el CARRETE de tu celular."},
    {pregunta:"¿Cómo agrego fotos?",respuesta:"Da click en “Nuevo Proyecto” y posteriormente selecciona que tipo de pasta desees. Una vez seleccionado, te aparecen las opciones de “Carrete” e “Instagram”. Recuerda que puedes agregar fotos de las dos fuentes en un mismo fotolibro."},
    {pregunta:"¿Cómo pido un Booksnap?",respuesta:"Una vez que agregas el “Proyecto” al carrito de compras es necesario ingresar la dirección de entrega así como los datos de la tarjeta de crédito con la que se va a realizar el pago."},
    {pregunta:"¿Cómo puedo contactar a Servicio al Cliente?",respuesta:"Nos encanta escuchar tus comentarios! Por favor escríbenos a contacto@booksnap.co para cualquier duda o comentario."},
    {pregunta:"¿Qué pasa si agrego mas de 60 fotos?",respuesta:"Cada uno de los fotolibros está compuesto de 60 páginas (60 fotos). Si agregas más de 60 fotos se crea un segundo volumen y nuevamente es necesario seleccionar 60 fotos. Esto aplica para los volúmenes que desees."},
    {pregunta:"¿Qué pasa si no recuerdo mi contraseña?",respuesta:"En la página de “Login” selecciona la opcion “Olvide mi Contraseña” y recibirás un correo electrónico con las instrucciones para poder restablecer tu contraseña."},
    {pregunta:"¿Puedo agregar fotos de Instagram y Carrete en el mismo álbum?",respuesta:"Si! Puedes seleccionar fotos de las dos fuentes."},
    {pregunta:"¿Qué significa el aviso de “Baja Calidad” que aparece en algunas imágenes?",respuesta:`Sabemos que cada momento es muy especial! Por eso queremos que tu Booksnap tenga la mejor calidad. Si alguna foto tiene el aviso de “Baja Calidad” significa que no cumple con nuestros estándares de calidad de impresión; nuestra recomendación es que selecciones otra imagen.`},
    {pregunta:"¿Cómo puedo aplicar un código de descuento?",respuesta:"Una vez que agregas el “Proyecto” al carrito de compras, en la página de “Checkout” donde ingresas los datos de dirección de entrega y tarjeta de crédito se encuentra un campo de “Código de Descuento”. Ingresa el código y listo!"},
    {pregunta:"¿Existe algún límite de volúmenes en un proyecto?",respuesta:"No! Sabemos que tus memorias no tienen limites asi que puedes imprimir los volúmenes que quieras en un proyecto! :)"},
    {pregunta:"¿Puedo editar o cancelar un proyecto ya ordenado?",respuesta:"No… Lamentablemente para asegurarnos de que recibas tus fotolibros en tiempo y forma, nuestros procesos de impresión son prácticamente en tiempo real por lo que ya no es posible modificar el proyecto."},
    {pregunta:"¿Puedo reimprimir algún proyecto realizado en el pasado?",respuesta:"Si! Desde la pantalla de “Mis Proyectos” puedes seleccionar, editar y reordenar cualquier proyecto. Ej. Olvide ordenar una copia del fotolibro de mi cumpleaños para el Abuelo José!"},
    {pregunta:"¿Puedo personalizar el Lomo de mi fotolibro?",respuesta:"Si! El nombre del proyecto es el que aparecerá en el lomo… Cada 60 fotos se crea un Volumen y el lomo siempre lleva el rango de fechas de cuándo fueron tomadas las fotos."},

];

export default textofaq;