const estadoInicial = {
    cargando:false,
    error:""
}

const AppReducer = (state = estadoInicial, action)=>{
    let res = {};
    switch(action.type){
        case "CARGANDO":   
            res = Object.assign({},state,{cargando:true});
            return res;
        case "CARGADO":
            res = Object.assign({},state,{cargando:false});
            return res;
        case "ERROR_APP":
            res = Object.assign({},state,{error:action.valor});
            return res;
        case "LIMPIAR_ERRORES_APP":
            res = Object.assign({},state,{error:""});
            return res;
        default:
            return res;
    }
}


export default AppReducer;
