import config from "./config";
import uuid  from "uuid/v5";
import {request} from "./axiosReqs"

export function reqRegistrarAnonimo(cadena, CBExito, CBError){
    console.log('REGISTRAR ANONIMO');
    let uuidBSP = uuid('booksnap.co', uuid.DNS);
    let assigned = uuid(cadena,uuidBSP).toUpperCase();
    const url = `${config.urlBackend}/clientes/uuid`;
    const options = {
        method:"POST",
        headers: { 
            'content-type': 'application/json',
            'uuid':assigned },            
            url
        };
    request(options).then(resp=>{
        CBExito(assigned);
    }).catch(err=>{
        CBError();
    })
}
export function reqIniciarSesion(datos,CBExito,CBError){
    
    let url = `${config.urlBackend}/clientes/auth`;
    
    
    
    const options = {
        method:"POST",
        headers: { 
            'content-type': 'application/json',
        },
        data:datos,             
            url
        };
    request(options).then(resp=>{

        CBExito(resp.data.token);
    }).catch(err=>{
        CBError();
    })
    
}
export function reqIniciarSesionTardia(datos,uuid,CBExito,CBError){    
    let url = `${config.urlBackend}/clientes/auth_carrito`;
    const options = {
        method:"POST",
        headers: { 
            'content-type': 'application/json',
            uuid:uuid
        },
        data:datos,             
            url
        };
    request(options).then(resp=>{
        CBExito(resp.data.token);
    }).catch(err=>{
        CBError(err);
    })
    
}