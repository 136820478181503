import React,{Component} from "react";
import {connect} from "react-redux";
import Navegacion from "../Navegacion";
import iconPlus from "../../images/design_icon_add.svg";
import iconSettings from "../../images/design_icon_config.svg";
import iconDelete from "../../images/design_icon_delete.svg";
import iconCancel from "../../images/design_icon_cancel.svg";
import iconConfirm from "../../images/design_icon_accept.svg";
import {previsualizarProyecto,eliminarProyecto,agregarCarrito} from "../../util/asyncProyectos";
import {Link} from "react-router-dom";
import Observer from "../../util/imageObserver";
import PreviewFotos from "./PreviewFotos";
import Volumenes from "./Volumenes";
import {ERROR_ENTRADA_PROYECTO,ERROR_ELIMINAR_PROY,ERROR_REORDENAMIENTO, ERROR_SELECCION_PORTADA, ERROR_AGREGAR_CARRITO,
    ERROR_BORRADO_MASIVO}from "../../util/errores";
import { getDataAut } from "../../util/general";
import ModalSpinner from "../modales/ModalSpinner";
import ModalConfirmacion from "../modales/ModalConfirmacion";
import ModalesError from "../ModalesError";
import Modal from "react-bootstrap/Modal";
import overlay from "../../images/overlay-editor.png";
import {esDefinido} from "../../util/general";
import Button from "react-bootstrap/Button";
import {eliminarSeleccion} from "../../util/asyncFotos";
var overlayStyle = {
    backgroundImage: `url(${overlay})`
};

const CANT_FOTOS_VOL = 60;

const dimBase = 160;
class Editor  extends Component{
    constructor(props){
        super(props);
        this.state = {
            fase:"fotos"}
     this.cargar();   
    }
    componentDidMount(){
        if(this.props.location.state && this.props.location.state.from &&(
            this.props.location.state.from === "/seleccionForroTematico"||
            this.props.location.state.from === "/seleccionPortada"))
            this.setState({fase:"volumenes"})
    }
    dismissModal = ()=>{    
        this.props.dispatch({type:"VISITADO_OVERLAY",overlay:"visitadoReordenamiento"});
    }
    entrarModoBorradoMasivo = ()=>{
        this.props.dispatch({type:"ENTRAR_MODO_BORRADO_MASIVO"});
    }
    salirModoBorradoMasivo = ()=>{
        
        this.props.dispatch({type:"SALIR_MODO_BORRADO_MASIVO"});
    }
    atras = ()=>{
        if(this.state.fase ==="fotos")
            this.props.history.push("/proyectos");
        else 
            this.setState({fase:"fotos"});
    }
    componentWillUnmount(){

        if(this.props.history.action === "POP"){
            this.props.history.push("/proyectos");
        }
    }
    componentDidUpdate(){

            const imgs = document.querySelectorAll('div[data-src]');
            imgs.forEach(img=>{
                Observer.observe(img);
            })
    }
    agregarCarrito = (vols)=>{
        
        if(this.validarCompletos(vols)){
            this.confirmarAgregarCarrito();
        }
        else
            this.props.dispatch({type:"ALERTAR_PROY",valor:"CARRITO"})
    }
    validarCompletos = (vols)=>{
        
        //let vols = this.props.volumenes;
        for(let i = 0;i<vols.length;i++){
            if(vols[i].cant_fotos !== CANT_FOTOS_VOL ||
                    this.sinPortada(vols[i])){
                return false;
            }
        }
        return true;
    }

    sinPortada = (vol)=>{
        let hasTematico = this.props.proyectos.find(proy=>proy.id === this.props.proyectoSeleccionado).has_tematico;
        if(hasTematico&&vol.url_forro === ""){
            return true;
        };
        if(!hasTematico && vol.url_portada === ""){
            return true;
        }
        return false;
    }

    cargar = ()=>{
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        this.props.dispatch(previsualizarProyecto(dataAut,this.props.proyectoSeleccionado));
    }
    irEdicionCaption = (id)=>{
        if (this.props.modoBorradoMasivo)
            return;
        this.props.dispatch({type:"SELECCIONAR_FOTO",id:id});
        this.props.history.push("/fotos/editarCaption");        
    }
    eliminarProyecto = ()=>{        
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        this.props.dispatch({type:"LIMPIAR_ALERTA_PROY"});
        this.props.dispatch(eliminarProyecto(dataAut,this.props.proyectoSeleccionado,this.props.history));        
    }   
    continuar =()=>{
        this.setState({fase:"portadas"});
    }
    confirmarAgregarCarrito = ()=>{        
        let dataAut =getDataAut(this.props.token,this.props.uuid);
        this.props.dispatch(agregarCarrito(dataAut,this.props.proyectoSeleccionado,this.props.history));        
    }
    obtenerErrores =()=>{
        let errores = [];
        errores.push(ERROR_ENTRADA_PROYECTO(this.props.errores === "ERROR",
        (ev)=>{
            this.props.dispatch({type:"LIMPIAR_ERRORES_PROYECTO"});
            this.props.history.push("/proyectos");
        }));
        errores.push(ERROR_ELIMINAR_PROY(this.props.errores === "ELIMINAR_PROYECTO",
        (ev)=>{
            this.props.dispatch({type:"LIMPIAR_ERRORES_PROYECTO"});
          }));
        errores.push(ERROR_REORDENAMIENTO(this.props.erroresFotos === "REORDENAMIENTO",
        (ev)=>{
            this.props.dispatch({type:"LIMPIAR_ERRORES_FOTO"});
          }));
        errores.push(ERROR_SELECCION_PORTADA(this.props.erroresFotos === "SELECCION_PORTADA",
        (ev)=>{
            this.props.dispatch({type:"LIMPIAR_ERRORES_FOTO"});
          }));
        errores.push(ERROR_AGREGAR_CARRITO(this.props.errores === "AGREGAR_CARRITO",
        (ev)=>{
            this.props.dispatch({type:"LIMPIAR_ERRORES_PROYECTO"});
          }))
        errores.push(ERROR_BORRADO_MASIVO(this.props.errores === "BORRADO_MASIVO",()=>{
            
            this.props.dispatch({type:"LIMPIAR_ERRORES_PROYECTO"})
            this.salirModoBorradoMasivo();
            this.cargar();
        }
        ))
        return errores;
    }
    clickEliminar = ()=>{
        if(this.state.fase === "fotos"){
            this.entrarModoBorradoMasivo();
        }
        else{
            this.props.dispatch({type:"ALERTAR_PROY",valor:"ELIMINAR"})
        }
        
    }
    eliminarSeleccion = ()=>{
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        this.props.dispatch(eliminarSeleccion(dataAut,this.props.fotosPorBorrar,this.props.proyectoSeleccionado,()=>{
            this.salirModoBorradoMasivo();
            this.cargar();
        }));

    }
    render(){
        return(
            <div className="">
                <Modal show = {!this.props.visitadoReordenamiento && esDefinido(this.props.uuid)} 
                    onHide = {this.dismissModal}
                className="modal-overlay" style={overlayStyle}>
                    
                </Modal>
                <ModalesError modales = {this.obtenerErrores()}/>
                <ModalConfirmacion show = {this.props.alertar && this.props.alerta === "ELIMINAR"}
                mensaje= "¿Estás seguro que deseas eliminar este proyecto? Esta acción es irreversible." 
                titulo = "Eliminar proyecto" 
                confirmarbtn="Eliminar" confirmar={this.eliminarProyecto} cancelar = {(ev)=>{this.props.dispatch({type:"LIMPIAR_ALERTA_PROY"})}}/>
                <ModalConfirmacion show = {this.props.alertar&& this.props.alerta === "CARRITO"}
                mensaje= "Los volúmenes que no cuentan con las 60 fotos necesarias para su impresión o imagen de portada se omitirán en tu orden de compra. ¿Deseas continuar?" 
                titulo = "Alerta" confirmarbtn="Continuar" confirmar ={this.confirmarAgregarCarrito}
                cancelar = {(ev)=>{
                this.props.dispatch({type:"LIMPIAR_ALERTA_PROY"})}}/>
                <ModalSpinner show = {this.props.cargandoFotos}/>
                <Navegacion/>
                <div id = "inner-content-wrapper" 
                    className={`editor d-flex flex-column ${this.props.modoBorradoMasivo?"modo-eliminar":""}`}>
                    <div className="title-bar">
                        <span className="back-icon" onClick = {this.atras}> </span>
                        {this.props.nombre}
                    </div>
                    <div  className="subtitle-bar">{this.state.fase === "fotos"?"Organiza y Edita tus Volúmenes":"Preview Lomo y Portada"}</div>
                    
                        
                        <div className = "opcionesProyecto">
                            <div>{this.props.cuentaFotos}/{CANT_FOTOS_VOL * this.props.volumenes.length} Fotos</div>
                            <div className="opciones-default">
                               
                                <div>
                                <Link to = "/proyectos/seleccionarFotos">
                                    <img src = {iconPlus} alt = "Agregar fotos"/> 
                                    <span className="d-none d-lg-inline"> AGREGAR FOTOS</span>
                                </Link>
                                <Link to = "/proyectos/configuracion">
                                    <img src = {iconSettings} alt = "Configurar"/>
                                    <span className="d-none d-lg-inline"> CONFIGURACIÓN</span>
                                </Link>
                                <a className = "eliminarProyecto" onClick = {this.clickEliminar}>
                                    <img src = {iconDelete} alt = "Eliminar"/>
                                    <span className="d-none d-lg-inline"> ELIMINAR</span>
                                </a>
                                </div>
                                
                            </div>
                            <div className="opcion-eliminar">
                                <a className = "eliminarProyecto">
                                    <img src = {iconCancel} alt = "Eliminar"/>
                                    <span className="d-none d-lg-inline"
                                        onClick = {this.salirModoBorradoMasivo}>SALIR DE MODO ELIMINAR</span>
                                </a>
                                {this.props.fotosPorBorrar.length > 0?
                                <a className = "eliminarProyecto">
                                    <img src = {iconConfirm} alt = "Eliminar"/>
                                    
                                        <span className="d-none d-lg-inline"
                                            onClick = {this.eliminarSeleccion}>ELIMINAR SELECCIÓN</span>
                                        
                                </a>
                                :
                                null
                                }
                            </div>
                        </div>
                    <div className = " inner-content pt-0 pb-0 ">

                        {this.state.fase === "fotos"?
                        <PreviewFotos
                            volumenes = {this.props.volumenes}
                            irEdicionCaption = {this.irEdicionCaption}
                            dispatch ={this.props.dispatch}
                            token = {this.props.token}
                            uuid = {this.props.uuid}
                            proyectoSeleccionado = {this.props.proyectoSeleccionado}
                            continuar = {this.continuar}
                            history={this.props.history}
                            />
                        :
                            <div className="portadas-wrapper">
                                    <Volumenes
                                    agregarCarrito = {this.agregarCarrito}
                                    history = {this.props.history}/>
                            </div>
                        }
                    </div>
                </div>  
            </div>
        );
    }
}

const mapStateToProps = (state)=>{
    return {
        nombre: state.proyectos.nombre,
        proyectoSeleccionado: state.proyectos.proyectoSeleccionado,
        token: state.clientes.token,
        uuid: state.clientes.uuid,
        cuentaFotos: state.proyectos.cuentaFotos,
        volumenes: state.proyectos.volumenes,
        proyectos: state.proyectos.proyectos,
        nombre: state.proyectos.nombre,
        errores: state.proyectos.errores,
        alertar: state.proyectos.alertar,
        cargandoFotos: state.fotos.cargando,
        erroresFotos: state.fotos.errores,
        alerta: state.proyectos.alerta,
        visitadoReordenamiento: state.clientes.visitadoReordenamiento,
        modoBorradoMasivo: state.proyectos.modoBorradoMasivo,
        fotosPorBorrar: state.proyectos.fotosPorBorrar
    }
}
export default connect(mapStateToProps)(Editor);