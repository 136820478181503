import {request} from "./axiosReqs";
import config from "./config";
export function reqGuardarCaption(dataAut,fotoId,caption){
    let url = `${config.urlBackend}/fotos/${fotoId}/caption`;
    const headers = Object.assign({'content-type': 'application/json',
    "version-app":"1.5.14"
    },dataAut)
    const options = {method:"POST",
        headers: headers,
        data: {caption:caption},
        url
    };
    return request(options);
}

export function reqEliminarFoto(dataAut,fotoId){
    let url = `${config.urlBackend}/fotos/${fotoId}`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {method:"DELETE",
        headers: headers,
        url
    };
    return request(options);
}
export function reqReordenar(dataAut,proyId, datos){
    let url = `${config.urlBackend}/proyectos/${proyId}/reordenar`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {method:"POST",
        headers: headers,
        data:{foto:datos},
        url
    };
    return request(options);
}

export function reqBorrarMasivo(dataAut,fotoIds,proyId){
    let url = `${config.urlBackend}/fotos/borrado_masivo`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const data ={
        foto_ids:fotoIds,
        proy_id: proyId
    }
    const options = {method:"DELETE",
        headers: headers,
        data:data,
        url
    };
    return request(options);
}