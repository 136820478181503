import React,{Component} from "react";
import {connect} from "react-redux";
import {reqObtenerVolumenes} from "../../util/proyectos";
import portada from "../../assets/respaldo-portada@3x.png";
import contraportada from "../../assets/respaldo-contraportada@3x.png";
import lomo from "../../assets/respaldo-lomo@3x.png";
import noportada from "../../assets/btn-portada.png";
import SeleccionPortada from "./SeleccionPortada";
import Observer from "../../util/imageObserver";
import {esDefinido} from "../../util/general";
import {reorientar, extraerExif} from "../../util/FotoBSP";
import {reqSeleccionarPortada} from "../../util/proyectos";
import {togglearPortadaTematica,cargarConfiguracion} from "../../util/asyncProyectos";
import {getDataAut} from "../../util/general";
import {obtenerImg,hacerThumbnail,cropear } from "../../util/images";
import {removeFromBucket,clearBuckets} from "../../util/images";
import VolumenTematico from "./VolumenTematico";
import EdicionLomo from "./EdicionLomo";
const CANT_FOTOS_VOLUMEN = 60;
class Volumenes extends Component{
    constructor(props){
        super(props);
        let porToken = this.props.token !== null && typeof this.props.token !=="undefined";
        let dataAut = porToken?{token:this.props.token}:{uuid: this.props.uuid}
        let that = this;
        this.state = {
            vols:[],
            volumenSeleccionado: null,
            seleccionandoPortada:false,
        }
        reqObtenerVolumenes(dataAut,this.props.proyectoSeleccionado).then(resp=>{
            
             that.setState({vols: resp.data.volumenes});
        }).catch(err=>{
            console.log("ERROR AL OBTENER VOLUMENES");
            console.log(err);
        })
    }

    cambiarPortada = (vol)=>{
        
        this.setState({seleccionandoPortada:true,volumenSeleccionado:vol});
    }
    componentDidMount = ()=>{
        let dataAut = getDataAut(this.props.token, this.props.uuid);
        console.log("COMPONENT DID MOUNT VOLUMENES");

        const imgs = document.querySelectorAll('div[data-src]');
        console.log(imgs);
        imgs.forEach(img=>{
            Observer.observe(img);
        });
        
        this.props.dispatch(cargarConfiguracion(dataAut, this.props.proyectoSeleccionado, ()=>{}));
    }
    componentDidUpdate = (prevProps,prevState)=>{
        console.log("COMPONENT DID UPDATE");

        const imgs = document.querySelectorAll('div[data-src]');
        console.log(imgs);
        imgs.forEach(img=>{
            Observer.observe(img);
        });
        if(!this.state.seleccionandoPortada && prevState.seleccionandoPortada){  
            this.props.dispatch({type:"CARGANDO_FOTOS"});          
            let that = this;
            let porToken = this.props.token !== null && typeof this.props.token !=="undefined";
            let dataAut = porToken?{token:this.props.token}:{uuid: this.props.uuid};
            reqObtenerVolumenes(dataAut,this.props.proyectoSeleccionado).then(resp=>{
                
                 that.setState({vols: resp.data.volumenes});
                 this.props.dispatch({type:"CARGADO_FOTOS"});
            }).catch(err=>{
                this.props.dispatch({type:"CARGADO_FOTOS"});
                console.log("ERROR AL OBTENER VOLUMENES");
                console.log(err);
            })  
        }
    }
    volverAVolumenes = ()=>{
        this.setState({seleccionandoPortada:false});
    }
    asignarPortada = async (ev,volNum,volId)=>{
        console.log("ASIGNANDO PORTAHDA");
        console.log(ev.target.files);
        let archivo = ev.target.files[0];
        if(esDefinido(archivo)){
            this.props.dispatch({type:"CARGANDO_FOTOS"});
            console.log("HABEMUS");
            await extraerExif([archivo]);
            let reorientada = await reorientar(archivo);
            let img = await obtenerImg(reorientada);
            img.name = archivo.path;
            //img.name = file.path;
            procesar(img).then(resp=>{
                let props = this.props;
                let proy = props.proyectoSeleccionado;
                let dataAut = getDataAut(this.props.token,this.props.uuid);
                resp.foto.name = archivo.name;
                console.log("SE PROCESO PORTADA");
                console.log(resp);

                reqSeleccionarPortada(dataAut,proy,volNum,resp.foto).then(resp=>{
                    console.log("SE HA SELECCIONADO PORTADA");
                    clearBuckets(["portadasVol","portadasProy"]);
                    this.props.dispatch({type:"CARGADO_FOTOS"});
                    this.portadaSeleccionada();
                    
                }).catch(err=>{
                    console.log("ERROR ENDPOINT SELECCIONAR PORTADA");
                    console.log(err);
                    this.props.dispatch({type:"ERROR_FOTO", valor:"SELECCION_PORTADA"});
                    this.props.dispatch({type:"CARGADO_FOTOS"});
                })
            }).catch(err=>{

            })
            //subir
        }
    }
    irSeleccionarForroTematico = (volId)=>{
        this.props.dispatch({type:"SELECCIONAR_VOLUMEN",volId: volId})
        this.props.history.push("/seleccionForroTematico");
    }
    irSeleccionarPortadaNormal = (volNum)=>{
        this.props.dispatch({type:"SELECCIONAR_NUM_VOLUMEN",volNum:volNum})
        this.props.history.push("/seleccionPortada");
    }
    
    listarVolumenes = ()=>{        
        console.log(this.state.vols)
        
        return this.state.vols.map((vol,index)=>{
            let completo = vol.cant_fotos === CANT_FOTOS_VOLUMEN;
            return  <div className = "contenedorVolumen" key = {`vol${index}`}>
                    <div className="contenedorInfoVolumenWrapper">
                        <div className="contenedorInfoVolumen">
                            <div  className="vol-numero info-vol">Volumen {vol.numero}</div>
                            <div className = {"info-vol " + (completo?"":"incompleto")}>
                            {`${vol.cant_fotos}/${CANT_FOTOS_VOLUMEN} fotos`}
                            </div>
                        </div>
                        {this.props.hasTematico?
                        null:                        
                        <EdicionLomo textoLomo = {vol.texto_inferior_lomo} volId = {vol.id}/>
                        }
                    </div>
                        {this.props.hasTematico?
                        <VolumenTematico vol = {vol} irSeleccionarForroTematico = {this.irSeleccionarForroTematico}/>
                        :
                        <div className = "contImagenVolumen">
                            <img src = {contraportada}/>
                            <img src = {lomo}/>

                            <label onClick = {()=>this.irSeleccionarPortadaNormal(vol.numero)}>
                                <img src ={portada} alt ="portada"/>
                                <span className = "portadaVolumen" 
                                    categoria = "portadasVol"
                                    identificador = {vol.id}    
                                >
                                {vol.url_portada === ""?
                                    <span>Elegir<br/>Portada </span>
                                :
                                    <img src = {vol.url_portada}/>
                                }
                                </span>
                                
                            </label>
                        </div>
                        }
                        
                    </div>
        });
    }
    toggleTematicos = ()=>{
        let dataAut = getDataAut(this.props.token, this.props.uuid  );
    
        this.props.dispatch(togglearPortadaTematica(dataAut,this.props.proyectoSeleccionado,this.props.hasTematico));
        
    }
    portadaSeleccionada = ()=>{
        this.setState({seleccionandoPortada:false});
        this.reload();
    }
    reload  = ()=>{
        let dataAut = getDataAut(this.props.token, this.props.uuid);
        let that = this;
        reqObtenerVolumenes(dataAut,this.props.proyectoSeleccionado).then(resp=>{
            console.log("VOLUMENES");
            console.log(resp.data);
             that.setState({vols: resp.data.volumenes});
        }).catch(err=>{
            console.log("ERROR AL OBTENER VOLUMENES");
            console.log(err);
        })
    }
    render(){
        return <div className="portadas">{
            this.state.seleccionandoPortada?                            
                <SeleccionPortada 
                    proyectoSeleccionado={this.props.proyectoSeleccionado}
                    volumenSeleccionado = {this.state.volumenSeleccionado}
                    token = {this.props.token}
                    uuid = {this.props.uuid}
                    dispatch ={this.props.dispatch}
                    portadaSeleccionada = {this.portadaSeleccionada}
                    volverAVolumenes = {this.volverAVolumenes}
                    />                
            :   <div className="mt-3"> 
               

                        <label className="cl-switch">
                            <span className="label">Portadas Prediseñadas</span>
                            <input type = "checkbox" checked = {this.props.hasTematico} onChange = {this.toggleTematicos}/>
                            <span className="switcher"></span>
                        </label>
                
                <div className="portadas-inner">
                
                {this.listarVolumenes()}
                </div>
                
                <a className='btn btn-primary btn-general' onClick = {(ev)=>this.props.agregarCarrito(this.state.vols)}>AGREGAR AL CARRITO</a>
                </div>
        }</div>
    }
}

function procesar(img){
    return new Promise(async (resolve,reject)=>{
        try{
            let cropped = await cropear(img);
            let thumbnail = await hacerThumbnail(cropped);
            resolve({foto:cropped, thumbnail:thumbnail});
        }
        catch(err){
            reject(err);
        }
    })
}

const mapStateToProps = (state)=>{
    return {
        token: state.clientes.token,
        uuid: state.clientes.uuid,
        proyectoSeleccionado: state.proyectos.proyectoSeleccionado,
        hasTematico: state.proyectos.hasTematico
    }
}
export default connect(mapStateToProps)(Volumenes);
