import React,{Component} from "react";
import Alert from "react-bootstrap/Alert";
import AlertIcon from "../../images/icon-warning.png";

 
class PreviewImg extends Component{
    render(){
        return <div><div className = "previewImagen" style={{
  backgroundImage: 'url(' + this.props.src + ')',}} >
        {this.props.alertar?
            <img className="icon-alert" src={AlertIcon}/>
        :null}
        </div></div>
    }
}

export default PreviewImg;