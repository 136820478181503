import React, {Component} from "react";
import Navegacion from "./Navegacion";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {registrarCliente} from "../util/asyncCliente";
import {connect} from "react-redux";
import {getDataAut, esDefinido} from "../util/general";
import ModalesError from "./ModalesError";
import {ERROR_REGISTRAR_CLIENTE} from "../util/errores";
import {Link} from "react-router-dom";
class Registrar extends Component{
    constructor(props){
        super(props);
        this.state = {
            nombre:"",
            email:"",
            password:"",
            errores :{}
        }
    }
    handleNombre = (ev)=>{
        this.setState({nombre:ev.target.value})
    }
    handleEmail = (ev)=>{
        this.setState({email: ev.target.value})
    }
    handlePassword = (ev)=>{
        this.setState({password:ev.target.value})
    }
    hayErrores = (errores)=>{
        return !(Object.keys(errores).reduce((acc,el)=>{return acc &errores[el].length === 0},true))
    }
    registrar = ()=>{
        let errores = this.validar();
        if(this.hayErrores(errores)){

            this.setState({errores:errores});
            return;
        }
        let dataAut = {uuid: this.props.uuid};
        let state = this.state;

        if(!esDefinido( this.props.uuid)){
            dataAut = {uuid:"empty-uuid"}
        }
        this.props.dispatch(registrarCliente(dataAut,state.nombre,state.email,state.password,
            this.props.history, this.props.preRegistro));
    }
    validar = (ev)=>{
        let campos = ["nombre","email","password"];
        let textos = ["Nombre", "Correo electrónico","Contraseña"]
        let errores = {};
        for(let i = 0;i<campos.length;i++){
            
            if(this.state[campos[i]]===""){
                errores[campos[i]] = textos[i];
            }
        }
        return errores;
    }
    erroresValidacion = ()=>{
        return this.state.errores.map((error,index)=>{
            return(<Alert variant = "danger"
                key = {index}>El campo {error} está vacío</Alert>)
        })
    }
    erroresValidacionPara = (campo)=>{
        
        if(esDefinido(this.state.errores[campo])){
            return (<Alert variant = "danger">
            El campo {this.state.errores[campo]} está vacío</Alert>)
        }
    }
    obtenerModales = ()=>{
        let modales = [];
        let that = this;
        
        
        modales.push(ERROR_REGISTRAR_CLIENTE(this.props.errores === "REGISTRAR_CLIENTE",
            ()=>{that.props.dispatch({type:"LIMPIAR_ERRORES_CLIENTE"})
        },this.props.detalleError));
        return modales;
    }
    atras = ()=>{
        this.props.history.goBack();
    }
    render(){
 
        return(
            <div id = "inner-content-wrapper" className = {this.props.navegable?"d-flex flex-column":" d-flex flex-column noNav"}>
                
                {this.props.navegable?
                    <Navegacion/>
                :
                    null
                }
                    <Navegacion/>

                <div className="title-bar">
                    <span className="back-icon" onClick = {this.atras}> </span> 
                    NUEVO USUARIO 
                </div>
                <ModalesError modales={this.obtenerModales()}/>
 
                <div className=" inner-content registro d-flex flex-column flex-fill justify-content-center align-items-center">
                    <div className="form-wrapper">
                        <div className="form-group"> 
                            <label htmlFor = "nombre">NOMBRE</label>
                            <input className="form-control" type = "text" id = "nombre"
                                value = {this.state.nombre}
                                onChange = {this.handleNombre}/>      
                            {this.erroresValidacionPara("nombre")}          
                        </div>
                        <div className="form-group"> 
                            <label htmlFor = "email">CORREO ELECTRÓNICO</label>
                            <input className="form-control" type = "text" id = "email"
                                value = {this.state.email}
                                onChange = {this.handleEmail}/> 
                        {this.erroresValidacionPara("email")}
                        </div>
                        <div className="form-group"> 
                            <label htmlFor = "password">CONTRASEÑA</label>
                            <input className="form-control" type = "password" id = "password"
                            value = {this.state.password}
                            onChange = {this.handlePassword}/>                
                        </div>
                        {this.erroresValidacionPara("password")}
                             

     
                       
                        <Button className='btn btn-primary btn-big btn-block' onClick = {this.registrar}>CONTINUAR</Button>
                         <div className="landing-terms">
                            Al ingresar aceptas nuestros  <br/>
                            <Link className="link-item"  to = {this.props.navegable?"/terminos":"/landing/terminos"}>términos y condiciones</Link>  

                        </div>
                    </div>
                </div>
        </div>)
    }
}
const mapStateToProps = (state)=>{
    return {
        token: state.clientes.token,
        uuid: state.clientes.uuid,
        errores:state.clientes.errores,
        preRegistro: state.navegacion.preRegistro,
        detalleError:state.clientes.detalleError
    }
}
export default connect(mapStateToProps)(Registrar);