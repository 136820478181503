import {reqCargarForrosTematicos,reqAsignarForroTematico} from "./forrosTematicos";

export function cargarForrosTematicos(){
    return function(dispatch,getState){
        dispatch({type:"CARGANDO"})
        reqCargarForrosTematicos().then(resp=>{
            dispatch({type:"CARGAR_FORROS_TEMATICOS", forros: resp.data.forros});

        }).catch(err=>{
            dispatch({type:"ERROR_FORROS_TEMATICOS",valor:"CARGAR_FORROS_TEMATICOS"});
            
        }).finally(()=>{
            dispatch({type:"CARGADO"});
        })
    }
}

export function asignarForroTematico(dataAut,proyId,forroId,cb){
    return function(dispatch,getState){
        dispatch({type:"CARGANDO"})
        reqAsignarForroTematico(dataAut, proyId, forroId).then(resp=>{
            cb();
        }).catch(err=>{
            dispatch({type:"ERROR_FORROS_TEMATICOS",valor:"ASIGNAR_FORRO_TEMATICO"});          
        }).finally(()=>{
            dispatch({type:"CARGADO"});
        })
    }
}