export const bucket = {
portadasProy:[],
fotos:[],
portadasVol:[],
portadasCarrito:[]
}

export const codigoErrorOrigen = {
    dispositivo:0,
    googlePhotos:1
}
export const codigoErrorFase = {
    setup:0,
    procesamiento:1,
    subida:2,
    registro:3
}
export const codigoErrorTipo = {
    desconocido: 0,
    red:1,    
    sesionApp:2,
    conexionApi:3,
    autenticacionApi:4,
    autorizacionApi:5,
    descargaFotos:6,
    upload:7,
    backend:8,
    descargaAlbumes:9
}
export function generarCodigoError (origen,fase,tipo){
    return `${codigoErrorOrigen[origen]}-${codigoErrorFase[fase]}-${codigoErrorTipo[tipo]}`
}