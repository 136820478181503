import React,{Component} from "react";
import {connect} from "react-redux";
import {esDefinido} from "../util/general";
import Carousel from "react-bootstrap/Carousel";
import Observer from "../util/imageObserver";
import { reqGuardarCaption, reqEliminarFoto } from "../util/fotos";
import { ERROR_FOTO_INDEFINIDA, ERROR_ELIMINAR_FOTO,ERROR_EDITAR_CAPTION } from "../util/errores";
import ModalesError from "./ModalesError";
import {getDataAut} from "../util/general";
import iconDelete from "../images/design_icon_delete.svg";
import Modal from "react-bootstrap/Modal";
import overlay from "../images/overlay-caption.png";
import ModalSpinner from "./modales/ModalSpinner";
import Alert from "react-bootstrap/Alert";

var overlayStyle = {
    backgroundImage: `url(${overlay})`
};

class EditarCaption extends Component{
    constructor(props){
        super(props);        
        if(!esDefinido(this.props.fotoSeleccionada) || !esDefinido(this.props.proyectoSeleccionado))
            this.props.dispatch({type:"ERROR_FOTO",valor:"FOTO_INDEF"});
        let fotos = this.props.volumenes.sort((a,b)=>a.orden-b.orden).flat();
      
        fotos  = fotos.map(a => Object.assign({}, a));
        let foto = fotos.find(foto=>{            
            return this.props.fotoSeleccionada === foto.id
        });
        this.state = {        
            fotos:fotos,
            fotoSeleccionada:foto,
            direction:null,
            editando:false,
            guardado:false,
            mostrarExitoGuardar:false     
        }        
    }
    dismissModal = ()=>{    
        this.props.dispatch({type:"VISITADO_OVERLAY",overlay:"visitadoEditarCaption"});
    }
    componentDidMount(){
        const imgs = document.querySelectorAll('div[data-src]');
        imgs.forEach(img=>{            
            Observer.observe(img);
        })
    }
    componentDidUpdate(prevProps,prevState){
        if(this.state.fotos.length !== prevState.fotos.length){
            const imgs = document.querySelectorAll('div[data-src]');
            imgs.forEach(img=>{
                Observer.observe(img);
            })
        }
    }
    onSelect = async (index,dir)=>{

    }
    handleSelect= async (selectedIndex,direccion) =>{
        //this.props.dispatch({type:"CARGANDO_FOTOS"});
        let foto = this.state.fotoSeleccionada;
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        //await this.guardarCaption(dataAut,foto.id,foto.caption);  
        this.setState({direccion:direccion});     
        this.setState({
          fotoSeleccionada:this.state.fotos[selectedIndex],
          editando:false,
          mostrarExitoGuardar:false
        });
      }
    carousel= ()=>{
        
        let x = this.state.fotos.map(foto=>{
            return <Carousel.Item key ={foto.id}>
                <div 
                    data-src = {foto.url} 
                    categoria = "fotos"
                    identificador = {foto.id}
                     >                
                </div>                
                <Carousel.Caption>                   
                                    
                </Carousel.Caption>
            </Carousel.Item>
        });
        return <Carousel 
            direction = {this.state.direccion}
            controls = {false}
            activeIndex = {this.state.fotos.findIndex(foto=>foto.id===this.state.fotoSeleccionada.id)}
            fade = {true}
            interval ={null}
            wrap = {false}
            >
            {x}
        </Carousel>
    }
    cambiarCaption = (ev)=>{
        let fotos = this.state.fotos.map(a=>Object.assign({},a));        
        let foto = fotos.find(el=>el.id === this.state.fotoSeleccionada.id);
        //let foto = fotos[this.state.fotoSeleccionada.orden-1];
        foto.caption = [...ev.target.value.normalize()].slice(0,100).join("");
        this.setState({fotos:fotos,fotoSeleccionada:foto,editando:true,mostrarExitoGuardar:false,guardado:false});
    }
    cerrar = async ()=>{
        //let foto = this.state.fotoSeleccionada;
        //let dataAut = getDataAut(this.props.token,this.props.uuid);
        //await this.guardarCaption(dataAut,foto.id,foto.caption);
        this.props.history.push("/proyectos/editar");
    }
    guardar =async ()=>{
        if(!this.state.editando){
            return;
        }
        let foto = this.state.fotoSeleccionada;
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        
            await this.guardarCaption(dataAut,foto.id,foto.caption);
            
        
        
    } 
    guardarCaptions(dataAut){
        let fotos = this.state.fotos;
        this.props.dispatch({type:"CARGANDO_FOTOS"});
        let promesas = [];
        for(let x = 0;x<this.state.fotos.length;x++){
            promesas.push(reqGuardarCaption(dataAut,fotos[x].id,fotos[x].caption));
        }
        Promise.all(promesas).then(()=>{
            this.props.dispatch({type:"CARGADO_FOTOS"});
            this.setState({mostrarExitoGuardar:true})
        }).catch(err=>{
            this.props.dispatch({type:"ERROR_FOTO",valor: "ERROR_CAPTION"})
            //let fotos =this.props.volumenes.sort((a,b)=>a.orden-b.orden).flat();
            //fotos = fotos.map(el=>Object.assign({},el));
            this.props.dispatch({type:"CARGADO_FOTOS"});
            //this.setState({fotos:fotos});
        });
        
    }
     guardarCaption(dataAut,id,caption){

        this.props.dispatch({type:"CARGANDO_FOTOS"});
        reqGuardarCaption(dataAut,id,caption).then(resp=>{
            this.props.dispatch({type:"CARGADO_FOTOS"});
            let fotosOrig = this.props.volumenes.sort((a,b)=>a.orden-b.orden).flat();
            let fotoOrig = fotosOrig.find(foto=>{            
                return this.props.fotoSeleccionada === foto.id
            });
            fotoOrig.caption = caption;
            this.setState({guardado:true,editando:false,mostrarExitoGuardar:true})
        }).catch((err)=>{            
            this.props.dispatch({type:"ERROR_FOTO",valor: "ERROR_CAPTION"})
            let fotos =this.props.volumenes.sort((a,b)=>a.orden-b.orden).flat();
             fotos = fotos.map(el=>Object.assign({},el));
             this.props.dispatch({type:"CARGADO_FOTOS"});
            this.setState({fotos:fotos});
        });
    }
    eliminarFoto = ()=>{
        let aEliminar = this.state.fotoSeleccionada;
        this.props.dispatch({type:"CARGANDO_FOTOS"});
        
        let index = this.state.fotos.findIndex(el=>el.id === aEliminar.id);
        let fotos = this.state.fotos.filter(el=>el.id!==aEliminar.id).map(el=>{
            let foto = Object.assign({},el);
            if(foto.orden>aEliminar.orden){
                foto.orden = foto.orden - 1;
            }
            return foto;
        });
        let fotoSeleccionada = index>(fotos.length-1)?fotos[fotos.length-1]:fotos[index];
        this.setState({fotoSeleccionada,fotos});    
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        reqEliminarFoto(dataAut,aEliminar.id).then(resp=>{
            
            this.props.dispatch({type:"CARGADO_FOTOS"});
        }).catch(err=>{
            this.props.dispatch({type:"ERROR_FOTO", valor: "ELIMINAR"});
            let fotos =this.props.volumenes.sort((a,b)=>a.orden-b.orden).flat();            
            fotos = fotos.map(el=>Object.assign({},el));
            this.setState({fotos:fotos,fotoSeleccionada:aEliminar});
            this.props.dispatch({type:"CARGADO_FOTOS"});
        });
    }
    obtenerErrores = ()=>{
        let errores = [];
        errores.push(ERROR_FOTO_INDEFINIDA(this.props.errores === "FOTO_INDEF",
        (ev)=>{
            this.props.dispatch({type:"LIMPIAR_ERRORES_FOTO"});
            this.props.history.push("/proyectos");
          }));
        errores.push(ERROR_ELIMINAR_FOTO(this.props.errores === "ELIMINAR",
        (ev)=>{
            this.props.dispatch({type:"LIMPIAR_ERRORES_FOTO"});                  
          }));
        errores.push(ERROR_EDITAR_CAPTION(this.props.errores === "ERROR_CAPTION",
        (ev)=>{
            this.props.dispatch({type:"LIMPIAR_ERRORES_FOTO"});                  
          }));
        return errores;
    }
    anteriorFoto = ()=>{
        
            let fotosOrig = this.props.volumenes.sort((a,b)=>a.orden-b.orden).flat();
            let fotoOrig = fotosOrig.find(foto=>{            
                return this.props.fotoSeleccionada === foto.id
            });
        let that = this;
        let ordenFlat = this.state.fotos.findIndex(el=> el.id === this.state.fotoSeleccionada.id)+1;

        if(ordenFlat !== 1){
            let index = ordenFlat -2; /*this.state.fotos.findIndex(actual =>{
                return actual.orden === this.state.fotoSeleccionada.orden - 1
            })*/

            if(this.state.editando && !this.state.guardado){

                this.setState({fotoSeleccionada:fotoOrig,fotos:fotosOrig},()=>{
                    that.handleSelect(index,"prev");
                });
            }else{
                that.handleSelect(index,"next");
            }   
            /*
            let foto = this.state.fotos.find(fotoActual=>{            
                return fotoActual.orden === this.state.fotoSeleccionada.orden - 1
            });
            
            
            this.setState({fotoSeleccionada:foto});*/
        }
        
    }
    siguienteFoto = ()=>{
        let fotosOrig = this.props.volumenes.sort((a,b)=>a.orden-b.orden).flat();
        let fotoOrig = fotosOrig.find(foto=>{            
            return this.props.fotoSeleccionada === foto.id
        });
        let that = this;
        let ordenFlat = this.state.fotos.findIndex(el=> el.id === this.state.fotoSeleccionada.id)+1;
        //SI FOTO NO ES LA ULTIMA
        if(ordenFlat !== this.state.fotos.length){
            let index = ordenFlat; /*this.state.fotos.findIndex(actual =>{
                return actual.orden === this.state.fotoSeleccionada.orden + 1
            })*/
            if(this.state.editando && !this.state.guardado){
            this.setState({fotoSeleccionada:fotoOrig,fotos:fotosOrig},()=>{
                that.handleSelect(index,"next");
            });
            }
            else{
                that.handleSelect(index,"prev");
            } 
            /*let foto = this.state.fotos.find(fotoActual=>{            
                return fotoActual.orden === this.state.fotoSeleccionada.orden + 1
            })
            console.log(foto);
            this.setState({fotoSeleccionada:foto});*/
        }
        
    }
    render(){
        return <div>        
                <ModalSpinner show = {this.props.cargando}/>
                <Modal show = {!this.props.visitadoEditarCaption && esDefinido(this.props.uuid)} 
                    onHide = {this.dismissModal}
                className=" modal-overlay" style={overlayStyle}>
                
                </Modal>

                  
                <ModalesError modales = {this.obtenerErrores()}/>
                {esDefinido(this.state.fotoSeleccionada)?
                    <div id = "inner-content-wrapper" className="d-flex flex-column">
                        <div className="title-bar">
                            <span className="back-icon" onClick = {this.cerrar}> </span>
                            DETALLE DE FOTO
                        </div>
                         
                        
                        <div className="inner-content detalle">
                           
                            <div className="row"> 
                                <div className = "contCarrusel col-md-6">
                                     <div className="fotos-contador">
                                        Foto {this.state.fotos.findIndex(el=>this.state.fotoSeleccionada.id === el.id)+1} de {this.state.fotos.length}
                                    </div>
                                    <div className="carousel-wrapper">
                                    {this.carousel()}
                                    </div>
                                    
                                    <a className = "eliminarProyecto"  onClick = {this.eliminarFoto}>
                                        <img src = {iconDelete} alt = "Eliminar" width="38"/>
                                        <span className=""> ELIMINAR FOTO</span>
                                    </a>
                                </div>
                                
                                <div className="meta-caption-cont col-md-6">
                                    <div className="foto-fecha">{new Date(this.state.fotoSeleccionada.fecha*1000).toLocaleDateString("es-MX",{  year: 'numeric', month: 'long', day: 'numeric' })}</div>
                                    
                                    {this.state.fotoSeleccionada.lugar?<div className='foto-ubicacion'>{this.state.fotoSeleccionada.lugar}</div>:""}

                                    <div className="form-group">
                                    <textarea className="form-control" value = {this.state.fotoSeleccionada.caption} onChange = {this.cambiarCaption}>
                                    </textarea>
                                    <Alert variant = "success" show = {this.state.mostrarExitoGuardar}>
                                        Caption guardado correctamente</Alert>                                    
                                    <span className = {`editarCaption ${this.state.editando?"btn btn-primary btn-block mt-3 ":"btn btn-primary btn-block mt-3 disabled "}`}  onClick = {this.guardar}>
                                        GUARDAR CAPTION
                                    </span>
                                    </div>
                                </div>
                            </div>
                            {
                            this.state.fotos.findIndex(el=> el.id === this.state.fotoSeleccionada.id)+1 !== 1?
                            <a className="carousel-control-prev" onClick = {this.anteriorFoto} role="button" ><span aria-hidden="true" className="carousel-control-prev-icon" ></span><span  className="sr-only">Previous</span></a>
                            :null}
                            {
                             this.state.fotos.findIndex(el=> el.id === this.state.fotoSeleccionada.id)+1!==this.state.fotos.length?
                            <a className="carousel-control-next" onClick = {this.siguienteFoto} role="button"><span aria-hidden="true" className="carousel-control-next-icon" ></span><span  className="sr-only">Next</span></a>:
                            null}
                         </div>
                    </div>
                :null}
            </div>
    }
}
const mapStateToProps = (state)=>{
    return {
        errores: state.fotos.errores,
        volumenes: state.proyectos.volumenes,
        proyectoSeleccionado: state.proyectos.proyectoSeleccionado,
        fotoSeleccionada: state.fotos.fotoSeleccionada,
        uuid: state.clientes.uuid,
        token: state.clientes.token,
        cargando: state.fotos.cargando,
        visitadoEditarCaption:state.clientes.visitadoEditarCaption
    }
}

export default connect(mapStateToProps)(EditarCaption);