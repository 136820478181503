const estadoInicial = {
    mostrarSidebar:true,
    preIniciarSesion:"/",
    preRegistro: "/ß"}
const navegacionReducer = (state = estadoInicial,action)=>{
    let res = {};
    switch(action.type){       
        case "TOGGLE_SIDEBAR": 
            res = Object.assign({},state,{mostrarSidebar:!state.mostrarSidebar});
            return res;
        case "PRE_INICIAR_SESION":
            res = Object.assign({},state,{preIniciarSesion: action.previo});
            return res;
        case "PRE_REGISTRO":
            res = Object.assign({},state,{preRegistro: action.previo});
            return res;
        default:
        return state;

    }
}
export default navegacionReducer;