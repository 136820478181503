import config from "./config";
import {request} from "./axiosReqs"

export function reqListarProyectos(dataAut,CBExito,CBError){   
    let url = `${config.urlBackend}/proyectos/`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {
        method:"GET",
        headers: headers,    
            url
        };
    request(options).then(resp=>{
        CBExito(resp);
    }).catch(err=>{
        CBError(err );
    })
    
}
export async function reqCrearProyecto(dataAut,tipoPasta){
    let url = `${config.urlBackend}/proyectos/`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {
        method:"POST",
        headers: headers,    
        data:{tipo_pasta:tipoPasta},
            url
        };
    let x = await request(options);
    return x;
}
export function reqRegistrarFotos(dataAut,meta,proyecto){
    let url = `${config.urlBackend}/proyectos/${proyecto}/registrar_fotos`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {method:"POST",
        headers: headers,    
        data:{metadatos:meta},
        url
    };
    return request(options);
}
export function reqGuardarConfiguracion(dataAut,proyId,configProy){
    let url = `${config.urlBackend}/proyectos/${proyId}/editar`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {method:"POST",
        headers: headers,    
        data:configProy,
        url
    };
    return request(options);
}

export function reqCargarConfiguracion(dataAut,proyId){
    let url = `${config.urlBackend}/proyectos/${proyId}/configuracion`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {method:"GET",
        headers: headers,
        url
    };
    return request(options);
}

export function reqPrevisualizarProyecto(dataAut,proyId){
    let url = `${config.urlBackend}/proyectos/${proyId}/fotos_aws`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {method:"GET",
        headers: headers,
        url
    };
    return request(options);
}

export function reqEliminarProyecto(dataAut,proyId){
    let url = `${config.urlBackend}/proyectos/${proyId}`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {method:"DELETE",
        headers: headers,
        url
    };
    return request(options);
}

export function reqObtenerVolumenes(dataAut,proyId){
    let url = `${config.urlBackend}/proyectos/${proyId}/volumenes`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {method:"GET",
        headers: headers,
        url
    };
    return request(options);
}

export function reqSeleccionarPortada(dataAut,proyId,numVol,img){
    let data = new FormData();
    data.append("foto",img,"foto.png");
    data.append("volumen",numVol);
    let url = `${config.urlBackend}/proyectos/${proyId}/portada`;
    const headers = Object.assign({},dataAut)
    const options = {method:"POST",
        headers: headers,
        data:data,
        url
    };
    return request(options);
}
export function reqAgregarCarrito(dataAut,proyId){
    let url = `${config.urlBackend}/carrito/proyecto/${proyId}`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {method:"POST",
        headers: headers,
        url
    };
    return request(options);
}

export function reqTogglearPortadaTematica(dataAut,proyId,actual){
    
    let url = `${config.urlBackend}/proyectos/${proyId}/configuracion_forro`;
    const headers = Object.assign({},dataAut)
    const options = {method:"POST",
        headers: headers,
        data:{has_tematico:!actual},
        url
    };
    return request(options);
}