import {gapi} from "./auth";
const pageSize = 50;
export function obtenerFotosLibreria(nextPageToken,albumId){
    if (albumId === "TODAS"){
        return gapi.client.request({
            "path":"https://photoslibrary.googleapis.com/v1/mediaItems",
            "params":{
                "pageToken":nextPageToken,
                "pageSize":pageSize}
        })
    }
    else{
        return gapi.client.request({
            "path":"https://photoslibrary.googleapis.com/v1/mediaItems:search",
            "method":"POST",
            "body":{
                "albumId":albumId,
                "pageToken": nextPageToken,
                "pageSize":pageSize
            }
        })
    }
}
export function obtenerAlbumesLibreria(nextPageToken){
    
    return gapi.client.request({
        "path":"https://photoslibrary.googleapis.com/v1/albums",
        "params":{"pageToken":nextPageToken,
        "pageSize":pageSize
    }
    })
}