
import config from "./config";
import {request} from "./axiosReqs";
export function reqCargarForrosTematicos(){
    
    let url = `${config.urlBackend}/forros_tematicos`;
    
    const options = {
        method:"GET",        
        url
    };
    return request(options);
}

export function reqAsignarForroTematico(dataAut,volId,forroId){
    
    let url = `${config.urlBackend}/volumenes/forro`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const data = {
        vol_id: volId,
        forro_id: forroId
    }
    const options = {
        headers,
        method:"POST",        
        data:data,
        url
    };
    return request(options);
}