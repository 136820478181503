import React,{Component} from "react";
import {connect} from "react-redux";
import SeleccionarPasta from "./SeleccionarPasta";
import SeleccionarFotos from "./SeleccionarFotos";
import Configurar from "./Configurar";
import Navegacion from "../Navegacion";
import {esDefinido} from "../../util/general";

const componentes = {
    PASTA:SeleccionarPasta,
    FOTOS:SeleccionarFotos,
    CONFIG:Configurar
}

class Editor extends Component{
    constructor(props){
        super(props);
        this.props.dispatch({type:"CREANDO_PROYECTO"})
        this.props.dispatch({type: "CREANDO"})
        this.state = { 
            componenteActual:componentes["PASTA"],
        }
    }
    componentWillUnmount(){
        console.log("UNMOUNTING COMPONENT");
        this.props.dispatch({type: "CREACION_TERMINADA"});
    }
    seleccionarPasta = (tipo)=>{
        let porToken = this.props.token !== null && typeof this.props.token !=="undefined";
        let dataAut = porToken?{token:this.props.token}:{uuid: this.props.uuid}
        this.props.dispatch({type:"SELECCIONAR_PASTA",tipoPasta:tipo});
        this.props.dispatch({type:"SELECCIONADA_PASTA"});
        
        //let copia = Object.assign({componenteActual:componentes["FOTOS"]},this.state);        
        //this.setState(copia);
    }
    irComponente = (siguiente)=>{
        let copia = Object.assign({componenteActual:componentes[siguiente]},this.state);
        this.setState(copia);
    }
    render(){
        let seleccion = "PASTA";
        //let seleccion = "FOTOS";
        if(this.props.seleccionadaPasta){
            seleccion = "FOTOS"           
        }
        if(this.props.subidasFotos){
            seleccion = "CONFIG"
        }
        let Comp = componentes[seleccion];
        return ( 
                <Comp seleccionarPasta = {this.seleccionarPasta} history={this.props.history}
                    irComponente = {this.irComponente} location = {this.props.location}/>
                   )
            
    }
}
const mapStateToProps = (state)=>{
    return {
        proyectoSeleccionado:state.proyectos.proyectoSeleccionado,
        volumenes: state.proyectos.volumenes,
        uuid: state.clientes.uuid,
        token: state.clientes.token,
        tipoPasta: state.proyectos.tipoPasta,
        seleccionadaPasta: state.crearProyecto.seleccionadaPasta,
        subidasFotos: state.crearProyecto.subidasFotos,
        configurado: state.crearProyecto.configurado
    }
}
export default connect(mapStateToProps)(Editor);