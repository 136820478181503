import {reqRegistrarCliente,reqRecuperarContrasena,reqCargarDatos, reqGuardarDatos} from "./clientes";
const nombres = {password:"Contraseña", email:"Correo Electrónico", nombre:"Nombre"};
export function registrarCliente(dataAut,nombre,email,password,history,preRegistro){
    return function(dispatch,getState){
        let data = {
            nombre:nombre,
            email:email,
            password:password
        }
        reqRegistrarCliente(dataAut,data).then(resp=>{
            //console.log("SE LOGRO REGISTRAR CLIENTE");
            //console.log(resp);
            if(preRegistro=== "/carrito/pago"){
                dispatch({type:"PAGANDO"});
            }
            dispatch({type:"INICIAR_SESION",token:resp.data.token});
            
            history.push(preRegistro);
        }).catch(err=>{
            let detalle = "";
            if(err.response){
            }
            if(err.response && err.response.status === 400){
                
                let errores = err.response.data.error;
                let arr = Object.keys(errores).map(categoria=>{
                    return errores[categoria].map(error=>{
                        if(error.trim() === "has already been taken"){
                            error =  "ya está en uso";
                        }
                        if(error.trim() === "is too short (minimum is 8 characters)"){
                            error =  "es demasiado corta(el mínimo es 8 caracteres)";
                        }
                        return `${nombres[categoria]}  ${error} `;
                    })
                }).flat();                    
                detalle = arr.join("\n");
            }
            
            dispatch({type:"ERRORES",errores:"REGISTRAR_CLIENTE",detalleError: detalle})
        })
    }
}
export function recuperarContrasena(email,history,to){
    return function(dispatch,getState){
        dispatch({type:"CARGANDO_CLIENTE"})
        reqRecuperarContrasena(email).then(resp=>{
            dispatch({type:"CARGADO_CLIENTE"});
            dispatch({type: "ENVIADO_EMAIL_RECUPERACION"});
        }).catch(err=>{

            dispatch({type:"CARGADO_CLIENTE"});
            if(err.response){
                if(err.response.status === 404){
                    dispatch({type:"ERRORES",errores:"CORREO_NO_ENCONTRADO"})
                    
                }
                else{
                    dispatch({type:"ERRORES",errores:"RECUPERAR_CONTRASENA"})
                }
            }
            else{
                dispatch({type:"ERRORES",errores:"RECUPERAR_CONTRASENA"})
            }
        })
    }
}
export function cargarDatos(dataAut){
    return function(dispatch,getState){
        dispatch({type:"CARGANDO_CLIENTE"});
        reqCargarDatos(dataAut).then(resp=>{            
            dispatch({type:"ACTUALIZAR_DATOS_CLIENTE",datos:resp.data})
        }).catch(err=>{
            dispatch({type:"ERRORES",errores:"CARGAR_DATOS"});
        }).finally(()=>{
            dispatch({type:"CARGADO_CLIENTE"});
        })
    }   
}

export function guardarDatos(dataAut,datos,CB,error){
    return function(dispatch,getState){
        dispatch({type:"CARGANDO_CLIENTE"});
        reqGuardarDatos(dataAut,datos).then(resp=>{            
            CB();
            dispatch({type:"ACTUALIZAR_DATOS_CLIENTE",datos:datos})
        }).catch(err=>{
            if(err.response && err.response.status === 412){
                error(err.response.data.error);
            }
            dispatch({type:"ERRORES",errores:"GUARDAR_DATOS"});
        }).finally(()=>{
            dispatch({type:"CARGADO_CLIENTE"});
        })
    }   
}