import React,{Component} from "react";
import iconDelete from "../../images/design_icon_delete.svg";

class ProyectoTematico extends Component{
    render(){
        return <div className = "proyecto-libro-tematico"
            style={{backgroundImage: `url("${this.props.src}")`}}>
        <a className="delete-btn" 
            onClick = {this.props.onClickDelete}>
            <img src = {iconDelete} alt = "Eliminar"/>
        </a>
        <div 
            
            categoria = "portadasProy"
            identificador = {this.props.id}
            className = "proyecto-libro-inner">
        </div>
        </div>
    }
}

export default ProyectoTematico;