import React, {Component} from "react";
import {handleClientLoad,signIn,signOut} from "../../../util/google_api/auth";
import {obtenerFotosLibreria,obtenerAlbumesLibreria} from "../../../util/google_api/photos";
import ModalSpinner from "../../modales/ModalSpinner";
import GridFotos from "./gridFotos";
import {esDefinido} from "../../../util/general";
import {subirFoto} from "../../../util/aws";
import config from "../../../util/config";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {request} from "../../../util/axiosReqs";
import ProgressBar from "react-bootstrap/ProgressBar";
import {connect} from "react-redux";
import Albumes from "./Albumes";
import {writeLog} from "../../../util/logs";
import { PAGINA_VACIA_GOOGLE_PHOTOS,ERROR_CARGAR_GOOGLE_PHOTOS,
    ERROR_SUBIR_GOOGLE_PHOTOS, ERROR_CARGAR_GOOGLE_ALBUMES,
    ERROR_INICIAR_GOOGLE_PHOTOS, ERROR_LOGIN_GOOGLE_PHOTOS} from "../../../util/errores";

import ModalesError from "../../ModalesError";
import ModalErrorCodigo from "../../modales/ModalErrorCodigo";
import { generarCodigoError } from "../../../util/const";
const todas = {
    id:"TODAS",
    nombre:"TODAS"
}
class GooglePhotos extends Component{
    constructor(props){
        super(props);
        this.state = {
            fase:"albumes",
            iniciadaSesion:false,
            conexionExitosa:false,
            cargadoApi:false,
            albumes:[todas],
            fotos:[],
            nextPageToken:null,
            nextPageTokenAlbums:null,
            cargandoFotos:false,
            subiendo:false,
            objetivo:0,
            subidas:0,
            albumSeleccionado:null
        }
    }
    Observer = new IntersectionObserver((entries)=>{
        let that = this;
        entries.forEach(entry=>{
            //console.log("PUEDO VER A ");
            //console.log(entry);
            //console.log(esDefinido(that.state.nextPageToken))
            //console.log(that.state.nextPageToken);
            //console.log(that.state);
            //console.log(entry.isIntersecting    )
            if( entry.isIntersecting){
                if(that.state.fase === "albumes"){
                    if(esDefinido(that.state.nextPageTokenAlbums)){
                        that.cargarAlbumes(that.state.nextPageTokenAlbums);
                    }
                }
                else{
                    if(esDefinido(that.state.nextPageToken )){
                        that.cargarFotos(that.state.nextPageToken);
                    }
                }
            }
        })
    });
    cargarMas = ()=>{
        this.cargarFotos(this.state.nextPageToken);
    }
    componentDidMount(){
        //console.log("GP DID MOUNT");
        //console.log(window.gapi);
        
        let gapi = window.gapi;
        
        handleClientLoad().then(signInStatus=> 
            {
                this.setState({
                    iniciadaSesion : signInStatus,
                    cargadoApi:true,
                    conexionExitosa:true
                });
                //console.log("CARGAR FOTOS");
                if(signInStatus){
                    this.cargarAlbumes();
                }
            }
            ).catch(err=>{
                this.setState({cargadoApi:true});
                this.props.dispatch({type:"ERROR_PROYECTO",valor:"ERROR_GOOGLE_PHOTOS",codigoError:err.message});
                let payload = {
                    error:JSON.stringify(err),
                    function: "app/proyectos/integraciones/GooglePhotos/componentDidMount",
                    browser:window.navigator.appVersion,
                    
                }
                
                writeLog(payload).then(resp=>console.log("SE HA CREADO LOG")).catch(err2=>{
                    console.log("no se pudo crear log")
                    console.log(err2);
                });
                //console.log("ERROR HANDLE CLIENT LOAD");
                //console.log(err.message);
            });
      }
      signIn = ()=>{
          //console.log("SIGNING IN");
          signIn().then(()=>{
              
            this.setState({iniciadaSesion:true});
            this.cargarAlbumes();
            })
          .catch(err=>{
              let msg = generarCodigoError("googlePhotos","setup","autenticacionApi");
              this.props.dispatch({type:"ERROR_PROYECTO",valor: "ERROR_GOOGLE_PHOTOS",codigoError: msg});
            //console.log(err);
            let payload = {
                error:JSON.stringify(err),
                function: "app/proyectos/integraciones/GooglePhotos/signIn",
                browser:window.navigator.appVersion,
                
            }
            
            writeLog(payload).then(resp=>console.log("SE HA CREADO LOG")).catch(err2=>{
                console.log("no se pudo crear log")
                console.log(err2);
            });
          });;
      }
      signOut = ()=>{
        signOut().then(()=>
            this.setState({iniciadaSesion:false,fotos:[],albumes:[todas],fase:"albumes"}))
        .catch(err=>{
            let payload = {
                error:JSON.stringify(err),
                function: "app/proyectos/integraciones/GooglePhotos/signOut",
                browser:window.navigator.appVersion,                
            }
            writeLog(payload).then(resp=>console.log("SE HA CREADO LOG")).catch(err2=>{
                console.log("no se pudo crear log")
                console.log(err2);
            });
            console.log(err);
            let msg = generarCodigoError("googlePhotos","setup","autenticacion");
            this.props.dispatch({type:"ERROR_PROYECTO",valor: "ERROR_GOOGLE_PHOTOS",codigoError: msg});
        });;
      }
    cargarAlbumes = (nextPageToken)=>{
        this.setState({cargandoFotos:true});
        //console.log("LISTAR FOTOS");
        //console.log(nextPageToken);
        obtenerAlbumesLibreria(nextPageToken).then( async resp=>{
            //console.log("RESPUESTA ALBUMES");
            //console.log(resp);
            
            let albumes = [];
            if(resp.result.albums)
                albumes =procesarAlbumes(resp.result.albums);   
            
            let copiaAlbumes = this.state.albumes.map(album=>{
                return Object.assign({},album);
            })
            let todas = copiaAlbumes.find(album=>album.id === "TODAS");

            let portadaTodas = null;  
            if(this.state.albumes.length > 1){
                portadaTodas = this.state.albumes[1].coverUrl;
            }else {
                if(albumes.length > 0){
                    portadaTodas = albumes[0].coverUrl;
                }
            }
            //todas.count = cuentaFotos;
            todas.coverUrl = portadaTodas;
            this.setState({
                albumes:copiaAlbumes.concat(albumes),
                nextPageTokenAlbums :resp.result.nextPageToken,
            },()=>{
                this.setState({cargandoFotos:false})
            });
            
        }).catch(err=>{
            this.setState({cargandoFotos:false});
            let msg = generarCodigoError("googlePhotos","setup","descargaAlbumes");
            this.props.dispatch({type:"ERROR_PROYECTO",valor:"ERROR_GOOGLE_PHOTOS",codigoError:msg});
            console.log("ERROR OBTENER ALBUMES;")
            console.log(err);
            let payload = {
                error:JSON.stringify(err),
                function: "app/proyectos/integraciones/GooglePhotos/cargarAlbumes",
                browser:window.navigator.appVersion,
                
            }
            writeLog(payload).then(resp=>console.log("SE HA CREADO LOG")).catch(err2=>{
                console.log("no se pudo crear log")
                console.log(err2);
            });
        })
    }
    cargarFotos = (nextPageToken)=>{
        
        this.setState({cargandoFotos:true});
        
        obtenerFotosLibreria(this.state.nextPageToken,this.state.albumSeleccionado).then(resp=>{
            
            //console.log(resp);
            let fotos =procesar(resp.result.mediaItems);
            if(fotos.length === 0){
                this.props.dispatch(
                    {type:"ERROR_PROYECTO",valor:"PAGINA_VACIA_GOOGLE_PHOTOS"})
            }
            this.setState({
                fotos:this.state.fotos.concat(fotos),
                nextPageToken:resp.result.nextPageToken,
                cargandoFotos:false

            });
            
        }).catch(err=>{
            this.setState({cargandoFotos:false});
            let msg = generarCodigoError("googlePhotos","setup","descargaFotos");
            this.props.dispatch({type:"ERROR_PROYECTO",valor:"ERROR_GOOGLE_PHOTOS",codigoError: msg});
            let payload = {
                error:JSON.stringify(err),
                function: "app/proyectos/integraciones/GooglePhotos/cargarFotos",
                browser:window.navigator.appVersion,
                
            }
            
            writeLog(payload).then(resp=>console.log("SE HA CREADO LOG")).catch(err2=>{
                console.log("no se pudo crear log")
                console.log(err2);
            });
            //console.log(err);
        })
    }
    componentDidUpdate(prevProps, prevState){
        let lazyLoader= document.querySelector("#lazyLoader");        
        if(esDefinido(lazyLoader)){
            this.Observer.observe(lazyLoader);
        }
    }
    seleccionMultiple = (start,end)=>{

        if(start>end){
            let temp = start;
            start = end;
            end = temp;
        }
        let fotos = this.state.fotos.map(foto=>Object.assign({},foto));
        for(let x = start; x<=end; x++){
            fotos[x].isSelected = true;    
        }
        
        this.setState({fotos:fotos});
    }
    toggleSelect=(index)=>{
        let fotos = this.state.fotos.map(foto=>Object.assign({},foto));
        fotos[index].isSelected = !this.state.fotos[index].isSelected;
        this.setState({fotos:fotos});
    }
    subir = ()=>{
        this.props.dispatch({type:"INICIAR_SUBIDA_GP"});
        let promesas = []
        let that = this;
        let seleccionadas = this.state.fotos.filter(foto=>foto.isSelected);
        
        //console.log("SUBIENDO");
        //console.log(seleccionadas);
        seleccionadas.forEach(foto=>{
            foto["nombre"] = Date.now().toString() + "_" + getRandomInt().toString() +".jpeg"; 
        })
        //console.log(seleccionadas);
        this.setState({subiendo:true,subidas:0,objetivo:seleccionadas.length});
        
        seleccionadas.forEach(foto=>{
            //console.log("FOR EACH SUBIR");
            let options = {
                method:"POST",
                data:{
                    baseUrl: foto.src,
                    nombre: foto.nombre
                },
                url: config.googlePhotos.subirFotosFunctionUrl
            }
            //console.log(options.data);
            let start = new Date().getTime();
            promesas.push(new Promise((res,rej)=>{
                
                axios(options).then(resp=>{
                //console.log(new Date().getTime()-start);
                this.setState({subidas: this.state.subidas + 1});
                res(res);
            }).catch(err=>{
                rej(new Error(generarCodigoError("googlePhotos","subida","upload")));
                let payload = {
                    error:JSON.stringify(err),
                    function: "app/proyectos/integraciones/GooglePhotos/subir",
                    browser:window.navigator.appVersion,
                    
                }
                
                writeLog(payload).then(resp=>console.log("SE HA CREADO LOG")).catch(err2=>{
                    console.log("no se pudo crear log")
                    console.log(err2);
                });
            })}));            
        })
        //console.log("REGISTRADAS PROMESAS");
        //console.log(seleccionadas);
        Promise.all(promesas).then(resp=>{

            setTimeout(function(){
                that.props.registrar(seleccionadas);
            },1);
            
        }).catch(err=>{
            //console.log(err);
            let payload = {
                error:JSON.stringify(err),
                function: "app/proyectos/integraciones/GooglePhotos/subir",
                browser:window.navigator.appVersion,
                
            }
            
            writeLog(payload).then(resp=>console.log("SE HA CREADO LOG")).catch(err2=>{
                console.log("no se pudo crear log")
                console.log(err2);
            });
            this.props.dispatch({type:"TERMINAR_SUBIDA_GP"});
            this.props.dispatch(
                {type:"ERROR_PROYECTO",valor:"ERROR_GOOGLE_PHOTOS",codigoError:err.message});
        }).finally(()=>{
            this.setState({subiendo:false});
            
        })
        
    }
    irAlbum = (albumId)=>{

        this.setState({
            fase:"fotos",
            albumSeleccionado:albumId,
            nextPageToken:null
        },()=>{this.cargarFotos();});
        
    }
    volverAlbumes =  ()=>{
        this.setState({
            fase:"albumes",
            fotos:[],
            nextPageToken:null,            
        })
    }
     modalesError = ()=>{
        let modales = [];
        
        modales.push(PAGINA_VACIA_GOOGLE_PHOTOS(
            this.props.errores === "PAGINA_VACIA_GOOGLE_PHOTOS",
            ()=>this.props.dispatch({type:"LIMPIAR_ERRORES_PROYECTO"})));
        
        return modales;
    }
    render(){
        //console.log("CUENTA SELEC");
        //console.log(this.state.fotos);
        let cuentaSeleccionadas = this.state.fotos.filter(foto=>foto.isSelected).length;        
        let porcentajeSubidas = this.props.subiendo?Math.round((this.state.subidas/this.state.objetivo)*100):0;
        return  <div className="googleWrapper ">
                        <ModalesError modales = {this.modalesError()}/>
                        <ModalErrorCodigo 
                            show = {"ERROR_GOOGLE_PHOTOS" === this.props.errores}
                            titulo = "ERROR GOOGLE PHOTOS"
                            mensaje = "Ha ocurrido un error, intenta mas tarde"
                            codigo = {this.props.codigoError}
                            textoBoton = "ACEPTAR"
                            handleClose = {()=>{this.props.dispatch({type:"LIMPIAR_ERRORES_PROYECTO"})}}
                        />
                        <ModalSpinner show = {!this.state.cargadoApi || this.state.cargandoFotos}/>
                        {this.props.subiendo?
                            <div className="contUpload text-center">
                                <p className="h3 text-weight-light mb-0"> Subiendo Fotos</p>
                                <div className="mb-4" >Este proceso puede tardar varios minutos...</div>
                                <div className="h1 label-porcentaje text-center">{porcentajeSubidas}%</div>
                                <ProgressBar  now={porcentajeSubidas}  />
                            </div>
                        :
                        <div > 
                            <div className="mb-3 text-center">
                                {this.state.conexionExitosa?
                                    this.state.iniciadaSesion?
                                        
                                        <button type="button" className="google-button" id = "signout-button" onClick = {this.signOut}>
                                          <span className="google-button__icon">
                                            <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg"><path d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z" id="Shape" fill="#EA4335"/><path d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z" id="Shape" fill="#FBBC05"/><path d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z" id="Shape" fill="#4285F4"/><path d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z" fill="#34A853"/></svg>
                                          </span>
                                          <span className="google-button__text">Salir de mi cuenta de Google </span>
                                        </button>
                                    : 
                                        <button type="button" className="google-button"  id="signin-button" onClick={this.signIn}>
                                          <span className="google-button__icon">
                                            <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg"><path d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z" id="Shape" fill="#EA4335"/><path d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z" id="Shape" fill="#FBBC05"/><path d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z" id="Shape" fill="#4285F4"/><path d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z" fill="#34A853"/></svg>
                                          </span>
                                          <span className="google-button__text">Entrar a mi cuenta de Google</span>
                                        </button>
                                    :null
                                }
                            </div>
                            {this.state.iniciadaSesion?
                                this.state.fase === "albumes"?

                                <Albumes albumes = {this.state.albumes} irAlbum = {this.irAlbum}/>
                                :
                                <GridFotos
                                    volverAlbumes = {this.volverAlbumes}
                                    toggleSelect = {this.toggleSelect}
                                    seleccionMultiple ={this.seleccionMultiple}
                                    fotos = {this.state.fotos}
                                    tipoSeleccion = {this.props.tipoSeleccion}
                                    asignarPortadaGooglePhotos = {this.props.asignarPortadaGooglePhotos}/>
                            :
                                null
                            }
                            
                                
                            {this.state.cargadoApi && (esDefinido(this.state.nextPageToken) || esDefinido(this.state.nextPageTokenAlbums) ) && this.state.iniciadaSesion
                                 && !this.state.cargandoFotos?
                 
                                <Button variant="link" className="btn btn-block btn-lg" id ="lazyLoader" onClick = {this.cargarMas}>CARGANDO...</Button>
                            :
                                null
                            }

                            {this.state.iniciadaSesion && cuentaSeleccionadas>0?
                                    <div >
                                    <a className="btn btn-primary btn-general" onClick = {this.subir}>SUBIR FOTOS</a>
                                </div>
                            :
                            null
                            }
                        </div>
                        
                        }

                 
        
                </div>
    }
}
function getRandomInt() {
    return Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER));
}

function procesar(fotos){
    let f =  fotos.filter(foto=>{        
        return (foto.mimeType==="image/jpeg" || foto.mimeType==="image/png" || foto.mimeType=== "image/heif");
    })
    //console.log("PROCESAR FOTO");
    //console.log(f);
    f = f.map(foto=>{
        return {
            src: foto.baseUrl,
            thumbnail: foto.baseUrl,
            thumbnailWidth:parseInt(foto.mediaMetadata.width),
            thumbnailHeight:parseInt(foto.mediaMetadata.height),
            anchoOriginal:parseInt(foto.mediaMetadata.width),
            altoOriginal:parseInt(foto.mediaMetadata.height),
            isSelected:false,
            fecha: Math.round(new Date(foto.mediaMetadata.creationTime).getTime()/1000),
            lugar:"",
            caption:"",
            latitud:"",
            longitud:"",
            id: foto.id,
           id_foto:`google-photos:${foto.id}`
        }
    })
    return f;
};

function procesarAlbumes(albumes){
    
    let res = albumes.map(album=>{
        return {
            id:album.id,
            coverUrl:album.coverPhotoBaseUrl,
            coverUrl:album.coverPhotoBaseUrl,
            count:album.mediaItemsCount,
            nombre: album.title
        }
    })
    return res;
}
const mapStateToProps = (state)=>{
    return {
        errores: state.proyectos.errores,
        subiendo: state.seleccionarFotos.subiendo,
        codigoError: state.proyectos.codigoError,
        
    }
}
export default connect(mapStateToProps)(GooglePhotos);