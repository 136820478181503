import React,{Component} from "react";
import { connect } from "react-redux";
import {Elements, StripeProvider} from 'react-stripe-elements';
import FormularioEnvioPago from "./FormularioEnvioPago";
import CompraExitosa from "./CompraExitosa";
import config from "../../util/config";
import { ERROR_APLICAR_TIPO_ENVIO, ERROR_APLICAR_DESCUENTO,ERROR_PROMO_NO_ENCONTRADO,
    ERROR_ORDENAR, ERROR_PROCESAMIENTO_PAGO,ERROR_CODIGO_POSTAL, ERROR_AUTENTICACION
    } from "../../util/errores";
import ModalSpinner from "../modales/ModalSpinner";
import ModalesError from "../ModalesError";
import Modal from "react-bootstrap/Modal";


class Pago extends Component{
    
        constructor(props){
            super(props);
            if(!this.props.pagado && !this.props.pagando){
                this.props.history.push("/carrito");
            }
        }
    
  
    
    
    
    getModales = ()=>{
        let errores = [];
        let that = this;
        errores.push(ERROR_APLICAR_TIPO_ENVIO(this.props.errores === "APLICAR_TIPO_ENVIO",
            ()=>{
                
            this.props.dispatch({type:"LIMPIAR_ERRORES_CARRITO"});
        }));
        errores.push(ERROR_APLICAR_DESCUENTO(this.props.errores === "APLICAR_DESCUENTO",()=>{
                this.props.dispatch({type:"LIMPIAR_ERRORES_CARRITO"})}
            ,this.props.detalle));
        errores.push(ERROR_PROMO_NO_ENCONTRADO(this.props.errores === "PROMO_NO_ENCONTRADO",
        ()=>this.props.dispatch({type:"LIMPIAR_ERRORES_CARRITO"})));
        errores.push(ERROR_ORDENAR(this.props.errores === "GENERICO_ORDEN",
        ()=>this.props.dispatch({type:"LIMPIAR_ERRORES_CARRITO"})));
        errores.push(ERROR_AUTENTICACION(this.props.errores === "ERROR_AUTENTICACION",
            ()=>{this.props.dispatch({type:"LIMPIAR_ERRORES_CARRITO"});
                this.props.history.push("/")}));
        errores.push(ERROR_PROCESAMIENTO_PAGO(this.props.errores === "PAGO_RECHAZADO",
            ()=>this.props.dispatch({type:"LIMPIAR_ERRORES_CARRITO"})));
        errores.push(ERROR_CODIGO_POSTAL(this.props.errores === "CP_INVALIDO",
            ()=>this.props.dispatch({type:"LIMPIAR_ERRORES_CARRITO"})));
        return errores;
    }
    componentWillUnmount(){
        
        this.props.dispatch({type:"SALIR_PAGO"});
    }
    atras = ()=>{
        this.props.history.push("/carrito");
    }
    render(){
        if (this.props.pagado){
            return <CompraExitosa/>
        }
        else{
        return (<div id = "inner-content-wrapper" >
                    <ModalSpinner show = {this.props.cargando}/>
                    <div className="title-bar">
                        <span className="back-icon" onClick = {this.atras}> </span> 
                        PAGO Y ENVIO 
                    </div>
                    <div className="notice-bar invert">¡Envío gratis a partir de $500!</div>

            <ModalesError modales = {this.getModales()}/>
            <StripeProvider apiKey={config.stripe_publishable_key}>
        <div className="example pago-form">
          <Elements locale = "es">
            <FormularioEnvioPago/>
          </Elements>
        </div>
      </StripeProvider>
        </div>)
        }
    
    }
}
const mapStateToProps = (state)=>{
    return {
        total: state.carrito.total,
        token: state.clientes.token,
        costoEnvio: state.carrito.costoEnvio,
        porcentajeDescuento: state.carrito.porcentajeDescuento,
        errores: state.carrito.errores,
        pagando:state.carrito.pagando,
        pagado:state.carrito.pagado,
        cargando: state.carrito.cargando,
        detalle: state.carrito.detalle
    }
}
export default connect(mapStateToProps)(Pago);