import config from "./config";
import axios from "axios";
export function firebaseFunctionBajarPortada(fotoUrl){
    let url = config.googlePhotos.bajarPortadaUrl
    let data = {
        baseUrl: fotoUrl,
    }
    let options = {
        method :"POST",
        data:data,
        responseType:"arraybuffer",
        url
    }
    return axios(options)
}