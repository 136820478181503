import React,{Component} from "react";
import {connect} from "react-redux";
import pastaSuave from "../../images/pasta-suave.png";
import pastaDura from "../../images/pasta-dura.png";
import Navegacion from "../Navegacion";


var styleSuave = {
  backgroundImage: 'url(' + pastaSuave + ')',
};

var styleDura = {
  backgroundImage: 'url(' + pastaDura + ')',
};

class SeleccionarPasta  extends Component{
    
    seleccionarPasta = (tipo)=>{
        this.props.seleccionarPasta(tipo);
    }
    atras= ()=>{
        this.props.history.goBack();
    }
    render(){
        
        return(<div className="d-flex flex-column">
            <Navegacion location = {this.props.location.pathname}/>
            <div className="title-bar">
                <span className="back-icon" onClick = {this.atras}> </span> 
                Proyecto Nuevo
            </div>
            <div className="subtitle-bar">Selección de Tipo de Pasta</div>
            
            <div className="d-flex flex-column flex-sm-row flex-fill">
                <div className="d-flex flex-fill flex-column pasta-wrapper" >
                    <div className="flex-fill pasta-img" style={styleSuave} onClick = {()=>{this.seleccionarPasta("suave")}}  ></div>
                    <div className="pasta-info">
                        <div className="pasta-tipo">Pasta Suave</div>
                        <div className="pasta-descripcion">Fotolibro de 60 fotos (16x16 cm)</div>
                        <div className="pasta-precio">$299</div>
                    </div>
                </div>
                <div className="d-flex flex-fill flex-column " >
                    <div className="flex-fill pasta-img" style={styleDura} onClick = {()=>{this.seleccionarPasta("dura")}}  ></div>
                    <div className="pasta-info">
                        <div className="pasta-tipo">Pasta Dura</div>
                        <div className="pasta-descripcion">Fotolibro de 60 fotos (16x16 cm)</div>
                        <div className="pasta-precio">$349</div>
                    </div>
                </div>
            </div>
            <div className="notice-bar">¡Envío gratis a partir de $500!</div>
        </div>);
    }
}

export default connect()(SeleccionarPasta);