import React, {Component} from 'react';
import {ALLOWED_CHARACTERS_REGEX,NUMBERS_REGEX,
    CODIGO_POSTAL_MAX_LENGTH} from "./Pago";
import stripeLogo from "../../images/stripe.png";
import cards from "../../images/cards.png";
import {CardElement,CardNumberElement,
    CardExpiryElement,CardCVCElement, injectStripe,
    } from 'react-stripe-elements';
import { esDefinido } from '../../util/general';

class FormularioPago extends Component {
  constructor(props) {
    super(props);
  }
  componentDidUpdate  (prevProps,prevState,snapshot ){
    if(esDefinido(this.props.titular)  && !esDefinido(prevProps.titular)){
      this.setState({titular:this.props.titular});
    }
  }

  
  
  render() {
    return (
    <div>

      <div className="checkout">
        <div className="form-title">MÉTODO DE PAGO</div>
                                          
        <div className="d-flex stripe-block">
          <img src = {cards} alt = "tarjetas"/>
          <img src = {stripeLogo} alt = "Stripe"/>

        </div>
        <div className="form-group">
          <label>NOMBRE DEL TITULAR</label>
          <input className="form-control"  type = "text" value = {this.props.titular} onChange = {(ev)=>this.props.handleChange("titular",ev.target.value)}/>
          {this.props.alertaErroresPor("titular")}
        </div>
        <div className="form-group">
          <label>NÚMERO DE TARJETA</label>
          <CardNumberElement/>
          {this.props.alertaErroresPor("numeroTarjeta")}
        </div>
        <div className="form-group">
          <label>CÓDIGO POSTAL DE TARJETA</label>
          <input  className="form-control"  type = "text" value = {this.props.codigoPostalCard} onChange = {(ev)=>this.props.handleChange("codigoPostalCard",ev.target.value)}/>
          {this.props.alertaErroresPor("codigoPostalCard")}
        </div>
        <div className="row"> 
        <div className="col">
        <div className="form-group">
          <label>MM/AA</label>
          <CardExpiryElement/>
          {this.props.alertaErroresPor("expiracion")}
        </div>
        </div>
        <div className="col-auto">
        <div className="form-group">
          <label>CÓDIGO DE SEGURIDAD</label>
          <CardCVCElement/>
          {this.props.alertaErroresPor("cvc")}
        </div>
        </div>
        </div>
      </div>

    </div>

    );
  }
}

export default FormularioPago;