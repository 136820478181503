import "intersection-observer";
import {getFromBucket} from "./images";
import {bucket} from "./const";
import "intersection-observer";
const config = {
    rootMargin: '0px 0px 50px 0px',
    threshold: 0
  };
  
  // register the config object with an instance
  // of intersectionObserver
  let observer = new IntersectionObserver(function(entries, self) {
      //console.log("INTERSECTION OBSERVER");
      //console.log(bucket);
    // iterate over each entry
    entries.forEach(entry => {
      // process just the images that are intersecting.
      // isIntersecting is a property exposed by the interface
      if(entry.isIntersecting) {
        // custom function that copies the path to the img
        // from data-src to src
        preloadImage(entry.target);
        // the image is now in place, stop watching
        self.unobserve(entry.target);
      }
    });
  }, config);

  function preloadImage(target){
    //console.log("PRELOAD IMAGE");
      const src = target.getAttribute("data-src");  
      const identificador = target.getAttribute("identificador");
      const categoria = target.getAttribute("categoria");
      //console.log(target);
      //console.log(identificador);
      //console.log(categoria);
      let img = getFromBucket(categoria,{identificador:identificador,source :src});
     
      while(target.firstElementChild)
        target.removeChild(target.firstElementChild);
      if(img.complete){
        //console.log("IMG COMPLETE");
        //console.log(img);
          target.appendChild(img)
      }
      else{
        img.onload = function(){
          
          //console.log("ONLOAD");
          //console.log(img);
            target.appendChild(img);
        }
    }
  }

  export default observer;