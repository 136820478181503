import {esDefinido} from "../util/general";
import {clearBuckets} from "../util/images";
const estadoInicial = {
    hayUsuario:false,
    errores:"",
    cargando:false,
    nombre:"",
    email:"",
    detalleError:"",
    iniciado:false,
    mostrarEnviadoEmailRecuperacion: false,
    visitadoSeleccionarFotos:false,
    visitadoConfiguracion:false,
    visitadoReordenamiento:false,
    visitadoEditarCaption:false,
    mostrarAdvertenciaNavegador:false
}
const  clientesReducer = (state = estadoInicial, action) => {
    let res = {};
switch(action.type){       
    case "INICIALIZAR":
        let uuid = localStorage.getItem("uuid");
        let token = localStorage.getItem("token");
        let visitadoSeleccionarFotos = localStorage.getItem("visitadoSeleccionarFotos") || false;
        let visitadoConfiguracion = localStorage.getItem("visitadoConfiguracion") || false;
        let visitadoReordenamiento = localStorage.getItem("visitadoReordenamiento") || false;
        let visitadoEditarCaption = localStorage.getItem("visitadoEditarCaption") || false;
        let advertencia = localStorage.getItem("mostrarAdvertenciaNavegador");
        let mostrarAdvertenciaNavegador = esDefinido(advertencia)?JSON.parse(advertencia):      !window.navigator.appVersion.toUpperCase().includes("CHROME");
        let hayUsuario = esDefinido(uuid) || esDefinido(token);

        res = Object.assign({},state,{uuid:uuid,token:token,hayUsuario:hayUsuario,iniciado:true,
            visitadoSeleccionarFotos:visitadoSeleccionarFotos,
            visitadoConfiguracion: visitadoConfiguracion,
            visitadoReordenamiento: visitadoReordenamiento,
            visitadoEditarCaption: visitadoEditarCaption,
        mostrarAdvertenciaNavegador:mostrarAdvertenciaNavegador})
        return res;
    case "VISTA_ADVERTENCIA_NAVEGADOR":
            res = Object.assign({},state,{mostrarAdvertenciaNavegador:false,iniciado:true});
            localStorage.setItem("mostrarAdvertenciaNavegador",false);
            return res;
    case "VISITADO_OVERLAY":
        res = Object.assign({},state);
        res[action.overlay] = true;
        localStorage.setItem(action.overlay,true);
        return res;
    case "INICIAR_SESION":
        res = Object.assign({},state,{hayUsuario:true,token:action.token,uuid:null})
        localStorage.setItem("token",action.token);
        localStorage.removeItem("uuid");
        return res;
    case "CERRAR_SESION":
        res = Object.assign({},state,{hayUsuario:false,token:null,
            visitadoSeleccionarFotos:false,
            visitadoConfiguracion:false,
            visitadoReordenamiento:false,
            visitadoEditarCaption:false})
        localStorage.clear();        
        clearBuckets(["portadasProy","portadasVol","fotos","portadasCarrito"]);
        return res;
    case "REGISTRAR_ANON":
        res = Object.assign({},state,{hayUsuario:true,uuid:action.uuid})
        localStorage.setItem('uuid',action.uuid);
        return res;
    case "ERRORES":
        res = Object.assign({},state,{errores: action.errores, detalleError:action.detalleError});
        return res;
    case "LIMPIAR_ERRORES_CLIENTE":
        res = Object.assign({},state,{errores: ""});
        return res;
    case "CARGANDO_CLIENTES":
        res = Object.assign({},state,{cargando:true});
        return res;
    case "CARGADO_CLIENTES":
        res = Object.assign({},state,{cargando:false});
        return res;
    
    case "ACTUALIZAR_DATOS_CLIENTE":    
        res = Object.assign({},state,{nombre: action.datos.nombre,email: action.datos.email});
        return res
    case  "ENVIADO_EMAIL_RECUPERACION":
        res = Object.assign({},state,{mostrarEnviadoEmailRecuperacion:true});
        return res;
    case  "ACEPTAR_ENVIADO":
        res = Object.assign({},state,{mostrarEnviadoEmailRecuperacion:false});
        return res;
    default:
        
    return state
}
}

export default clientesReducer;