import React, {Component} from "react";

class VolumenTematicoCarrito extends Component{
    render(){
        return <div className = "proyecto-libro-tematico"  onClick = {this.props.onClick } 
        style={{
            backgroundImage: `url("${this.props.url_forro}")`,
            
        }}>
                            
        <div 
        
        
        identificador = {this.props.id}
        categoria ="portadasVol"
        className = "proyecto-libro-inner">
        </div>
        
    </div>
    }
}

export default VolumenTematicoCarrito;