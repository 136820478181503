import {reqCargarCarrito,reqEditarVolumen,reqEliminarProyecto,
    reqAplicarTipoEnvio, reqAplicarDescuento,reqOrdenar,
    reqObtenerResumenCompra} from "./carrito";
import {reqCargarDatos} from "./clientes";
import {estados} from "../app/carrito/FormularioEnvioPago";
import {registrar} from "./eventos";
export function cargarCarrito(dataAut){
    return function(dispatch,getState){
        dispatch({type:"CARGANDO_CARRITO"});
        dispatch({type:"ACTUALIZAR_CARRITO", carrito:[],total:0})
        reqCargarCarrito(dataAut).then(resp=>{
            dispatch({type:"ACTUALIZAR_CARRITO", carrito:resp.data.proyectos,total:resp.data.total});
            dispatch({type:"CARGADO_CARRITO"})
        }).catch(err=>{
            dispatch({type:"ERROR_CARRITO",valor: "CARGAR_CARRITO"});
            dispatch({type:"CARGADO_CARRITO"})  
            //console.log("ERROR AL CARGAR CARRITO");
            //console.log(err);
        })
    }
}

export function aumentarCantidad(dataAut, volId, cantidad){
    return function(dispatch,getState){
        try{
            dispatch({type:"CARGANDO_CARRITO"});
           
            reqEditarVolumen(dataAut,volId,cantidad +1).then(resp=>{
                dispatch({type:"ACTUALIZAR_TOTALES", total:resp.data.total,     
                    totalVol:resp.data.total_volumen, volId: volId});
                    dispatch({type:"CARGADO_CARRITO"});    
                dispatch({type:"AUMENTAR_CANTIDAD", volId:volId});
            }).catch(err=>{ 
                dispatch({type:"ERROR_CARRITO",valor:"EDITAR_CANTIDAD"});
                dispatch({type:"CARGADO_CARRITO"});    
                });
        }catch(err){
            //console.log("ERROR EDITAR TRYCATCH");
            dispatch({type:"ERROR_CARRITO",valor:"EDITAR_CANTIDAD"});
            dispatch({type:"CARGADO_CARRITO"});
        }
    }
}
export function disminuirCantidad(dataAut,volId,cantidad){
    return function(dispatch,getState){
        try{
            dispatch({type:"CARGANDO_CARRITO"});
            
            reqEditarVolumen(dataAut,volId,cantidad-1).then(resp=>{
                dispatch({type:"CARGADO_CARRITO"});
                dispatch({type:"ACTUALIZAR_TOTALES", total:resp.data.total,     
                    totalVol:resp.data.total_volumen, volId: volId});
                dispatch({type:"DISMINUIR_CANTIDAD", volId:volId});
            }).catch(err=>{ 
                dispatch({type:"ERROR_CARRITO",valor:"EDITAR_CANTIDAD"});
                dispatch({type:"CARGADO_CARRITO"});    
                });
        }catch(err){
            dispatch({type:"ERROR_CARRITO",valor:"EDITAR_CANTIDAD"});
            dispatch({type:"CARGADO_CARRITO"});
        }
    }
}

export function eliminarProyecto(dataAut,proyId){
    return function(dispatch,getState){
        dispatch({type:"CARGANDO_CARRITO"});
        reqEliminarProyecto(dataAut,proyId).then(resp=>{
            dispatch({type:"ELIMINAR_PROYECTO_CARRITO",proyId:proyId,total:resp.data.total})
            dispatch({type:"CARGADO_CARRITO"});
        }).catch(err=>{
            dispatch({type:"ERROR_CARRITO", valor: "ELIMINAR_PROYECTO"});
            dispatch({type:"CARGADO_CARRITO"});
        });
    }
}
export function aplicarTipoEnvio(dataAut,tipoEnvio,CB){
    return function(dispatch,getState){
        dispatch({type:"CARGANDO_CARRITO"})
        reqAplicarTipoEnvio(dataAut,tipoEnvio).then(resp=>{
            dispatch({type:"ACTUALIZAR_COSTO_ENVIO",valor:resp.data.total})
            CB();
        }).catch(err=>{
            dispatch({type:"ERROR_CARRITO",valor:"APLICAR_TIPO_ENVIO"});
        }).finally(()=>{
            dispatch({type:"CARGADO_CARRITO"});
        })
    }
}
export function aplicarDescuento(dataAut,codigoDescuento){
    return function(dispatch,getState){
        dispatch({type:"CARGANDO_CARRITO"})
        reqAplicarDescuento(dataAut,codigoDescuento).then(resp=>{
            dispatch({type:"ACTUALIZAR_PORCENTAJE_DESCUENTO",valor:resp.data.porcentaje})
            dispatch({type:"ACTUALIZAR_MONTO_DESCONTADO", valor: resp.data.monto_descontado})
            dispatch({type:"ACTUALIZAR_TIPO_DESCUENTO",valor:resp.data.tipo_descuento})
        
        }).catch(err=>{
            //console.log("ERROR APLICAR DESCEUNTO");
            //console.log(err.response);
            if(err.response){
                if(err.response.status === 403){
                    dispatch({type:"ERROR_CARRITO",valor:"APLICAR_DESCUENTO", detalle: err.response.data.error})
                    
                }
                else{
                    dispatch({type:"ERROR_CARRITO",valor:"APLICAR_DESCUENTO", detalle: "No se pudo aplicar el código de descuento"})
                }
            }
            else{
                dispatch({type:"ERROR_CARRITO",valor:"APLICAR_DESCUENTO",detalle:"El código que proporcionaste es inválido"})
            }
        }).finally(()=>{
            dispatch({type:"CARGADO_CARRITO"});
        })
    }
}
export function obtenerResumenCompra(dataAut,codigoDescuento, tipoEnvio, cb){
    return function(dispatch,getState){
        dispatch({type:"CARGANDO_CARRITO"})
        reqObtenerResumenCompra(dataAut,codigoDescuento, tipoEnvio).then(resp=>{
            console.log("OBTENIDO RESUMEN COMPRA");
            console.log(resp);
            dispatch({type:"ACTUALIZAR_PORCENTAJE_DESCUENTO",valor:resp.data.porcentaje})
            //dispatch({type:"ACTUALIZAR_MONTO_DESCONTADO", valor: resp.data.monto_descontado})            
            dispatch({type:"ACTUALIZAR_TIPO_DESCUENTO",valor:resp.data.tipo_descuento})
            dispatch({type:"ACTUALIZAR_COSTO_ENVIO", valor:resp.data.costo_envio})
            dispatch({type:"ACTUALIZAR_DESCUENTO",valor:resp.data.descuento})        
            cb()    
        }).catch(err=>{
            console.log("obtener resumen compra");
            console.log(err);
            console.log(err.response);
            if(err.response){
                if(err.response.status === 403){
                    if(err.response.data.error === "ENVIO"){
                        dispatch({type:"ERROR_CARRITO",valor:"APLICAR_TIPO_ENVIO", detalle: err.response.data.error})
                    }else{
                        dispatch({type:"ERROR_CARRITO",valor:"APLICAR_DESCUENTO", detalle: err.response.data.error})
                    }
                    
                    
                }
                else{
                    dispatch({type:"ERROR_CARRITO",valor:"APLICAR_DESCUENTO", detalle: "No se pudo aplicar el código de descuento"})
                }
            }
            else{
                dispatch({type:"ERROR_CARRITO",valor:"APLICAR_DESCUENTO",detalle:"El código que proporcionaste es inválido"})
            }
        }).finally(()=>{
            dispatch({type:"CARGADO_CARRITO"});
        })
    }
}
export function cargarDatosPagoPrevio(dataAut,CB){
    return function(dispatch,getState){

        dispatch({type:"CARGANDO_CARRITO"});
        
        reqCargarDatos(dataAut).then(resp=>{
            let datos = {
                titular: resp.data.nombre,
                destinatario: localStorage.getItem("destinatario")||"",
                direccion: localStorage.getItem("direccion")||"",
                direccion2: localStorage.getItem("direccion2")||"",
                ciudad: localStorage.getItem("ciudad")||"",
                estado: localStorage.getItem("estado")||estados[0],
                codigoPostal: localStorage.getItem("codigoPostal")||"",
                telefono: localStorage.getItem("telefono")||"",
                tipoEnvio: localStorage.getItem("tipoEnvio")||"estandar",
            }
            dispatch({type:"ACTUALIZAR_DATOS_CLIENTE",datos:resp.data})
            CB(datos);
            //dispatch({type:"ACTUALIZAR_DATOS_PAGO_PREVIO",datos:datos})
        }).catch(err=>{
            //console.log(err);
            dispatch({type:"ERROR_CARRITO",valor:"CARGAR_DATOS_PAGO_PREVIO"});
        }).finally(()=>{
            dispatch({type:"CARGADO_CARRITO"});
        })
    }   
}
export function ordenar(dataAut,datos,cardToken,email){
    
    delete datos["errores"];
    return function(dispatch,getState){
        dispatch({type:"CARGANDO_CARRITO"});
        reqOrdenar(dataAut,datos,cardToken).then(el=>{
            registrar({categoria:"ORDENES",etiqueta:email,value:1,accion: "PAGADA"})
            persistirDatosPago(datos);
            dispatch({type:"COMPRA_EXITOSA"});
        }).catch(err=>{
            
            if(err.response){
                
                if(err.response.status === 401){
                    
                    dispatch({type:"ERROR_CARRITO",valor:"ERROR_AUTENTICACION"});
                    dispatch({type:"CERRAR_SESION"});                    
                }
                if(err.response.status === 500){
                    
                    
                    if(err.response.data.error === "Stripe"){
                        
                        dispatch({type:"ERROR_CARRITO",valor:"PAGO_RECHAZADO"});
                    }
                    else if(err.response.data.error === "codigo_postal"){
                        
                        dispatch({type:"ERROR_CARRITO",valor:"CP_INVALIDO"});
                    }
                    else{
                        
                        dispatch({type:"ERROR_CARRITO",valor:"ORDENAR"});
                    }
                    
               }
            }
            else{
                
                dispatch({type:"ERROR_CARRITO",valor:"ORDENAR"});
            }
            
            
            
        }).finally(()=>dispatch({type:"CARGADO_CARRITO"}));
    }
}

function persistirDatosPago(datos){
    localStorage.setItem("destinatario",datos.destinatario);
    localStorage.setItem("direccion",datos.direccion);
    localStorage.setItem("direccion2",datos.direccion2);
    localStorage.setItem("ciudad",datos.ciudad);
    localStorage.setItem("estado",datos.estado);
    localStorage.setItem("codigoPostal",datos.codigoPostal);
    localStorage.setItem("telefono",datos.telefono);
    localStorage.setItem("tipoEnvio",datos.tipoEnvio);                
}