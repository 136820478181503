import React,{Component} from "react";
class NoDragFoto extends Component{
    render(){
        return <div 
        className='noDragFoto' 
        data-src ={this.props.src}
        categoria = "fotos"
        identificador = {this.props.id}
        >
      </div>
    }
}

export default NoDragFoto;