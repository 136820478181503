import {reqRegistrarAnonimo,reqIniciarSesion,
    reqIniciarSesionTardia} from "./usuarios";
import {reqListarProyectos} from "./proyectos";
import {esDefinido} from "./general";
import {reqCrearProyecto} from "./proyectos";
export function comenzar(){
    
    return function(dispatch,getState){
        const cadena ="DESKTOP"+ Math.random().toString()+Date.now().toString();
        reqRegistrarAnonimo(cadena,(assigned)=>{
            dispatch({type:"REGISTRAR_ANON", uuid:assigned})
        },
        ()=>{
            dispatch({type:"ERRORES",errores:"REGISTRO"})
        });
    }
}
export function iniciarSesion(email,password,history){
    let datos = {email,password}
    return function(dispatch,getState){
        reqIniciarSesion(datos,(token)=>{
            dispatch({type:"INICIAR_SESION",token:token})
            history.push("/proyectos");
        },()=>{
            dispatch({type:"ERRORES",errores:"AUTENTICACION"});
        })
    }
}
export function iniciarSesionTardia(email,password,uuid,history,prevPath,preIniciarSesion){
    let datos = {email,password}
    return function(dispatch,getState){

        reqIniciarSesionTardia(datos,uuid,(token)=>{
            dispatch({type:"INICIAR_SESION",token:token});
            
            if(preIniciarSesion === "/carrito/pago"){
                dispatch({type:"PAGANDO"});
            }
            if(prevPath.pathname === "/recuperar_contrasena"){
                history.push(preIniciarSesion);
            }
            else{
                
                history.goBack();
            }
        },(err)=>{
            dispatch({type:"ERRORES",errores:"AUTENTICACION"});
            console.log(err);
            console.log(err.response);
        })
    }
}
export function listarProyectos(dataAut){
    return function(dispatch,getState){
        dispatch({type:"CARGANDO_PROYECTOS"})
        reqListarProyectos(dataAut,(resp)=>{
         
            let proyectos = resp.data.proyectos;
            dispatch({type:"REGISTRAR_PROYECTOS",proyectos:proyectos})
            dispatch({type:"CARGADO_PROYECTOS"});
        },(err)=>{
            if(esDefinido(err.response)){
                if (err.response.status===401){
                    dispatch({type:"CERRAR_SESION"});
                    dispatch({type:"ERRORES",errores:"AUTENTICACION"});
                    dispatch({type:"CARGADO_PROYECTOS"});
                }
                else{
                    dispatch({type:"ERRORES",errores:"PROYECTOS"});
                    dispatch({type:"CARGADO_PROYECTOS"});
                }
            }
            else{
                dispatch({type:"ERRORES",errores:"PROYECTOS"});
                dispatch({type:"CARGADO_PROYECTOS"});
            }
        });
    }
}
export function crearProyecto(dataAut,tipoPasta){
    return function(dispatch,getState){
        reqCrearProyecto(dataAut,tipoPasta,
            (resp)=>{
                //console.log("SE GUARDO PROYECTO");                
                //console.log(resp);
                dispatch({type:"SELECCIONAR_PROYECTO",id:resp.data.proyecto_id})
            },
            (err)=>{
                console.log("ERROR AL CREAR PROYECTO");
                //console.log(err.response);
            }
        )
    }   
}