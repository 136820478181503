import React,{Component} from "react";
import Dropzone from 'react-dropzone';
import { esDefinido } from "../../util/general";
import { obtenerImg,hacerThumbnail,cropear } from "../../util/images";
import {reqSeleccionarPortada} from "../../util/proyectos";
import Nav from "react-bootstrap/Nav";
import GooglePhotos from "./Integraciones/GooglePhotos";
import {asignarPortadaGooglePhotos} from "../../util/asyncProyectos";
import {connect} from "react-redux";
import {getDataAut} from "../../util/general";
import ModalSpinner from "../modales/ModalSpinner";
import ModalesError from "../ModalesError";
import { ERROR_SELECCION_PORTADA}from "../../util/errores";
import Alert from "react-bootstrap/Alert";

const baseStyle = {
    width: 200,
    height: 200,
    borderWidth: 2,
    borderColor: 'grey',
    borderStyle: 'dashed',
    borderRadius: 5
  };
class SeleccionPortada extends Component{
    constructor(props){
        super(props);
        this.state = {
            foto: null,
            tab:"dispositivo",
        errores:""}
    }
    obtenerErrores =()=>{
        let errores = [];
       
        errores.push(ERROR_SELECCION_PORTADA(this.props.erroresFotos === "SELECCION_PORTADA",
        (ev)=>{
            this.props.dispatch({type:"LIMPIAR_ERRORES_FOTO"});
          }));
        
        
        return errores;
    }
    dropRejected = (files,x)=>{
        
        for(let i = 0;i<files.length;i++){
            if(files[i].type === "image/jpeg" ||files[i].type==="image/png"){
                this.setState({errores:"Solo se puede seleccionar un archivo"})
                return;
            }
            
        }
        this.setState({errores:"El archivo no es válido"});

    }
    componentDidMount(){
        if(!esDefinido(this.props.numVolumenSeleccionado)){
            this.props.history.push("/proyectos");
        }
    }
    atras = ()=>{
        this.props.history.push({
            pathname: '/proyectos/editar',
            state: { 
                from: this.props.location.pathname
            }
          })
    }
    selectTab = (key)=>{

        
        this.setState({tab:key});
    }
    enviar =  async(files,rejections)=>{
        if (rejections.length > 0 ){            
            return;
         }
        
        let img = await obtenerImg(files[0]);
        this.props.dispatch({type:"CARGANDO_FOTOS"});
        let foto = procesar(img).then(async resp=>{

        let props = this.props;
        let proy = props.proyectoSeleccionado;
        let vol = props.numVolumenSeleccionado;
        let x = document.getElementById("previewCrop");
        let img = await obtenerImg(resp.foto);        
        let porToken = this.props.token !== null && typeof this.props.token !=="undefined";
        let dataAut = porToken?{token:this.props.token}:{uuid: this.props.uuid}
        //resp.foto.name = img.name;
        reqSeleccionarPortada(dataAut,proy,vol,resp.foto).then(resp=>{
            this.atras();
        }).catch(err=>{
            console.log("ERROR ENDPOINT SELECCIONAR PORTADA");
            console.log(err);
            this.props.dispatch({type:"ERROR_FOTO", valor:"SELECCION_PORTADA"});
            this.props.dispatch({type:"CARGADO_FOTOS"});
        })            
        }).catch(err=>{
            console.log("ERROR AL PROCESAR");
            console.log(err);
            this.props.dispatch({type:"ERROR_FOTO", valor:"SELECCION_PORTADA"});
            this.props.dispatch({type:"CARGADO_FOTOS"});
        });
    }
    actualizarPreview = async(files)=>{
        
        let file = files[0];
        if(!esDefinido(file)){
            return
        }
        let img = await obtenerImg(file);

        img.name = file.path;
        this.setState({foto:img})
    }
    preview =()=>{
        if(!esDefinido(this.state.foto))
            return null;
        let that = this;
        return <div className = "previewImagen">
                <img src = {this.state.foto.src}/>            
                </div>        

    }
    cancelar = ()=>{
        this.props.volverAVolumenes();
    }
    
    asignarPortadaGooglePhotos = ( datosFoto)=>{

        let proyId = this.props.proyectoSeleccionado;
        let volNum = this.props.numVolumenSeleccionado;
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        
        this.props.dispatch(asignarPortadaGooglePhotos(dataAut,volNum,proyId,datosFoto,()=>{
            this.atras();
        }));
    }
    render (){
        
        return <div>
            <ModalSpinner show = {this.props.cargando}/>
            <ModalesError modales = {this.obtenerErrores()}/>
            <div className ="title-bar">
                <span className="back-icon" onClick = {this.atras}> </span>
                {this.props.nombre}
            </div>
            
            <div class="subtitle-bar">Selección de Portada</div>
             <div className="inner-content" >   

            <Nav variant="tabs" defaultActiveKey="dispositivo"
                    activeKey = {this.state.tab}
                    onSelect = {this.selectTab}
                    >
                    <Nav.Item >
                        <Nav.Link disabled={this.props.subiendo||this.props.procesando}
                            eventKey = "dispositivo">Dispositivo</Nav.Link>
                    </Nav.Item>
                    <Nav.Item >
                       <Nav.Link disabled={this.props.subiendo || this.props.procesando}
                        eventKey = "google_photos">Google Photos</Nav.Link>
                    </Nav.Item>
                    
                </Nav>
               
            {this.state.tab === "dispositivo"?
                
                <Dropper enviar = {this.enviar} onDropRejected = {this.dropRejected} errores = {this.state.errores}/>
            :
                <GooglePhotos 
                    tipoSeleccion = "PORTADA"
                    asignarPortadaGooglePhotos = {this.asignarPortadaGooglePhotos}/>
            }
            </div>
        </div>
    }
}
function procesar(img){
    return new Promise(async (resolve,reject)=>{
        try{
            let cropped = await cropear(img);
            let thumbnail = await hacerThumbnail(cropped);
            resolve({foto:cropped, thumbnail:thumbnail});
        }
        catch(err){
            reject(err);
        }
    })
}
class Dropper extends Component {
    render(){
        return(
             
        <div className = "uploadWrapper">
        <Dropzone onDrop={this.props.enviar}
        accept = 'image/png,image/jpeg'
            onDropRejected = {this.props.onDropRejected}
            multiple = {false}>
            {({getRootProps, getInputProps}) => (
                <section>
                <div {...getRootProps()} className="drop-area mb-3">
                    <input {...getInputProps()} id ="selector" />
                    <p>Arrastra aquí - la foto de portada seleccionada, o <span className="link-style">haz click</span> para seleccionar una</p>
                </div>
                </section>
            )}
        </Dropzone>
        {this.props.errores!==""?
            <Alert variant = "danger">{this.props.errores}</Alert>
        :
            null
            }
            
        </div>);
    }
}
const mapStateToProps = (state)=>{
    return {
        token: state.clientes.token,
        uuid: state.clientes.uuid,
        proyectoSeleccionado: state.proyectos.proyectoSeleccionado,
        numVolumenSeleccionado: state.proyectos.numVolumenSeleccionado,
        volumenes: state.proyectos.volumenes,
        cargando: state.fotos.cargando,
        erroresFotos: state.fotos.errores,
        nombre: state.proyectos.nombre,
        
    }
}
export default connect(mapStateToProps)(SeleccionPortada);