import React,{Component} from "react";
import textoTerminos from  "../util/textoterminosycondiciones";

import Header from "./HeaderSimple";


class Terminos extends Component{
    atras = ()=>{
        this.props.history.goBack();
    }
    render(){
        return(

            <div id = "inner-content-wrapper">
                <Header />

                    <div className="title-bar">
                        <span className="back-icon" onClick = {this.atras}> </span> 
                        Términos Y Condiciones
                    </div>
                    <div className="terminos inner-content">
          
                        <h2>1.- Términos Generales&nbsp;</h2>
                        <p>BOOKSNAP S.A.S. DE C.V. (en lo sucesivo “BOOKSNAP”) pone a su disposición estos Términos y Condiciones (en lo sucesivo los “Términos y Condiciones de Uso") los cuales definen sus derechos, responsabilidades y beneficios como miembro de este servicio, consistente en el uso de la aplicación denominada BOOKSNAP (en lo sucesivo la “Aplicación") por medio de la cual usted podrá seleccionar imágenes de redes sociales o de su galería de imágenes de su smartphone, sus fotos a efecto de que BOOKSNAP realice la impresión de las mismas en un fotolibro (en lo sucesivo el “Servicio"). Al usar el Servicio, usted acepta estos Términos y Condiciones en su totalidad.
                        </p>
                        <p> Gracias por visitar la aplicación y/o leer el contenido del Servicio a través de cualquier medio electrónico, sea correo electrónico, mensaje electrónico, página web, entre otros (en lo sucesivo los “Medios”). Al tener acceso al Servicio a través de la aplicación y/o los Medios, usted conviene en sujetarse a los siguientes Términos y Condiciones de Uso sin limitación o calificación, e implica la aceptación, plena e incondicional, de todas y cada una de las condiciones generales y particulares incluidas en este documento, de manera que le pedimos revise cuidadosamente este documento antes de seguir adelante. Si Usted no pretende sujetarse legalmente a estos Términos y Condiciones de Uso, no acceda, ni use la aplicación y/o los Medios. En caso de que los acepte y se registre conforme a lo aquí estipulado, se le atribuirá la condición de usuario (en lo sucesivo el “Usuario” o los “Usuarios”).&nbsp;
                        </p>
                        <p> Este instrumento describe precisamente los términos y condiciones generales aplicables al uso del Servicio ofrecido por BOOKSNAP a través de la Aplicación.&nbsp;
                        </p>
                        <p> A través de la aceptación de los principios y reglas aquí definidas, independientemente de donde tenga acceso (Aplicación o Medios), usted podrá hacerse miembro inscrito en el Servicio y obtener todas las recompensas en el mismo.&nbsp;
                        </p>
                        <p> Cualquier trasgresión a estos Términos y Condiciones de Uso por parte del Usuario (incluyendo, pero no limitándose a la comisión de actos que, a criterio exclusivo de BOOKSNAP, constituyan conductas abusivas, inapropiadas o ilícitas) generarán el derecho en favor de BOOKSNAP, en cualquier momento y sin necesidad de notificación previa de ningún tipo, suspender o terminar la prestación del Servicio y/o de retirarle o denegarle el acceso al mismo al Usuario trasgresor.
                        </p>
                        <p> El Servicio puede ser utilizado únicamente con fines lícitos. El contenido, información y/o material que BOOKSNAP pone al alcance de los Usuarios a través de la Aplicación y/o los Medios contiene elementos amparados por normas de propiedad intelectual, incluyendo protección de software, fotografías, logos, diseños, imágenes gráficas, música y sonido, sin que esta descripción implique limitación de naturaleza alguna. Se encuentra terminantemente prohibido cualquier tipo de copia, distribución, transmisión, retransmisión, publicación, impresión, difusión y/o explotación comercial del material y/o contenido puesto a disposición del público a través de la Aplicación y/o los Medios, sin el previo consentimiento expreso y por escrito de BOOKSNAP o, en su caso, del titular de los derechos de propiedad correspondientes. El incumplimiento de lo aquí mencionado sujetará al infractor a todos los reclamos civiles y sanciones penales que pudieran corresponder. Asimismo, se encuentra prohibida la publicación y/o distribución de cualquier material que resulte obsceno, inapropiado, difamatorio o que pudiera resultar violatorio de los derechos de cualquier tercero o resulte contrario a las políticas generales de BOOKSNAP y/o al ordenamiento jurídico vigente.
                        </p>
                        <p> Usted conviene en usar los servicios del Aplicación y/o los Medios Electrónicos sólo para realizar negocios legítimos. La información de su cuenta es propiedad exclusiva de BOOKSNAP. Usted puede acceder a la información de su cuenta a través del Aplicación y/o los Medios.&nbsp;
                        </p>
                        <p> Usted debe acceder a la información de su cuenta directamente a través de la Aplicación o de los Medios y no a través de una página diferente.</p>
                        <h2>2.- Registro&nbsp;</h2>
                        <p>2.1. Para registrarse, usted debe crear una contraseña, así como indicar una dirección de correo electrónico, también, dicho registro podrá ser efectuado mediante una cuenta de Facebook.&nbsp;
                        </p>
                        <p> Usted es responsable de todo el uso que se origina en su cuenta, de la información y fotos proporcionadas a BOOKSNAP, asimismo, es responsable de la confidencialidad de su nombre de usuario (el cual será su correo electrónico), y contraseña; el Usuario será responsable de notificar a BOOKSNAP respecto de si detecta en su correo electrónico alguna actividad no identificada en la cuenta del Usuario.
                        </p>
                        <p> 2.2. Para que la calidad del servicio sea la optima, el Usuario deberá de proporcionar correctamente los datos solicitados por BOOKSNAP. BOOKSNAP en ningún caso se hará responsable por fallas en el servicio ocasionadas como resultado de un incorrecto llenado de datos.</p>
                        <p>2.3. Queda a la entera voluntad de BOOKSNAP la aceptación o negación del registro de cualquier persona en su sistema, en el caso de negación BOOKSNAP no está obligada a dar explicación alguna de la negativa.</p>
                        <p>2.4. El Usuario declara que la información proporcionada a BOOKSNAP es veraz, la autenticidad en la información proporcionada por el Usuario es fundamental para el correcto mantenimiento del registro. En el caso de que la información proporcionada en el registro no concuerde con la realidad y/o se este incurriendo en alguna conducta de carácter ilícito, BOOKSNAP podrá cancelar de forma unilateral la cuenta del Usuario, sin necesidad de cualquier tipo de aviso o advertencia. Los Usuarios garantizan y responderán, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los datos personales ingresados. El Usuario será el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que pudiera causar a BOOKSNAP y/o a terceros en virtud de la información que facilite a BOOKSNAP.
                        </p>
                        <p> 2.5. BOOKSNAP protegerá los datos personales de todos los Usuarios de conformidad con lo estipulado en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo la “Ley”); el Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo el “Reglamento”) y los Lineamientos del Aviso de Privacidad publicados por la Secretaría de Economía en el Diario Oficial de la Federación (en lo sucesivo los “Lineamientos”).
                        </p>
                        <p> Le solicitamos leer el Aviso de Privacidad que se encuentra en la página www.booksnap.co, por lo que, al firmar el presente, manifiesta su conformidad con el mismo.</p>
                        <p>2.6. El Usuario deberá ser mayor de 18 años y si llegado a ser necesario se compromete a actualizar sus datos conforme se le requiera, BOOKSNAP podrá utilizar diversos medios para identificar a sus Usuarios.</p>
                        <p>En caso de requerir mayor comprobación respecto de la veracidad de los datos proporcionados por el Usuario, BOOKSNAP se reserva el derecho de requerir cualquier tipo de comprobante o documento con el fin de verificar la veracidad de la información, BOOKSNAP igualmente podrá dar de baja sin necesidad de explicación alguna, las cuentas cuya información no pudiera ser verificada.&nbsp;
                        </p>
                        <p> El Usuario accederá a su cuenta personal (en lo sucesivo la “Cuenta”) mediante el ingreso de su nombre de usuario y clave de seguridad personal previamente designada (en lo sucesivo la “Clave de Seguridad”). Es responsabilidad del Usuario mantener la confidencialidad de la contraseña designada.
                        </p>
                        <p> El Usuario será responsable por las actividades que se realicen desde su cuenta, toda vez que el Usuario es el único con conocimiento de la clave de seguridad designada misma que es intransferible. Está totalmente prohibida la venta, cesión o transferencia de la cuenta o cualquier operación (incluyendo la reputación y calificaciones) bajo ningún título.
                        </p>
                        <p> BOOKSNAP se reserva la facultad de rechazar cualquier solicitud así como dar de baja cualquier solicitud previamente aceptada sin necesidad de proporcionar justificación alguna.</p>
                        <h2>3.- Uso de la Aplicación</h2>
                        <p>El Usuario acepta no utilizar la Aplicación para ninguno de los siguientes fines:</p>
                        <p>3.1. Para subir, publicar, solicitar imprimir, enviar por correo electrónico o transmitir de otra manera; cualquier material que sea o promueva conductas obscenas, ofensivas, blasfemas, pornográficas, ilegales, dañinas, difamatorias, calumniosas, vulgares, que promuevan el racismo, el fanatismo, el odio o el daño físico de cualquier tipo contra cualquier grupo o individuo;</p>
                        <p>3.2. Subir, publicar, solicitar imprimir, enviar por correo electrónico o transmitir de otra manera; cualquier material que proporcione información instructiva sobre actividades ilegales; esto puede incluir, entre otros, información sobre cómo fabricar o comprar armas y/o explosivos, así como transmitir o generar virus informáticos;</p>
                        <p>3.3. Subir, publicar, solicitar imprimir, enviar por correo electrónico o transmitir de otra manera; cualquier material que infrinja derechos de autor, marca registrada, patente u otro derecho de propiedad intelectual o derecho moral de cualquier persona, o que constituya una invasión de la privacidad o derechos publicitarios de otro;</p>
                        <p>3.4. Para subir, publicar, solicitar imprimir, enviar por correo electrónico o transmitir; cualquier material de trabajo protegido por derechos de autor de otra persona.</p>
                        <p>3.5. Dañar a menores de cualquier manera, esto puede incluir; publicar, ordenar imprimir, enviar por correo electrónico, transmitir o comunicar cualquier contenido que incite a la pornografía infantil, explotación sexual infantil y la representación de menores involucrados en una conducta sexual;</p>
                        <p>3.6. Falsificar encabezados o manipular identificadores para disfrazar el origen de cualquier contenido publicado, comunicado o transmitido a través de la Aplicación;</p>
                        <p>3.7. Cargar, publicar, enviar por correo electrónico o transmitir cualquier material que pueda dañar nuestros sistemas informáticos o los de cualquier otra persona, incluidos, entre otros, los virus, códigos, gusanos, datos u otros archivos o programas diseñados para dañar o permitir el acceso no autorizado al Servicio que pueda causar cualquier defecto, error, mal funcionamiento o corrupción del Servicio;</p>
                        <p>3.8. Ejercer cualquier actividad que implique la transmisión de "correo no deseado", "cartas en cadena" o correo masivo no solicitado.</p>
                        <p>3.9. Para promover información que el Usuario sabe o cree que es falsa o engañosa;</p>
                        <p>3.10. Para cualquier propósito comercial;</p>
                        <p>3.11. Suplantar a cualquier persona o entidad.&nbsp;</p>
                        <p>El Usuario también acepta que:</p>
                        <p>3.13. Es el único responsable del contenido que envíe, publique, transmita o comunique (información y fotos que comparta) a través de su cuenta personal.
                        </p>
                        <p> 3.12. No nos proporcionará ninguna información que sea falsa, que intente ocultar su identidad o que no tenga derecho a divulgar;</p>
                        <h2>4.- Disponibilidad del Servicio</h2>
                        <p>En general el Servicio estará disponible las veinticuatro horas del día durante los siete días de la semana, y solamente se suspenderá de manera provisional al momento de efectuar mantenimiento de la aplicación, o cuando se solicite actualización de la misma. BOOKSNAP no responderá por fallas en el servicio cuando la aplicación no se haya actualizado a su versión mas reciente disponible.</p>
                        <h2>5.- Condiciones Para Ingresar Al Servicio</h2>
                        <p>5.1. Para el debido uso del Servicio a solicitar, el Usuario deberá acceder ya sea a la aplicación o a la pagina web a través de un dispositivo debidamente conectado a internet y que cuente con un sistema operativo compatible con la aplicación.&nbsp;</p>
                        <p>5.2. BOOKSNAP no se hará responsable por interrupciones o suspensiones del servicio de acceso a Internet ocasionadas por la falla en el sistema de telecomunicaciones, en el suministro de energía eléctrica, casos fortuitos o de fuerza mayor o una acción de terceros que puedan inhabilitar los equipos que suministran el acceso a la red.&nbsp;</p>
                        <p>Por lo anterior, BOOKSNAP no se responsabiliza por cualquier daño, perjuicio o pérdida al Usuario causados por fallas en el sistema, en el servidor o en Internet. BOOKSNAP tampoco será responsable por cualquier virus que pudiera infectar el equipo del Usuario como consecuencia del acceso, uso o examen de la Aplicación y/o los Medios Electrónicos o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio contenidos en los mismos. Los Usuarios NO podrán imputarle responsabilidad alguna ni exigir pago de daños o perjuicios, en virtud de dificultades técnicas o fallas en los sistemas o en Internet. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a BOOKSNAP; en tales casos se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad.</p>
                        <h2>6.- Beneficios</h2>
                        <p>6.1. El Usuario reconoce que esta de acuerdo en recibir por parte de BOOKSNAP, por medio de la Aplicación pero también por medio de correo electrónico o cualquier Medio Electrónico, y vía impresa cualquier promoción o publicidad tendiente a que el Usuario reciba beneficios con sus respectivos términos y condiciones.</p>
                        <p>6.2. La Aplicación y/o los Medios son seguros y toda la información que pasa a través de los mismos está codificada, por lo que su contraseña y las imágenes seleccionadas para su impresión están protegidas por la seguridad de nuestra plataforma.</p>
                        <h2>7. Plazo de vigencia y terminación y rescisión contractual</h2>
                        <p>7.1. El plazo de vigencia de los presentes Términos y Condiciones de Uso es indefinido.&nbsp;</p>
                        <p>7.2. El usuario podrá en cualquier momento solicitar a BOOKSNAP dar de baja su cuenta y eliminar sus datos, esto sin&nbsp; que se entiendan extinguidas las obligaciones contraídas y no solventadas antes de la solicitud de baja.&nbsp;</p>
                        <p>7.3 La terminación de la relación comercial por BOOKSNAP en cualquiera de los supuestos mencionados en los presentes Términos y Condiciones, no implicará responsabilidad en ningún caso para BOOKSNAP ni obligación de la misma de indemnizar al usuario de forma alguna.</p>
                        <p>7.4. BOOKSNAP y el Usuario reconocen que podrán rescindir la relación comercial sin necesidad de declaración judicial previa, si cualquiera de ellos incumple con cualquiera de los Términos y Condiciones de Uso contemplados en este documento.</p>
                        <h2>8.- Compromiso</h2>
                        <p>8.1. BOOKSNAP se compromete a seguir las normas de privacidad y seguridad en términos de la Ley, el Reglamento y los Lineamientos, respetando la privacidad de los Usuarios y la confidencialidad de la información otorgada, no divulgando datos personales relativos a los registros, excepto en los siguientes casos: (i) para cumplir disposición legal u orden de autoridad judicial; (ii) para cumplir cualquier procedimiento instaurado por cualquier órgano competente; (iii) para hacer cumplir los términos de los Servicios; (iv) para responder reclamaciones de que tal contenido viole derechos de terceros o para proteger los interés de BOOKSNAP o de terceros; (v) si hubiera necesidad de identificar y resolver problemas técnicos.&nbsp;</p>
                        <p>8.2. En ningún caso BOOKSNAP asumirá ningún tipo de compromiso, por fraudes originados fuera o a través del sistema de los Usuarios y de sus computadoras, principalmente en relación a informaciones proporcionadas, sea en el registro o en el sistema.</p>
                        <p>8.2. Bajo ningún supuesto BOOKSNAP así como sus funcionarios, empleados, proveedores, socios, mandantes y sucesores a título particular autorizados serán responsables por el daños causados en virtud de un mal uso de la aplicación o del sistema web, incluyendo pero no limitándose al uso del contenido y/o Servicios ofrecidos a través de la Aplicación y/o los Medios.
                        </p>
                        <p> El uso que usted dé a la aplicación&nbsp;y/o los medios electrónicos es bajo su propio riesgo. El contenido se proporciona "como está" y sin ninguna garantía de ningún tipo, ya sea expresa o implícita, incluyendo, de manera enunciativa mas no limitativa, cualquier garantía de expectativa de privacidad implícita, propiedad para un propósito en particular, comerciabilidad, infracción a leyes o propiedad. En ningún caso, BOOKSNAP o sus subsidiarias o filiales serán responsables de ningún daño (ya sea en consecuencia, directo, incidental, indirecto, punitivo, especial o de alguna otra manera) que se derive de o se relacione con el uso de o la incapacidad de usar la aplicación o y/o los medios o por cualquier parte del contenido obtenido a través de o de alguna otra manera en relación con la aplicación y/o los medios. En cada caso, sin importar si tales daños se basan en contratos, responsabilidad neta, perjuicio u otras teorías de responsabilidad, y también sin importar si BOOKSNAP recibió una notificación real o implícita de que era posible que se incurriera en tales daños.</p>
                        <p>8.3. A través de la aceptación de los Términos y Condiciones de Uso, el Usuario acepta indemnizar y sacar en paz y a salvo a BOOKSNAP y a sus funcionarios, empleados, proveedores, socios, mandantes y sucesores a título particular autorizados respecto de todos los daños y perjuicios que BOOKSNAP pudiera llegar a sufrir por un mal uso de la aplicación por parte del Usuario: (i) los Términos y Condiciones de Uso, y/o (ii) cualesquiera leyes, normas, decretos o regulaciones vigentes. BOOKSNAP se reserva el derecho de defenderse en contra de acciones que pudieran ejercerse en contra de BOOKSNAP y que pudieran resultar en una indemnización de cualquier carácter a favor del accionante, así mismo, en caso de que BOOKSNAP decidiera llevar su defensa, el Usuario se compromete a apoyar a BOOKSNAP con todo lo que sea requerido por la misma para efectos del juicio en cuestión.</p>
                        <h2>9.- Propiedad Intelectual e Industrial</h2>
                        <p>9.1. BOOKSNAP, con sede en la Ciudad de México, reconoce ser la única propietaria de los derechos del dominio [www.booksnap.co] así como de la aplicación de nombre Booksnap, también declara que es propietaria de los derechos sobre el contenido, proyecto y código fuente tanto de los Medios como de la Aplicación y, en especial, sobre las fotografías, imágenes, textos, logos, proyectos, marcas, nombres comerciales y datos incluidos en la Aplicación y/o los Medios.&nbsp;</p>
                        <p>9.2. A los Usuarios se les advierte que tales derechos están protegidos por la legislación vigente Mexicana e internacional relativa a la propiedad intelectual e industrial.&nbsp;</p>
                        <p>9.3. Está prohibida la copia, reproducción, adaptación, modificación, distribución, comercialización, comunicación pública y/o cualquier otra acción que genera una infracción de la legislación Mexicana o internacional vigente en materia de propiedad intelectual y/o industrial, así como el uso de los contenidos de la Aplicación sin previa autorización expresa y por escrito de BOOKSNAP.&nbsp;</p>
                        <h2>10.- Otros</h2>
                        <p>10.1. La aceptación y vinculación, por los Usuarios, a los términos de este instrumento se hará mediante un clic en el ícono "Acepto”.</p>
                        <p>10.2. Cualquier duda sobre el tenor de las disposiciones aquí previstas, podrá ser aclarada en nuestro Servicio de Atención al Cliente de la Aplicación, por medio de envío de correo electrónico a la dirección (contacto@booksnap.co).</p>
                        <p>10.3. Al momento de descargar la Aplicación o visitar alguno de nuestros Medios nosotros estaremos recibiendo un correo electrónico de su parte lo que nos faculta a continuar con dicho medio de comunicación y por lo tanto el Usuario acepta recibir correos electrónicos por nuestra parte. Nos comunicaremos con Usted mediante los siguientes medios: correo electrónico, avisos en la Aplicación y/o los demás Medios, o por medio del Boletín Informativo del Servicio. Usted acepta que todos los convenios, avisos, divulgaciones, correos, publicidad y otros comunicados que le enviemos vía electrónica satisfacen los requerimientos legales de que tales comunicados están por escrito.</p>
                        <p>10.4. En el caso de que cualquier cláusula de estos Términos y Condiciones de Uso de la Aplicación y los Medios Electrónicos sea declarada nula, las demás cláusulas permanecerán vigentes y serán interpretadas llevándose en consideración la voluntad de las partes y la finalidad de las mismas presentes en el referido término.&nbsp;</p>
                        <p>10.5. BOOKSNAP tendrá la facultad sobre si ejecutar los derechos conferidos en&nbsp; el presente instrumento, si decidiese no ejercerlos ello no implica la renuncia o pérdida de los mismos, esta solo se dará mediante renuncia expresa por parte de BOOKSNAP o por prescripción negativa declarada por autoridad judicial.</p>
                        <p>10.6. BOOKSNAP se reserva el derecho de modificar en cualquier momento, la presentación y configuración de la Aplicación, así como los presentes Términos y Condiciones de Uso. Por eso, BOOKSNAP recomienda al Usuario lea atentamente dichos Términos y Condiciones de Uso cada vez que acceda al Servicio. En cualquier caso, la aceptación de los Términos y Condiciones de Uso será un paso previo e indispensable para la obtención de recompensas inherentes al Servicio.&nbsp;</p>
                        <p>10.7. Los presentes Términos y Condiciones de Uso se rigen en todos y cada uno de sus partes por las leyes aplicables de los Estados Unidos Mexicanos. Por su parte, BOOKSNAP y el Usuario, renuncian expresamente a la jurisdicción de cualquier otro fuero, y se someten a la jurisdicción de los tribunales competentes de la Ciudad de México.
                        </p>
                        <p> Ciudad de México, a 21 de Junio de 2018.</p>
                    </div>
                </div>
        )
    }
}

export default Terminos;