import React,{Component} from "react";
import editIcon from "../../assets/icon-edit-lomo@3x.png";
import previewIcon from "../../assets/icon-view-lomo@3x.png";

import {connect} from "react-redux";
import { getDataAut } from "../../util/general";
import {guardarLomo} from "../../util/asyncVolumenes";
import ModalSpinner from "../modales/ModalSpinner";
import ModalesError from "../ModalesError";
import PreviewLomo from "./PreviewLomo";
import {ERROR_GUARDAR_LOMO} from "../../util/errores";
import Button from "react-bootstrap/Button";
class EdicionLomo extends Component{
    constructor(props){
        super(props);
        this.state = {
            textoLomo: "",
            editando:false,
            mostrarPreviewLomo:false
        }
    }
    mostrarPreviewLomo = ()=>{
        this.setState({
            mostrarPreviewLomo:true
        })
    }
    cerrarPreviewLomo = () =>{
        this.setState({
            mostrarPreviewLomo:false
        })
    }
    getModalesError = ()=>{
        let errores = [];
        errores.push(ERROR_GUARDAR_LOMO(this.props.error === "GUARDAR_LOMO",
        ()=>{
            this.setState({textoLomo: this.props.textoLomo});
            this.props.dispatch({type:"ERROR_APP",valor: "LIMPIAR_ERRORES_APP"});
            
        }));
        return errores;
    }
    componentDidMount(){
        this.setState({textoLomo: this.props.textoLomo});
    }
    clickEditar = () => {
        this.setState({editando:true});
    }
    guardarLomo = ()=>{
        let dataAut = getDataAut(this.props.token, this.props.uuid);
        this.props.dispatch(guardarLomo(dataAut,this.props.volId,this.state.textoLomo,()=>{
            this.setState({
                editando:false,
                mostrarPreviewLomo:false
            })
        }));
    }
    handleChange=(ev)=>{
        this.setState({textoLomo:ev.target.value.slice(0,32)})
    }
    cancelar = ()=>{
        this.setState({textoLomo:this.props.textoLomo,editando:false})
    }
    render(){
        return <div>
                
                <div className = "texto-lomo">
                <ModalSpinner show = {this.props.cargando}/>
                <ModalesError modales = {this.getModalesError()}/>
                
                
                    <PreviewLomo 
                        cerrarPreviewLomo = {this.cerrarPreviewLomo} 
                        showPreviewLomo = {this.state.mostrarPreviewLomo}
                        textoLomo = {this.state.textoLomo}
                        nombreProy = {this.props.nombre}/>
                    
                    <div className = "texto">TEXTO EN LOMO</div>
                    <div className ="contIconos">
                        
                        <img src = {previewIcon} alt ="preview lomo" onClick = {this.mostrarPreviewLomo}
                            className = "icon"/>
                         
                    
                    </div>
                </div>
                {this.state.editando?<div className ="cont-edicion">
                    <div className="form-group ">
                        <input type = "text" className="form-control"  onChange = {this.handleChange} value = {this.state.textoLomo}/>
                    </div>

                   
                    <div className ="cont-botones">
                    <Button className ="btn btn-block"
                        size = "sm" onClick ={this.guardarLomo}>GUARDAR </Button>
                    <Button className ="btn btn-block"
                        size ="sm" onClick ={this.cancelar}>CANCELAR </Button>
                    </div>
                    </div>
                :
                    <div className="form-group ">
                        <input type = "text" className="form-control" onClick = {this.clickEditar}  value = {this.state.textoLomo}/>
                    </div>
                }
                
        </div>
    }
}
const mapStateToProps = (state)=>{
    return {
        token: state.clientes.token,
        uuid: state.clientes.uuid,
        error: state.app.error,
        cargando: state.app.cargando,
        nombre: state.proyectos.nombre
    }
}
export default connect(mapStateToProps)(EdicionLomo);