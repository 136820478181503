const estadoInicial = {
    errores:"",
    cargando:true,
    proyectos:[],
    proyectoSeleccionado:null,
    nombre:"",
    tipoPasta:"suave",
    mostrarCaption:false,
    mostrarFecha:false,
    mostrarUbicacion:false,
    volumenes:[],
    cuentaFotos:0,
    alertar: false,
    alerta:"",
    modoBorradoMasivo: false,
    fotosPorBorrar:[],
    hasTematico:false,
    volumenSeleccionado:null,
    codigoError:"",
    numVolumenSeleccionado:null

}
 const proyectosReducer = (state = estadoInicial, action) => {
    let res = {};
    switch(action.type){  
        case "TOGGLE_POR_BORRAR":
            let temp = state.fotosPorBorrar.slice();
            if(temp.includes(action.id)){                
                temp = temp.filter(el=>el!==action.id)
            }
            else{
                temp.push(action.id);
            }
            res = Object.assign({},state,{fotosPorBorrar:temp});
            return res;
        case "ENTRAR_MODO_BORRADO_MASIVO":
                res = Object.assign({},state,{modoBorradoMasivo:true})
                return res;    
        case "SALIR_MODO_BORRADO_MASIVO":
            res = Object.assign({},state,{modoBorradoMasivo:false,fotosPorBorrar:[]})
            return res;    
        case "TOGGLEAR_PORTADA_TEMATICA":
            res = Object.assign({},state,{hasTematico:!state.hasTematico});
            return res; 
        case "REGISTRAR_PROYECTOS":
            res = Object.assign({},state,{proyectos:action.proyectos})
            return res;
        case "RETIRAR_PROYECTO":
            let proy = state.proyectos.map(proy=>Object.assign({},proy)).filter(proy=>proy.id!==action.valor);
            res = Object.assign({},state,{proyectos:proy})
            return res;
        case "CARGANDO_PROYECTOS":
            res = Object.assign({},state,{cargando:true});
            return res;
        case "CARGADO_PROYECTOS":
            res = Object.assign({},state,{cargando:false});
            return res;
        case "SELECCIONAR_PROYECTO":            
            res = Object.assign({},state,{proyectoSeleccionado:action.id,nombre: action.nombre,volumenes:[]});
            return res;
        case "SELECCIONAR_PASTA":
            res = Object.assign({},state,{tipoPasta: action.tipoPasta})
            return res;
        case "CREANDO_PROYECTO":
            res = Object.assign({},state,{proyectoSeleccionado:null,nombre:""})
            return res;
        case "EDITAR_NOMBRE":
            res = Object.assign({},state,{nombre:action.nombre})
            return res;            
        case "TOGGLE_TIPO_PASTA":
            let tipoPasta ="suave";
            if(state.tipoPasta == "suave")  
                tipoPasta = "dura";
            res = Object.assign({},state,{tipoPasta:tipoPasta})
            return res;
        case "TOGGLE_MOSTRAR_FECHA":
            res = Object.assign({},state,{mostrarFecha:toglear(state.mostrarFecha)})
            return res;
        case "TOGGLE_MOSTRAR_CAPTION":
            res = Object.assign({},state,{mostrarCaption:toglear(state.mostrarCaption)})
            return res;
        case "TOGGLE_MOSTRAR_UBICACION":
            res = Object.assign({},state,{mostrarUbicacion:toglear(state.mostrarUbicacion)})
            return res;
        case "ERROR_PROYECTO":
            res = Object.assign({},state,{errores:action.valor,codigoError:action.codigoError});
            return res;
        case "ERROR_PROYECTO_INDEF":
            res = Object.assign({},state,{errores:"ERROR_INDEF"})
            return res;
        
        case "LIMPIAR_ERRORES_PROYECTO":
            res = Object.assign({},state,{errores:""})
            return res;
        case "EDITAR_CONFIGURACION":
            res = Object.assign({},state,{
                nombre: action.nombre,
                mostrarCaption: action.mostrarCaption,
                mostrarFecha: action.mostrarFecha,
                mostrarUbicacion: action.mostrarUbicacion,
                tipoPasta: action.tipoPasta,
                hasTematico:action.hasTematico
            })
            return res;
        case "ACTUALIZAR_VOLUMENES":
            res = Object.assign({},state,{volumenes: action.volumenes, cuentaFotos: action.cuentaFotos});
            return res;
        case "ALERTAR_PROY":

            res = Object.assign({},state,{alertar: true,alerta:action.valor});
            return res;
        case "LIMPIAR_ALERTA_PROY":
            res = Object.assign({},state,{alertar:false});
            return res;
        case "SELECCIONAR_VOLUMEN":
            res = Object.assign({},state,{volumenSeleccionado:action.volId});
            return res;
        case "SELECCIONAR_NUM_VOLUMEN":
            res = Object.assign({},state,{numVolumenSeleccionado:action.volNum});
            return res;
        default:
        return state;
    }
}
function toglear(valor){
    return !valor;
}
export default proyectosReducer;