import React, {Component} from "react";
import ModalError from "./ModalError";
class ModalesError extends Component{
    render(){

        return this.props.modales.map((modal,index)=>{
            return<ModalError key ={`modalError_${index}`}
                show={modal.show}
                handleClose = {modal.handleClose}
                textoBoton = {modal.textoBoton}
                titulo = {modal.titulo}
                mensaje = {modal.mensaje}
                codigoError = {modal.codigoError}
            />
        });
    }
}

export default ModalesError;