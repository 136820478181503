import React,{Component} from "react";
import FormularioPago from "./FormularioPago";
import {injectStripe} from "react-stripe-elements";
import { connect } from "react-redux";
import {aplicarTipoEnvio,aplicarDescuento,ordenar, obtenerResumenCompra} from "../../util/asyncCarrito";
import {cargarDatosPagoPrevio} from "../../util/asyncCarrito";
import Alert from "react-bootstrap/Alert";
import { esDefinido } from "../../util/general";
const  NOMBRE_DESTINATARIO_MAX_LENGTH = 35;
const CIUDAD_MAX_LENGTH = 15;
const TELEFONO_MAX_LENGTH = 15;
export const DIRECCION_MAX_LENGTH = 35;
export const COLONIA_MAX_LENGTH = 35;
export const CODIGO_POSTAL_MAX_LENGTH = 5;
export const  ALLOWED_CHARACTERS_REGEX = /[^A-Za-z0-9ÑñÁÉÍÓÚáéíóú ´,.]/gi;
export const NUMBERS_REGEX = /[^0-9]/gi;
export const estados =  ["Aguascalientes", "Baja California", "Baja California Sur", 
    "Campeche", "Chihuahua", "Chiapas", "Ciudad de México", 
    "Coahuila", "Colima", "Durango", "Guanajuato", 
    "Guerrero", "Hidalgo", "Jalisco", "Estado de México", 
    "Michoacán", "Morelos", "Nayarit", "Nuevo León", 
    "Oaxaca", "Puebla", "Querétaro", "Quintana Roo", 
    "San Luis Potosí", "Sinaloa", "Sonora", "Tabasco", 
    "Tamaulipas", "Tlaxcala", "Veracruz", "Yucatán", "Zacatecas"]
class FormularioEnvioPago extends Component{
    constructor(props){
        super(props);
        this.state = {
            destinatario: "",
            direccion:"",
            direccion2:"",
            ciudad:"",
            estado:estados[0],
            codigoPostal:"",
            telefono:"",
            tipoEnvio:"estandar",
            codigoDescuento:"",
            errores: [],
            codigoPostalCard:"",
            titular:""
        }
        //this.props.dispatch(aplicarTipoEnvio({token:this.props.token}, "estandar",()=>{}));
        //this.props.dispatch(aplicarDescuento({token:this.props.token},""))  
        this.props.dispatch(obtenerResumenCompra({token:this.props.token},"","estandar", ()=>{}));    
    }
    componentDidMount(){
        
        
        this.cargarDatosPagoPrevio();
    }
    cargarDatosPagoPrevio(){
        let dataAut = {token:this.props.token};        
        this.props.dispatch(cargarDatosPagoPrevio(dataAut,(datos)=>{
            this.setState({
                titular: datos.titular,
                destinatario: datos.destinatario,
                direccion: datos.direccion,
                direccion2:datos.direccion2,
                ciudad: datos.ciudad,
                estado: datos.estado,
                codigoPostal: datos.codigoPostal,
                telefono: datos.telefono,
                tipoEnvio: datos.tipoEnvio,
            });
        }));
    };
    ordenar = (token,erroresPago)=>{          
        this.setState({errores:[]})
        let errores = this.validar().concat(erroresPago);        
        if(errores.length > 0){        
            this.setState({errores:errores});            
            return;
        }
        let dataAut = {token:this.props.token};
        
        this.props.dispatch(ordenar(dataAut,Object.assign({},this.state),token.token.id,this.props.email));

    }
    handleTipoEnvio = (ev)=>{
        let dataAut = {token:this.props.token}
        let tipoEnvio = this.state.tipoEnvio === "estandar"?"express":"estandar";
        //this.props.dispatch(aplicarTipoEnvio(dataAut,tipoEnvio,()=>this.setState({tipoEnvio:tipoEnvio })));
        this.props.dispatch(obtenerResumenCompra(dataAut,this.state.codigoDescuento,tipoEnvio,()=>this.setState({tipoEnvio:tipoEnvio })));
    }
    validar = ()=>{
        let campos = [{id:"destinatario", aTexto:"Nombre del destinatario"},
            {id:"direccion", aTexto:"Línea de dirección 1"},
            {id:"direccion2", aTexto: "Línea de Dirección 2"},
            {id:"ciudad", aTexto:"Ciudad"},
            {id:"Estado", aTexto:"Estado"},
            {id:"codigoPostal", aTexto:"Código Postal"},
            {id:"telefono", aTexto:"Teléfono Móvil"}
            ]
        let errores = campos.map(campo=>{
            if(this.state[campo.id]==="" && campo.id !="direccion2"){
                let temp = {};
                temp[campo.id] = `${campo.aTexto} está vacío`;
                return temp;
            }
            else {
                return null
            }
        })
        errores =  errores.filter(el=>el !== null);
        return errores;
    }
    handleChange =  (target,valor)=>{
        let texto = valor.replace(ALLOWED_CHARACTERS_REGEX,"");
        if(target === "destinatario"){
            texto = texto.slice(0,NOMBRE_DESTINATARIO_MAX_LENGTH);
        }
        if(target === "direccion"){
            texto = texto = texto.slice(0,DIRECCION_MAX_LENGTH);            
        }        
        if(target === "direccion2"){
            texto = texto = texto.slice(0,COLONIA_MAX_LENGTH);            
        }        
        if(target === "ciudad"){
            texto = texto = texto.slice(0,CIUDAD_MAX_LENGTH);
        }
        if(target === "codigoPostal"){
            texto = texto.replace(NUMBERS_REGEX,"");
            texto = texto.slice(0,CODIGO_POSTAL_MAX_LENGTH);
        }
        if(target === "telefono"){
            texto = texto.slice(0,TELEFONO_MAX_LENGTH);            
        }
        if(target ==="codigoPostalCard"){
            texto = texto.replace(NUMBERS_REGEX,"");
            texto = texto.slice(0,CODIGO_POSTAL_MAX_LENGTH);
        }
        
        let copia = Object.assign({},this.state);
        copia[target] = texto;
        this.setState(copia);
    }
    aplicarDescuento = ()=>{
        let dataAut = {token:this.props.token};
        let tipoEnvio = this.state.tipoEnvio === "estandar"?"estandar":"express";
        this.props.dispatch(obtenerResumenCompra(dataAut,this.state.codigoDescuento,tipoEnvio,()=>{}));
    }
    obtenerCostoFinal = ()=>{
        console.log("OBTENER COSTO FINAL")
        console.log(this.props.total);
        console.log(this.props.porcentajeDescuento);
        console.log(this.state.tipoEnvio);
        console.log(this.props.montoDescuento);
        console.log(this.props.descuento);
        console.log(this.props.costoEnvio);
        if(this.state.tipoEnvio === "estandar" && this.props.tipoDescuento=== "MONTO" && this.props.total >=500){
            return this.props.total-this.props.descuento;
        }
        return this.props.total+ this.props.costoEnvio - this.props.descuento;
    }
    componentWillUnmount(){
        
    }
    alertasErrores = ()=>{
       return  this.state.errores.map((error,index)=>{
            return (<Alert variant = "warning" key = {"alerta_"+index}>{error}</Alert>)
        })
    }
    submit = async()=>{
        let erroresExpiracion = ["incomplete_expiry","invalid_expiry_year_past","invalid_exp"]
        let erroresNumTarjeta = ["incomplete_number","invalid_number","incorrect_number"];
        let erroresCVC = ["incomplete_cvc"]
        let errores = this.validarPago(this.state.titular, this.state.codigoPostalCard);
        let token = {token:{id:""}};
        if(this.obtenerCostoFinal()>0){
            token = await this.props.stripe.createToken({name:this.state.titular,address_zip:this.state.codigoPostalCard});
        }
        if(token.error){
            if(erroresNumTarjeta.includes(token.error.code)){
                errores.push({"numeroTarjeta":token.error.message});
            }
            if(erroresExpiracion.includes(token.error.code) ){
                errores.push({"expiracion":token.error.message});
            }
            if(erroresCVC.includes(token.error.code)){
                errores.push({"cvc":token.error.message});
            }        
        }
        this.ordenar(token,errores);
      }
    validarPago = (titular,codigoPostal)=>{
        let errores = [];
        if(this.obtenerCostoFinal()==0){
            return errores;
        }
        if(titular === ""){
            errores.push({titular:"Nombre Del Titular Está vacío"});
        }
        if(codigoPostal===""){
            errores.push({codigoPostalCard:"Código Postal De Tarjeta está vacío"});
        }
        return errores;
    }
    selectEstados = ()=>{
        return (
        <select className="form-control"  onChange = {(ev)=>this.handleChange("estado",ev.target.value)} value = {this.state.estado}>
            {estados.map((edo,index)=>{
                return (<option key = {"estado_" + index} value ={edo} >{edo}</option>)
            })}
        </select>)
        
    }
    alertaErroresPor = (campo)=>{
        let busqueda = this.state.errores.find(actual=>{
            if(esDefinido(actual[campo])){
                return true;
            }
        })
        if(esDefinido(busqueda)){
            return <Alert variant = "warning">{busqueda[campo]}</Alert>
        }
    }
    render(){
        let totalSinDescuento = this.props.total + this.props.costoEnvio;
        return (<div className="inner-content">

        <div className="carrito-total">            
            <span>TOTAL</span> <span>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(this.obtenerCostoFinal())}</span>
        </div>
        <div className="row">
         <div className="col-md-6">
            <div className="form-title">DIRECCIÓN DE ENVÍO</div>

           
            <div className="form-group">
                <label>NOMBRE DE DESTINATARIO</label>
                <input className="form-control" type = "text" value = {this.state.destinatario} onChange = {(ev)=>this.handleChange("destinatario",ev.target.value)}/>
                {this.alertaErroresPor("destinatario")}
            </div>
            <div className="form-group">
                <label>DIRECCIÓN</label>
                <input className="form-control" type = "text" value = {this.state.direccion} onChange = {(ev)=>this.handleChange("direccion",ev.target.value)}/>
                {this.alertaErroresPor("direccion")}
            </div>
            <div className="form-group">
                <label>COLONIA</label>
                <input className="form-control" type = "text" value = {this.state.direccion2} onChange = {(ev)=>this.handleChange("direccion2",ev.target.value)}/>
                {this.alertaErroresPor("direccion2")}
            </div>
            <div className="form-group">
                <label>CIUDAD</label>
                <input className="form-control" type = "text" value = {this.state.ciudad} onChange = {(ev)=>this.handleChange("ciudad",ev.target.value)}/>
                {this.alertaErroresPor("ciudad")}
            </div>
            <div className="form-group">
                <label>ESTADO</label>
                {this.selectEstados()}
            </div>
            <div className="form-group">
                <label>CÓDIGO POSTAL</label>
                <input className="form-control" type = "text" value = {this.state.codigoPostal} onChange = {(ev)=>this.handleChange("codigoPostal",ev.target.value)}/>
                {this.alertaErroresPor("codigoPostal")}
            </div>
            <div className="form-group">
                <label>TELÉFONO MÓVIL</label>
                <input className="form-control" type = "text" value = {this.state.telefono} onChange = {(ev)=>this.handleChange("telefono",ev.target.value)}/>
                {this.alertaErroresPor("telefono")}
            </div>
        </div>
         <div className="col-md-6">

            <div className="form-title">TIPO DE ENVÍO</div>


            
            <div className="form-group radio">

                <label className="radio-container">
                    <strong>ENVÍO ESTÁNDAR (+${(this.state.tipoEnvio === "estandar" && this.props.tipoDescuento=== "MONTO" && this.props.total >=500)||this.props.total - this.props.descuento >= 500?"0":"50"})</strong> 8-10 DÍAS HÁBILES
                  <input type = "radio" name="envio" value = "estandar" checked ={this.state.tipoEnvio ==="estandar"} 
                    onChange = {this.handleTipoEnvio}></input>
                  <span className="checkmark"></span>
                </label>
            </div>

            <div className="form-group radio">

                <label className="radio-container">
                    <strong>ENVÍO EXPRESS (+$80)</strong> 6-8 DÍAS HÁBILES
                  <input   type = "radio" name="envio" value = "express" checked ={this.state.tipoEnvio ==="express"}
                    onChange = {this.handleTipoEnvio}></input>

                  <span className="checkmark"></span>
                </label>

                
            </div>
            
            <FormularioPago handleChange = {this.handleChange}
                titular = {this.state.titular} 
                codigoPostalCard = {this.state.codigoPostalCard}

                alertaErroresPor = {this.alertaErroresPor}/>
            <div className="form-group">
                <label>CÓDIGO DE DESCUENTO</label>
                <div className="row">
                <div className="col">
                <input className=" form-control" type="text" value = {this.state.codigoDescuento} onChange = {(ev)=>this.handleChange("codigoDescuento",ev.target.value)}/>
                </div>
                <div className="col-auto">

                <button className="btn btn-secondary" onClick = {this.aplicarDescuento}>APLICAR</button>
                </div>
                </div>
            </div>
        </div>
        </div>
        <a className="btn btn-primary btn-general" onClick={this.submit}>REALIZAR PAGO</a>
        


        </div>
)
    }
}   
const mapStateToProps = (state)=>{
    return {
        total: state.carrito.total,
        token: state.clientes.token,
        costoEnvio: state.carrito.costoEnvio,
        porcentajeDescuento: state.carrito.porcentajeDescuento,
        email: state.clientes.email,
        montoDescuento: state.carrito.montoDescuento,
        tipoDescuento: state.carrito.tipoDescuento,
        descuento: state.carrito.descuento
    }
}
export default injectStripe(connect(mapStateToProps)(FormularioEnvioPago));