import {reqCargarConfiguracion,reqGuardarConfiguracion,reqPrevisualizarProyecto,
    reqEliminarProyecto,reqAgregarCarrito,
    reqTogglearPortadaTematica,reqSeleccionarPortada
} from "./proyectos";
import {clearBuckets,procesarArrayBufferParaPortada} from "./images";
import {firebaseFunctionBajarPortada} from "./firebaseFunctions";

    const SinTitulo = "Sin título";
export const cargarConfiguracion = (dataAut,proy,CB)=>{
    return function(dispatch,getState){
        dispatch({type:"CARGANDO_PROYECTOS"});                                
        reqCargarConfiguracion(dataAut,proy).then(resp=>{
            CB();
            dispatch({ 
                type: "EDITAR_CONFIGURACION",
                mostrarUbicacion: resp.data.mostrar_ubicacion,
                mostrarFecha: resp.data.mostrar_fecha,
                mostrarCaption: resp.data.mostrar_caption,
                hasTematico: resp.data.has_tematico,
                nombre: resp.data.nombre===SinTitulo?"":resp.data.nombre,
                tipoPasta: resp.data.pasta
            });
            dispatch({type:"CARGADO_PROYECTOS"});
        }).catch(err=>{
            dispatch({type:"ERROR_PROYECTO",valor: "ERROR_CARGAR_CONFIG"});
        })
    }
}
export const guardarConfiguracion = (dataAut,proy,config,history)=>{
    return function(dispatch,getState){
        reqGuardarConfiguracion(dataAut,proy,config).then(resp=>{
            history.push("/proyectos/editar")
        }).catch(err=>{
            dispatch({type:"ERROR_PROYECTO",valor: "ERROR"});
        })
    }
}
export const previsualizarProyecto = (dataAut,proy)=>{
    return function(dispatch,getState){
        reqPrevisualizarProyecto(dataAut,proy).then(resp=>{
            dispatch({type:"CARGANDO_FOTOS"});
            dispatch({type:"ACTUALIZAR_VOLUMENES",cuentaFotos:resp.data.cantidad_fotos,volumenes: resp.data.volumenes})
        }).catch(err=>{
            dispatch({type:"ERROR_PROYECTO", valor:"ERROR"});
        }).finally(()=>{
            dispatch({type:"CARGADO_FOTOS"});
        });
    }
}

export const eliminarProyecto = (dataAut,proy,history)=>{
    return function(dispatch,getState){
        reqEliminarProyecto(dataAut,proy).then(resp=>{                        
            history.push("/proyectos");
            dispatch({type:"RETIRAR_PROYECTO", valor:proy});
        }).catch(err=>{
            dispatch({type:"ERROR_PROYECTO",valor: "ELIMINAR_PROYECTO"});
        })
    }
}
export const agregarCarrito = (dataAut,proy,history)=>{
    return function(dispatch,getState){
        dispatch({type:"LIMPIAR_ALERTA_PROY"});
        reqAgregarCarrito(dataAut,proy).then(resp=>{
            history.push("/carrito");
        }).catch(err=>{
            dispatch({ type: "ERROR_PROYECTO" , valor: "AGREGAR_CARRITO" });
        })
    }
}

export const togglearPortadaTematica = (dataAut,proy, actual)=>{
    return function(dispatch,getState){
        reqTogglearPortadaTematica(dataAut,proy,actual).then(resp=>{                        
            dispatch({type:"TOGGLEAR_PORTADA_TEMATICA"});
        }).catch(err=>{
            
            dispatch({type:"ERROR_PROYECTO",valor: "TOGGLEAR_PORTADA_TEMATICA"});
        })
    }
}
 export const asignarPortadaGooglePhotos = (dataAut,volNum, proyId, datosFoto,cb)=>{
    return function(dispatch,getState){
        dispatch({type:"CARGANDO_FOTOS"});
        firebaseFunctionBajarPortada(datosFoto.src).then(resp=>{

            return procesarArrayBufferParaPortada(resp.data,datosFoto)
        }).then(resp=>{             
            return reqSeleccionarPortada(dataAut,proyId,volNum,resp)
        }).then(resp=>{
            
            clearBuckets(["portadasVol","portadasProy"]);

            cb();
            //navegar a donde toca
        }).catch(err=>{
            dispatch({type:"ERROR_FOTO", valor:"SELECCION_PORTADA"});
            console.log(err);
        }).finally(()=>{
            dispatch({type:"CARGADO_FOTOS"});
        })
    }
 }