import React,{Component} from "react";
import Navegacion from "../Navegacion";
import {recuperarContrasena} from "../../util/asyncCliente";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import ModalesError from "../ModalesError";
import Modal from "react-bootstrap/Modal";

import {ERROR_RECUPERAR_CONTRASENA, ERROR_CORREO_NO_ENCONTRADO} from "../../util/errores";
class RecuperarContrasena extends Component{
    constructor(props){
        super(props);
        this.state = {email:"",valido:true}
    }
    aceptarEnviado = ()=>{
        let previous= this.props.navegable?"/login":"/landing/login/";
        let to = {pathname: previous, state: { prevPath: this.props.location }};
        this.props.dispatch({type:"ACEPTAR_ENVIADO"});
        this.props.history.push(to);
    }
    enviar = ()=>{
        let that = this;
        let previous= "/login";
        if(this.props.location.pathname.startsWith("/landing")){
            previous="/landing/login";
        }
        let to = {pathname: previous, state: { prevPath: this.props.location }};
        if(esEmail(this.state.email)){
            this.setState({valido:true});
            this.props.dispatch(recuperarContrasena(this.state.email,
                this.props.history,to));
        }else{
            this.setState({valido:false});
        }
    }
    handleEmail = (ev)=>{
        this.setState({email:ev.target.value});
    }
    getModales = ()=>{
        let errores = [];
        errores.push(ERROR_RECUPERAR_CONTRASENA(this.props.errores === "RECUPERAR_CONTRASENA",
           ()=> this.props.dispatch({type:"LIMPIAR_ERRORES_CLIENTE"})));
        errores.push(ERROR_CORREO_NO_ENCONTRADO(this.props.errores === "CORREO_NO_ENCONTRADO",
            ()=>this.props.dispatch({type:"LIMPIAR_ERRORES_CLIENTE"})))
        return errores;
    }
    atras = ()=>{
        this.props.history.goBack();
    }
    render(){
        return(

        <div id = "inner-content-wrapper" className = {this.props.navegable?"d-flex flex-column":" d-flex flex-column noNav"}>
            <Modal show = {this.props.mostrarEnviadoEmailRecuperacion}>
                <Modal.Header>
                    <Modal.Title>
                    Recuperación de Contraseña
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Te hemos enviado un correo electrónico con las instrucciones para recuperar tu contraseña.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick = {this.aceptarEnviado}>ACEPTAR</Button>               
                </Modal.Footer>
            </Modal>
            <ModalesError modales = {this.getModales()}/>
            {
                this.props.navegable?<Navegacion/>
            :
                null
            }
            <Navegacion/>
                    <div className="title-bar">
                        <span className="back-icon" onClick = {this.atras}> </span> 
                        RECUPERAR CONTRASEÑA 
                    </div>
                    <div className=" inner-content login d-flex flex-column flex-fill justify-content-center align-items-center">
                    
                    
                    <div className="form-wrapper">
                        <div className="form-group">      

                            <label   htmlFor = "email">CORREO ELECTRÓNICO</label>
                            <input className="form-control" type = "text" value = {this.state.email}
                                onChange = {this.handleEmail}/>
                            {this.state.valido?null:
                            <Alert variant = "danger">Dirección de correo inválida</Alert>}
                        </div>
                    <Button className='btn  btn-big btn-primary btn-block'  onClick = {this.enviar}>ENVIAR</Button>

                    </div>
            </div>
        </div>)
    }
}
function esEmail(string){
    return (/^.+@([^.\s]+\.{1})+[^.\s]+$/).test(string)
}
const mapStateToProps = (state)=>{
    return {
        errores: state.clientes.errores,
        mostrarEnviadoEmailRecuperacion:state.clientes.mostrarEnviadoEmailRecuperacion
    }
}
export default connect(mapStateToProps)(RecuperarContrasena);