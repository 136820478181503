import React, {Component} from "react";
import Gallery from 'react-grid-gallery';
import { render } from 'react-dom';
import { esDefinido } from "../../../util/general";
class GridFotos extends Component{
    constructor(props){
      super(props);  
      this.state = {lastSelected:null}
    }
    
    clickedThumbnail = (index,ev)=>{
        if(this.props.tipoSeleccion === "PORTADA"){
            let foto =this.props.fotos[index];
            let datos = {
                id:foto.id,
                anchoOriginal:foto.anchOriginal,
                altoOriginal: foto.altOriginal,
                src: foto.src
            }
            this.props.asignarPortadaGooglePhotos(datos);
            return;
        }
        if(ev.shiftKey && esDefinido(this.state.lastSelected) && !this.props.fotos[index].isSelected){
            this.props.seleccionMultiple(this.state.lastSelected , index)
            this.setState({lastSelected:index});
        }else{
            if(!this.props.fotos[index].isSelected){
                this.setState({lastSelected:index});
            }
            this.props.toggleSelect(index);
            
        }
        
        
    }
    render(){
        
        
            return  (
                <div >
                    <div className=" ml-1 d-flex align-items-center"  onClick = {this.props.volverAlbumes}>
                        <span className="back-icon"> </span>
                        <span className="linkstyle">Volver a Álbumes</span>
                    </div>
                    <Gallery 
                    enableLightBox= {false}
                    onClickThumbnail = {
                        this.clickedThumbnail}
                    onSelectImage = {this.clickedThumbnail}
                    images = {this.props.fotos}/>
                </div>
            )

    }
}

export default GridFotos;