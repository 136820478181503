import { esDefinido } from "../util/general";

const estadoInicial = {
    errores:"",
    cargando:false,
    carrito:[],
    total:0,
    descuento:0,
    confirmando:false,
    mensaje:"",
    comprando:false,
    costoEnvio:0,
    porcentajeDescuento:0,
    montoDescuento:0,
    pagando:false,
    pagado:false,
    requerirRegistrado:false,
    tipoDescuento:"PORCENTAJE",
    detalle:""
}

const carritoReducer = (state = estadoInicial, action) => {
    let res = {};
    let carrito = [];
    switch(action.type){       
        case "CARGANDO_CARRITO":
            res = Object.assign({},state,{cargando:true})
            return res;
        case "CARGADO_CARRITO":
            res = Object.assign({},state,{cargando:false})
            return res;
        case "CONFIRMANDO":
            res = Object.assign({},state,{confirmando:true,mensaje:action.valor})
            return res;
        case "CONFIRMADO":
            res = Object.assign({},state,{confirmando:false,mensaje:""})
            return res;
        case "LIMPIAR_ERRORES_CARRITO":
            res = Object.assign({},state,{errores:""})
            return res;
        case "ACTUALIZAR_CARRITO":
            res = Object.assign({},state,{carrito: action.carrito,total:action.total})
            return res;
        case "ACTUALIZAR_TOTALES":
            carrito = copiarCarrito(state.carrito);
            actualizarTotal(carrito,action.volId,action.totalVol);
            res = Object.assign({},state,{carrito: carrito,total:action.total})
            return res;
        case "ERROR_CARRITO":
            res = Object.assign({},state,{errores: action.valor,detalle:action.detalle});
            return res;
        
        case "AUMENTAR_CANTIDAD":
             carrito = copiarCarrito(state.carrito);
            aumentarVolumen(action.volId,carrito);            
            res = Object.assign({},state,{carrito:carrito});
            return res;
        case "DISMINUIR_CANTIDAD":
            carrito = copiarCarrito(state.carrito);
            disminuirVolumen(action.volId,carrito);            
            res = Object.assign({},state,{carrito:carrito});
            return res;
        case "ELIMINAR_PROYECTO_CARRITO":
            carrito = copiarCarrito(state.carrito);
            carrito = carrito.filter(proy=>proy.id !== action.proyId);
            res = Object.assign({},state,{carrito:carrito,total:action.total});
            return res;
        case "COMPRANDO":
            res = Object.assign({},state,{comprando:true});
            return res;
        case "COMPRADO":
            res = Object.assign({},state,{comprando:false});
            return res;
        case "ACTUALIZAR_COSTO_ENVIO":  
            res = Object.assign({},state,{costoEnvio:action.valor});
            return res;
        case "ACTUALIZAR_PORCENTAJE_DESCUENTO": 
            res = Object.assign({},state,{porcentajeDescuento:action.valor});
            return res;
        case "ACTUALIZAR_MONTO_DESCONTADO": 
            res = Object.assign({},state,{montoDescuento:action.valor});
            return res;
        case "ACTUALIZAR_TIPO_DESCUENTO": 
            res = Object.assign({},state,{tipoDescuento:action.valor});
            return res;
        case "ACTUALIZAR_DESCUENTO": 
            res = Object.assign({},state,{descuento:action.valor});
            return res;
        case "ACTUALIZAR_DATOS_PAGO_PREVIO": 
            res = Object.assign({},state,{
                titular: action.datos.titular,
                destinatario: action.datos.destinatario,
                direccion: action.datos.direccion,
                ciudad: action.datos.ciudad,
                estado: action.datos.estado,
                codigoPostal: action.datos.codigoPostal,
                telefono: action.datos.telefono,
                tipoEnvio: action.datos.tipoEnvio
            });
            return res;
        case "COMPRA_EXITOSA":
            res = Object.assign({},state,{pagado:true});
            return res;
        case "PAGANDO":
            res = Object.assign({},state,{pagando:true});
            return res;
        case "SALIR_PAGO":
            res = Object.assign({},state,{pagando:false,pagado:false,comprando:false});
            return res;
        case "REQUERIR_REGISTRADO":
            res = Object.assign({},state,{requerirRegistrado:true});
            return res;
        case "LIMPIAR_REQUERIR_REGISTRADO":
            res = Object.assign({},state,{requerirRegistrado:false});
            return res;
        default:
            return state;
    }
}

function copiarCarrito(carrito){
    return carrito.map(proy=>{        
        let vols = proy.volumenes.map(vol=>Object.assign({},vol));
        let nuevoProy = Object.assign({},proy);
        nuevoProy.volumenes = vols;
        return nuevoProy;
    })
}
function aumentarVolumen(volId,carrito){    
    editarCantVolumen(volId,carrito,"AUMENTAR");
}
function disminuirVolumen(volId,carrito){
    editarCantVolumen(volId,carrito,"DISMINUIR");
}
function editarCantVolumen(volId,carrito,accion){
    carrito.forEach(proy=>{
        proy.volumenes.forEach(vol=>{
            if( volId == vol.id){
                if (accion === "AUMENTAR"){
                    
                    vol.cantidad ++;
                }else{
                    if(vol.cantidad !==0){
                        vol.cantidad --;
                    }
                }
            }
        })
    })
}
function actualizarTotal(carrito,volId,total){
    carrito.forEach(proy=>{
        proy.volumenes.forEach(vol=>{
            if( volId == vol.id){
                
                vol.total = total;
            }
        })
    })
}
export default carritoReducer;