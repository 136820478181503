const estadoInicial = {

    fotoSeleccionada:null,
    caption:"",
    cargando: false,
    errores: ""
}
 const fotosReducer = (state = estadoInicial, action) => {
    let res = {};
    switch(action.type){       
        case "SELECCIONAR_FOTO":     
            res = Object.assign({},state,{fotoSeleccionada:action.id})
            return res;
        case "ERROR_FOTO":     
            res = Object.assign({},state,{errores:action.valor})
            return res;
        case "LIMPIAR_ERRORES_FOTO":     
            res = Object.assign({},state,{errores:""})
            return res;
        case "CARGANDO_FOTOS":
            res = Object.assign({},state,{cargando:true});
            return res;
        case "CARGADO_FOTOS":
            res = Object.assign({},state,{cargando:false});
            return res;
        default:
            return state;
    }
}
export default fotosReducer;