import React, {Component} from "react";
import {connect} from "react-redux";
import Navegacion from "../Navegacion";
import iconDelete from "../../images/design_icon_delete.svg";

import {getDataAut, esDefinido} from "../../util/general";
import ModalSpinner from "../modales/ModalSpinner";
import {cargarCarrito} from "../../util/asyncCarrito";
import {ERROR_CARGAR_CARRITO, ERROR_EDITAR_CANTIDAD,ERROR_ELIMINAR_PROYECTO_CARRITO,
    ERROR_NO_VOLUMENES_CARRITO} from "../../util/errores";
import ModalesError from "../ModalesError";
import {aumentarCantidad, disminuirCantidad, eliminarProyecto} from "../../util/asyncCarrito";
import ModalConfirmacion from "../modales/ModalConfirmacion";
import Observer from "../../util/imageObserver";
import Button from "react-bootstrap/Button";
import RegistroLogin from "../RegistroLogin";
import ModalError from "../ModalError";
import VolumenTematicoCarrito from "../proyectos/VolumenTematicoCarrito";
const FOTOS_POR_VOLUMEN = 60;
class Carrito extends Component {
    constructor(props){
        super(props);
        this.state ={proyecto:0,errorEditar:""};
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        this.props.dispatch(cargarCarrito(dataAut));


    }
    componentDidUpdate(){
         const imgs = document.querySelectorAll('div[data-src]');
        imgs.forEach(img=>{
            Observer.observe(img);
        })
    }
    aumentarCantidad = (volId,cantidad,proy,vol)=>{
        if(!proy.agregado_titulo){
            this.setState({errorEditar:"Este proyecto no tiene configurado el texto a incluir en el lomo. Para poder agregarlo a la compra regresa a la pantalla de configuración para editarlo."})   
            return;
        }
        if(!vol.completo){
            this.setState({errorEditar:`Este volumen no cuenta con las ${FOTOS_POR_VOLUMEN} fotos necesarias y/o imagen de portada para su impresión. Para poder agregarlo a la compra regresa a editarlo y complétalo.`})
            return;
        }     
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        this.props.dispatch(aumentarCantidad(dataAut,volId,cantidad))
    }
    disminuirCantidad = (volId,cantidad,proy,vol)=>{
        if(!proy.agregado_titulo){
            this.setState({errorEditar:"Este proyecto no tiene configurado el texto a incluir en el lomo. Para poder agregarlo a la compra regresa a la pantalla de configuración para editarlo."})   
            return;
        }
        if(!vol.completo){
            this.setState({errorEditar:`Este volumen no cuenta con las ${FOTOS_POR_VOLUMEN} fotos necesarias y/o imagen de portada para su impresión. Para poder agregarlo a la compra regresa a editarlo y complétalo.`})
            return;
        }     
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        this.props.dispatch(disminuirCantidad(dataAut,volId,cantidad));
    } 
    eliminar =(proyId)=>{
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        this.setState({proyId:proyId});
        this.props.dispatch({type:"CONFIRMANDO", valor:"Desea Eliminar Este Proyecto Del Carrito?"});
    }
    confirmadoEliminar = ()=>{
        
        this.props.dispatch({type:"CONFIRMADO"});
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        this.props.dispatch(eliminarProyecto(dataAut,this.state.proyId));
    }
    listarProyectos = ()=>{
        return this.props.carrito.map(proyecto=>{
            
            return <div className = "proyectoCarrito" key = {"proy"+proyecto.id}>                
                <div className="proyecto-header">
                    <span>{proyecto.nombre}   </span>
                    <a className = "eliminarProyecto" onClick = {()=>{this.eliminar(proyecto.id)}}>
                        <img src = {iconDelete} alt = "Eliminar"/>
                                    <span className="d-none d-md-inline"> ELIMINAR</span>
                    </a>
                </div>
                <div className="proyecto-vols" >{this.listarVols(proyecto)}</div>

            </div>
        });
    }
    limpiarErroresCarrito=()=>{
        this.props.dispatch({type:"LIMPIAR_ERRORES_CARRITO"});

    }
    limpiarErroresCarga=()=>{
        this.props.history.push("/proyectos");
        this.props.dispatch({type:"LIMPIAR_ERRORES_CARRITO"});

    }
    modalesError=()=>{
        let modales = [];        
        modales.push(ERROR_CARGAR_CARRITO(
            this.props.errores === "CARGAR_CARRITO",
            this.limpiarErroresCarga));  
        modales.push(ERROR_EDITAR_CANTIDAD(
                this.props.errores === "EDITAR_CANTIDAD",
                this.limpiarErroresCarrito));    
        modales.push(ERROR_ELIMINAR_PROYECTO_CARRITO(
                this.props.errores === "ELIMINAR_PROYECTO",
                this.limpiarErroresCarrito));  
        modales.push(ERROR_NO_VOLUMENES_CARRITO(
                this.props.errores === "NO_VOLUMENES",
                this.limpiarErroresCarrito
        ))          
        return modales;
    }

    handleClickVolumen = (proy)=>{
        this.props.dispatch({type:"SELECCIONAR_PROYECTO",id:proy.id,nombre:proy.nombre});
        this.props.history.push("/proyectos/editar");
    }
    listarVols(proy){
        return proy.volumenes.map(vol=>{
            return <div className = "volumenCarrito" key ={"vol"+vol.id} >
                <div className = "infoVolumenCarrito" >
                    <div className="vol-info"> <span className="vol-num">Volumen {vol.num}</span>     
                        <span className="vol-price">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(vol.total)}</span></div>
                    
                
                </div>
                <div className="volumen-carrito-wrapper">
                    {sinPortada(proy.has_tematico,vol)?
                        <div className = "proyecto-libro">
                            <div className = "proyecto-libro-inner">
                            </div>
                        </div>
                        :
                        proy.has_tematico?
                            <VolumenTematicoCarrito url_forro = {vol.url_forro} 
                                onClick = {()=>{this.handleClickVolumen(proy)}}
                                id = {vol.id}/>
                                :
                        <div className = "proyecto-libro"  onClick = {()=>{this.handleClickVolumen(proy)}}>
                            
                            <div data-src = {vol.url}
                            identificador = {vol.id}
                            categoria ="portadasVol"
                            className = "proyecto-libro-inner">
                            </div>
                            
                        </div>
                        }
                    <div className="control-cant">
                        <div className="cant-less" onClick ={(ev)=>{this.disminuirCantidad(vol.id,vol.cantidad,proy,vol)}}>-</div>
                        <div className="cant-num" >{vol.cantidad}</div>
                        <div className="cant-plus" onClick = {(ev)=>{this.aumentarCantidad(vol.id,vol.cantidad,proy,vol)}}>+</div>
                    </div>
                </div>
            </div>
        });
    }
    handleContinuar = ()=>{
        let errores = this.validarCarrito();
        this.props.dispatch({type:"COMPRANDO"});
        if (errores.length === 0){
            
            this.props.history.push("/carrito/pago");
            this.props.dispatch({type:"PAGANDO"});
        }
        else{
            if(errores.includes("NO_VOLUMENES")){
                this.props.dispatch({type:"ERROR_CARRITO", valor:"NO_VOLUMENES"})
            }
            else{
                this.props.dispatch({type:"PRE_INICIAR_SESION",previo:"/carrito/pago"})
                this.props.dispatch({type:"PRE_REGISTRO",previo:"/carrito/pago"});
                this.requerirRegistroOLogin();
                //this.props.history.push("/cuenta_requerida");
            }
        }
    }
    requerirRegistroOLogin = ()=>{
        this.props.dispatch({type:"REQUERIR_REGISTRADO"})
    }
    atras = ()=>{
        this.props.history.goBack();
    }
    validarCarrito = ()=>{
        let errores = [];
        if(!esDefinido(this.props.token)){
            errores.push("NO_CUENTA")
        }
        let almenosUno = false;
        this.props.carrito.forEach(proy=>{
            proy.volumenes.forEach(vol=>{
                if(vol.cantidad>0){
                    almenosUno = true;
                }
            })
        })
        if(!almenosUno){
            errores.push("NO_VOLUMENES")
        }
        return errores;
    }
    render(){
        return (
        <div id = "inner-content-wrapper" className="d-flex flex-column">

            <RegistroLogin cerrarModal = {()=>{this.props.dispatch({type: "LIMPIAR_REQUERIR_REGISTRADO"})}}
                show = {this.props.requerirRegistrado}
                location = {this.props.location}/>
            <ModalSpinner
                show = {this.props.cargando}
                />
            <ModalConfirmacion
                mensaje ={this.props.mensaje}
                show = {this.props.confirmando}
                confirmar = {()=>this.confirmadoEliminar()}
                confirmarbtn = "Eliminar"
                cancelar = {()=>{this.props.dispatch({type:"CONFIRMADO"})}}
                 />
            <ModalError show = {this.state.errorEditar!==""} handleClose={()=>{this.setState({errorEditar:""})}} 
                titulo = "Volumen Incompleto" mensaje = {this.state.errorEditar}  
                    textoBoton= "ACEPTAR"/>
            <ModalesError 
                modales ={this.modalesError()}/>  
            <Navegacion/>

            <div className="title-bar">
                <span className="back-icon" onClick = {this.atras}> </span>
                MI CARRITO
            </div>
                       <div className="notice-bar invert">¡Envío gratis a partir de $500!</div>

        
            <div className = "carrito inner-content ">

            <a className = "btn btn-general btn-primary" onClick = {this.handleContinuar}>CONTINUAR</a>
          
            <div className="carrito-total"><span>TOTAL</span> <span>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(this.props.total)}</span></div>
            <div className="carrito-wrapper">{this.listarProyectos()}</div>
            </div>
        </div>);
    }
}
function sinPortada(hasTematico,vol){
    return (!hasTematico && vol.url==="") ||(hasTematico && vol.url_forro === "")
}
const mapStateToProps = (state)=>{
    return {
        carrito: state.carrito.carrito,
        errores: state.carrito.errores,
        cargando: state.carrito.cargando,
        confirmando: state.carrito.confirmando,
        mensaje: state.carrito.mensaje,
        total: state.carrito.total,
        token: state.clientes.token,
        uuid: state.clientes.uuid,
        requerirRegistrado: state.carrito.requerirRegistrado
    }
}
export default connect(mapStateToProps)(Carrito);