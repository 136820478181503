export const registrar = function (evento){

    let dataLayer = window.dataLayer || [];
    function gtag(){
        dataLayer.push(arguments);
    } 
    gtag("event",evento.accion,{
        event_category: evento.categoria, 
        event_action:evento.accion,
        event_label: evento.etiqueta, 
        event_value: evento.valor
    });
    
   }