const estadoInicial = {
    objetivo:0,
    subidas:0,
    subiendo:false,
    alertar:false,
    evaluadaCalidad:false,
    procesadas:0,
    procesando:false
}
 const seleccionarFotos = (state = estadoInicial, action) => {
    let res = {};
    switch(action.type){               
        case "INICIAR_SUBIDA_GP":   
            res = Object.assign({},state,{subiendo:true})            
            return res;
        case "TERMINAR_SUBIDA_GP":   
            res = Object.assign({},state,{subiendo:false})            
            return res;
        case "INICIAR_CARGA":
            res = Object.assign({},state,{procesando:true,objetivo:action.objetivo})
            //console.log("INICIAR CARGA");
            //console.log(res);
            return res;
        case "AUMENTAR_CUENTA_PROCESADAS":            
                let procesando = state.procesadas !== state.objetivo-1;
                let subiendo = state.procesadas === state.objetivo-1;
                //console.log("AUMENTAR CUENTA PROC");
                //console.log(procesando);
                //console.log(subiendo);
                res = Object.assign({},state,{procesadas:state.procesadas + 1,
                    procesando:procesando,
                    subiendo:subiendo});    
            return res;        
        case "AUMENTAR_CUENTA_SUBIDAS":            
            res = Object.assign({},state,{subidas:state.subidas + 1});    
            return res;        
        case "TERMINAR_CARGA":
            res = Object.assign({},state,{subiendo:false,procesando:false,objetivo:0,subidas:0,procesadas:0});
            return res;      
        case "ALERTA_CALIDAD":
            //console.log("ALERTA CALIDAD");
            res = Object.assign({},state,{alertar:true});
            return res;      
        case "OCULTAR_ALERTA_CALIDAD":
            res = Object.assign({},state,{alertar:false,evaluadaCalidad:true});
            return res;      
        case "LIMPIAR_ALERTA":
            res = Object.assign({},state,{evaluadaCalidad:false});
            return res;      
        
        default:
        return state;
    }
}
export default seleccionarFotos;