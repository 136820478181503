import React,{Component} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
class ModalError extends Component{
    render(){

        return(<Modal show={this.props.show} onHide={this.props.handleClose}>
        
        <Modal.Header closeButton={this.props.textoBoton!==""}>
            <Modal.Title>{this.props.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.mensaje}</Modal.Body> 
        {this.props.textoBoton !==""?
        <Modal.Footer>
            <Button variant="primary" onClick ={this.props.handleClose   }>{this.props.textoBoton}</Button>        
        </Modal.Footer>
        :null}                   
        </Modal>)
    }
}
export default ModalError;