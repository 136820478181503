import React,{Component} from "react";
import {connect} from "react-redux";
import {extraerMetadatos, reorientar} from "../../util/FotoBSP";
import {reqRegistrarFotos, reqCrearProyecto} from "../../util/proyectos";
import ProgressBar from "react-bootstrap/ProgressBar";
import {subirAlt,extraerExif} from "../../util/FotoBSP";
import { esDefinido,getDataAut } from "../../util/general";
import {MIN_QUALITY,renombrar,validar,obtenerImg} from "../../util/images";
import Alert from "react-bootstrap/Alert";
import Dropzone from 'react-dropzone';
import Navegacion from "../Navegacion";
import {ERROR_CALIDAD,ERROR_SUBIR_FOTOS, ERROR_ENTRADA_PROYECTO} from "../../util/errores"; 
import {    registrarFotos} from "../../util/asyncFotos";   
import ModalesError from "../ModalesError";
import PreviewImg from "./PreviewImg";
import overlay from "../../images/overlay-editor.png";
import Modal from "react-bootstrap/Modal";
import  {createRef} from 'react';
import {esMovil} from "../../util/movil";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import GooglePhotos from "./Integraciones/GooglePhotos";
import ModalErrorCodigo from "../modales/ModalErrorCodigo";
import {writeLog} from "../../util/logs";
const dropzoneRef = createRef();

const MAX_UPLOAD_SIZE_MOBILE = 150000000;
var overlayStyle = {
    backgroundImage: `url(${overlay})`
};


class SeleccionarFotos  extends Component{   
    constructor(props){
        super(props);
        this.state={
            fotos:[],
            errores:[],
            rechazadosSize:[],
            reorientadas:[],
            reorientadasImg:[],
            mostrarMaxSize:false,
            tab:"dispositivo"}
        if(!this.props.creando && !esDefinido(this.props.proyectoSeleccionado) ){
            this.props.dispatch({type:"ERROR_PROYECTO",valor:"ERROR"});
        }
    }
    handleClickProgramatically = ()=>{
        //console.log("HANDLING CLICK PROGRAMATICALLY");
        if(!esMovil()){
            //console.log("NO ES MOVIL");
            //console.log(dropzoneRef);

            if (dropzoneRef.current) {
                dropzoneRef.current.open()
            }
        }
        else{
            this.setState({mostrarMaxSize:true});
        }
    }
    aceptarMaxSize = ()=>{
        this.setState({mostrarMaxSize:false});
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    }
    dismissModal = ()=>{    
        this.props.dispatch({type:"VISITADO_OVERLAY",overlay:"visitadoSeleccionarFotos"});
    }
     actualizarPreview =  async (files)=>{
         
         if (files.length === 0){
            return;
         }
         let meta = [];
        this.setState(Object.assign({},this.state,{errores:[]}));
        /*let errores =[];// validar(files);
        if(errores.length > 0){
            console.log("HAY ERRORES");
            this.setState(Object.assign({},this.state,{errores:errores,fotos:[]}));
            return;
        }*/
        let promises = [];
        this.props.dispatch({type:"LIMPIAR_ALERTA"});
        let fotos = files;    
        await extraerExif(fotos);
        
        
        let p2s = []
        Array.from(fotos).forEach(async el=>{
            p2s.push(reorientar(el));
                    
        });
        let reorientadas  = await Promise.all(p2s);
        
        
        
        Array.from(reorientadas).forEach(async el=>{
              promises.push(obtenerImg(el));
                
        })


        this.setState(Object.assign({},this.state,
            {reorientadas:reorientadas,fotos:fotos}))
        Promise.all(promises).then(values =>{
            
            this.setState({reorientadasImg:values});
        });
    }
    atras= ()=>{
        if(this.props.creando){
            this.props.history.push("/proyectos");
        }
        else{
            this.props.history.push("/proyectos/editar")
        }
        //this.props.history.goBack();
    }
    registrarGooglePhotos = async (fotos)=>{
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        let that = this
            if(that.props.creando){                
                  let resp = await reqCrearProyecto(dataAut,that.props.tipoPasta);
                  
                  let proy = resp.data.proyecto_id;
                  that.props.dispatch({type:"SELECCIONAR_PROYECTO",id:resp.data.proyecto_id,nombre: ""})
            }
            that.registrarFotos(fotos);                
        
    }
    registrarFotos =(meta)=>{
        
        console.log("REGISTRANDO");
        console.log(meta);
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        
        this.props.dispatch(registrarFotos(dataAut,meta,this.props.proyectoSeleccionado,
            this.props.history, this.props.creando));
    }
    
    enviar = async (files,rejections)=>{  
        if (rejections.length > 0 && files.length === 0){            
            return;
         }
         if(esMovil()){
             //console.log("ES MOVIL");
             let filtradoMovil = filtrarMovil(files);
             if (filtradoMovil["rechazados"].length>0){
                //console.log("SUMA DE TAMANOS DE ARCHIVO DEMASIADO GRANDE");   
                this.setState({rechazadosSize:filtradoMovil["rechazados"]});              
                files = filtradoMovil["aceptados"];                
                    //console.log("RECHAZANDO");
                    //console.log(filtradoMovil["rechazados"]);                
             }
             if(filtradoMovil["aceptados"].length === 0){
                 return ;
             }
         }
         this.setState({errores:[]})
        this.props.dispatch({type:"INICIAR_CARGA",objetivo:files.length})
        let fotos = files;    
        
        
        let p2s = []        
        
        const that = this;        
        let dataAut = getDataAut(this.props.token,this.props.uuid);

        fotos = renombrar(fotos);      
       
        Promise.all([subirAlt(fotos,that.aumentarCuenta),extraerMetadatos(fotos,()=>{})])
        .then(async function(values){
                if(that.props.creando){                
                  let resp = await reqCrearProyecto(dataAut,that.props.tipoPasta);
                  
                  let proy = resp.data.proyecto_id;
                  that.props.dispatch({type:"SELECCIONAR_PROYECTO",id:resp.data.proyecto_id,nombre: ""})
            }
            that.registrarFotos(values[1]);                
        }).catch(err=>{
            console.log("ERROR ENVIAR");
            console.log(err.message);
            that.props.dispatch({type:"ERROR_SUBIR_FOTOS_CREAR_PROY",codigoError:err.message});
            that.props.dispatch({type:"TERMINAR_CARGA"});
            let payload = {
                error: err.message,
                function: "app/proyectos/SeleccionarFotos/enviar",
                browser:window.navigator.appVersion,
                
            }
            console.log(payload);
            writeLog(payload).then(resp=>console.log("SE HA CREADO LOG")).catch(err2=>{
                console.log("no se pudo crear log")
                console.log(err2);
            });
        });        
    }
    aumentarCuenta = (tipo)=>{
        if(tipo === "procesadas")
            this.props.dispatch({type:"AUMENTAR_CUENTA_PROCESADAS"})
        else
            this.props.dispatch({type:"AUMENTAR_CUENTA_SUBIDAS"})
    }
    preview=()=>{
        if(this.state.fotos.length == 0){
            return null;
        }
        let that = this;
        let arr =this.state.reorientadasImg.map( (el,index)=>{                      
            let alertar = el.naturalHeight < MIN_QUALITY || el.naturalWidth < MIN_QUALITY;            
            if (alertar) this.props.dispatch({type:"ALERTA_CALIDAD"});
            return <PreviewImg
                key = {index} alertar = {this.props.alertar}
                src = {el.src}/>
                            
        })
        return arr;
    }
    listarErrores = ()=>{
       return  this.state.errores.map((el,index)=>{
            return <Alert variant = "danger" key = {index}> {el} </Alert>
        })
    }
    listarRechazadosSize = ()=>{
        let lis =   this.state.rechazadosSize.map((el,index)=>{
             return <li  key = {index}> {`${el}`} </li>
         })
         if(lis.length === 0){
             return null;
         }
         return <Alert variant ="danger">
             <Alert.Heading>Los siguientes archivos no se pudieron subir para cumplir con el tamaño total máximo de subida:</Alert.Heading>
             <ul>
                 {lis}
             </ul>
         </Alert>
     }
    closeModal = ()=>{
        this.props.dispatch({type:"OCULTAR_ALERTA_CALIDAD"});
    }
    limpiarErroresValidacion=()=>{
        this.setState(Object.assign({},this.state,{errores:[]}));
    }
    modalesError=()=>{
        let modales = [];        
        modales.push(ERROR_CALIDAD(
            this.props.alertar && !this.props.evaluadaCalidad,
            this.closeModal));
        modales.push(ERROR_SUBIR_FOTOS(
            this.props.errores === "SUBIR_FOTOS",
            ()=>{
                this.props.dispatch({type:"OCULTAR_ERROR_CREAR"});
                this.props.dispatch({type:"LIMPIAR_ERRORES_PROYECTO"});
                this.props.dispatch({type:"TERMINAR_CARGA"});
            }))
        modales.push(ERROR_ENTRADA_PROYECTO(
            this.props.errores === "ERROR",
            ()=>{
                this.props.dispatch({type:"LIMPIAR_ERRORES_PROYECTO"});
                this.props.history.push("/proyectos");
            }
        ))        
        return modales;        
    }
    atras = ()=>{
        this.props.history.goBack();
    }
    dropAccepted = (files)=>{
        
    }
    dropRejected = (files)=>{
        let errores = [];
        
        
        for(let x = 0; x<files.length; x++){
            errores.push(`${files[x].name} no es un archivo válido`);
        }
        this.setState({errores:errores});
    }
    selectTab = (key)=>{

        
        this.setState({tab:key});
    }
    render(){
        let cantidad = this.props.procesando? this.props.procesadas:this.props.subidas;
        
        let porcentajeProcesadas = this.props.objetivo === 0? 0: Math.round(100*this.props.procesadas/this.props.objetivo);
        let porcentajeSubidas =  this.props.objetivo === 0? 0: Math.round(100*this.props.subidas/this.props.objetivo);
        const baseStyle = {
            width: 200,
            height: 200,
            borderWidth: 2,
            borderColor: 'grey',
            borderStyle: 'dashed',
            borderRadius: 5
          };
          
        return(
        <div>            
            <Navegacion location = {this.props.location.pathname}/>

            <div id = "inner-content-wrapper">
                <Modal show = {this.state.mostrarMaxSize}>
                    <Modal.Header>ALERTA</Modal.Header>
                    <Modal.Body>
                    Por razones de rendimiento solo puedes subir máximo 150Mb de fotografías a la vez desde tu celular. 
                    Te avisaremos si no podemos subirlas todas para que lo intentes en otra carga.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick = {this.aceptarMaxSize}>ACEPTAR</Button>
                    </Modal.Footer>
                </Modal>
                <ModalesError 
                    modales ={this.modalesError()}/>       
                <ModalErrorCodigo 
                    show ={this.props.errorCrear === "SUBIR_FOTOS"}
                    titulo = "ERROR"
                    textoBoton = "ACEPTAR"
                    mensaje = {"Ocurrió un error en la carga de fotos, intenta de nuevo. "}
                    codigo = {this.props.codigoError}
                    handleClose = {()=>{
                        this.props.dispatch({type:"OCULTAR_ERROR_CREAR"});
                        this.props.dispatch({type:"LIMPIAR_ERRORES_PROYECTO"});
                        this.props.dispatch({type:"TERMINAR_CARGA"});
                    }}
                    
                />
                <div className="title-bar">
                    <span className="back-icon" onClick = {this.atras}> </span> 
                    {this.props.creando?"Proyecto Nuevo": this.props.nombre}
                </div>
                <div className="subtitle-bar">
                    Selección de Fotos</div>
                <div className="inner-content">

                <Nav variant="tabs" defaultActiveKey="dispositivo"
                    activeKey = {this.state.tab}
                    onSelect = {this.selectTab}
                    >
                    <Nav.Item >
                        <Nav.Link disabled={this.props.subiendo||this.props.procesando}
                            eventKey = "dispositivo">Dispositivo</Nav.Link>
                    </Nav.Item>
                    <Nav.Item >
                       <Nav.Link disabled={this.props.subiendo || this.props.procesando}
                        eventKey = "google_photos">Google Photos</Nav.Link>
                    </Nav.Item>
                    
                </Nav>
                        {this.state.tab === "dispositivo"?
                        <div className = "uploadWrapper">
                        {this.props.procesando?
                        <div className="contUpload text-center">
                            
                            <p className="h3 text-weight-light mb-0">Paso 1/2 Procesando Fotos</p>
                            <div className="mb-4">Este proceso puede tardar varios minutos...</div>
                            
                            <div className="h1 label-porcentaje ">{porcentajeProcesadas}%</div>
                            <ProgressBar  now={porcentajeProcesadas}  />
                        </div>
                        :                                                
                        this.props.subiendo?
                        <div className="contUpload text-center">
                            
                            <p className="h3 text-weight-light mb-0">Paso 2/2 Subiendo Fotos</p>
                            <div className="mb-4" >Este proceso puede tardar varios minutos...</div>
                            
                            <div className="h1 label-porcentaje text-center">{porcentajeSubidas}%</div>
                            <ProgressBar  now={porcentajeSubidas}  />
                            
                        </div>
                        :
                            <Dropzone 
                            ref={dropzoneRef}
                            onDrop={this.enviar}
                        onDropAccepted = {this.dropAccepted}
                        onDropRejected = {this.dropRejected}
                        
                        accept = 'image/png,image/jpeg'
                        style = {baseStyle}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div {...getRootProps({onClick: (e)=>{
                                    e.stopPropagation()
                                    this.handleClickProgramatically();
                                }})} className="drop-area">
                                    <input {...getInputProps()} id ="selector" 
                                        />
                                    Arrastra aquí tus fotos (jpg o png), o <span className="link-style">haz click</span> para seleccionarlas
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    
                        }
                        
                      
                    <div className="mb-3">
                        {this.listarErrores()}
                        {this.listarRechazadosSize()}
                    </div>
                    
                    <div id = "rotadas"></div>
                    {!this.procesando && !this.props.subiendo && esDefinido(this.state.fotos) 
                        && this.state.fotos.length > 0 ?
                        <div className ="contPreview">

                            <p>Vista Previa de Fotos</p>
                            <div className = "preview">
                                {this.preview()}
                            </div>
                        
                        </div>
                    :
                        null
                    }
                    {!this.props.procesando && !this.props.subiendo && esDefinido(this.state.fotos) 
                        && this.state.fotos.length > 0 ?
                        <a className="btn-general btn btn-primary" onClick = {this.enviar}>ENVIAR FOTOS</a>
                    :
                        null
                    }
                    </div>

                    :
                        <GooglePhotos registrar =  {this.registrarGooglePhotos}/>
                    }
                    </div>
                </div>
            </div>)
    }
}
 
const mapStateToProps = (state)=>{
    return {
        proyectoSeleccionado:state.proyectos.proyectoSeleccionado,
        token: state.clientes.token,
        uuid: state.clientes.uuid,
        tipoPasta: state.proyectos.tipoPasta,
        subidas: state.seleccionarFotos.subidas,
        objetivo: state.seleccionarFotos.objetivo,
        subiendo: state.seleccionarFotos.subiendo,
        alertar: state.seleccionarFotos.alertar,
        evaluadaCalidad: state.seleccionarFotos.evaluadaCalidad,
        errorCrear: state.crearProyecto.error,
        codigoError: state.crearProyecto.codigoError,
        creando: state.crearProyecto.creando,
        errores: state.proyectos.errores,
        nombre: state.proyectos.nombre,
        procesando: state.seleccionarFotos.procesando,
        procesadas: state.seleccionarFotos.procesadas,
        visitadoSeleccionarFotos:state.clientes.visitadoSeleccionarFotos
    }
}
function filtrarMovil(files){
    let aceptados = [];
    let rechazados = [];
    let suma = 0;
    for (let x = 0; x<files.length; x++){
        suma += files[x].size;
        //console.log("ARCHIVO");
        //console.log(files[x].name);
        //console.log(files[x].size);
        //console.log(suma);
        //console.log("RESTAN");
        //console.log(MAX_UPLOAD_SIZE_MOBILE-suma);
        if (suma>MAX_UPLOAD_SIZE_MOBILE){
            rechazados.push(files[x].name);
            files[x] = null;
        }else{
            aceptados.push(files[x])
        }
    }
    //console.log("ACEPTADOS");
    //console.log(aceptados.length);
    return {aceptados,rechazados};
}
export default connect(mapStateToProps)(SeleccionarFotos);