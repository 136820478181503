import React,{Component} from "react";
import {connect} from "react-redux";
import Navegacion from "../Navegacion";
import Button from "react-bootstrap/Button";
import previewCapt from "../../assets/previewBook-capt@3x.png";
import previewCaptDate from "../../assets/previewBook-capt-date@3x.png";
import previewCaptDateLoc from "../../assets/previewBook-capt-date-loc@3x.png";
import previewCaptLoc from "../../assets/previewBook-capt-loc@3x.png";
import preview from "../../assets/previewBook-none@3x.png";
import previewDate from "../../assets/previewBook-date@3x.png";
import previewDateLoc from "../../assets/previewBook-date-loc@3x.png";
import previewLoc from "../../assets/previewBook-loc@3x.png";
import Alert from "react-bootstrap/Alert";
import {getDataAut} from "../../util/general";
import {cargarConfiguracion,guardarConfiguracion} from "../../util/asyncProyectos";
import { esDefinido } from "../../util/general";
import ModalesError from "../ModalesError";
import Modal from "react-bootstrap/Modal";
import overlay from "../../images/overlay-config.png";

import {ERROR_GUARDAR_CONFIG,ERROR_ENTRADA_PROYECTO,ERROR_CARGAR_CONFIG} from "../../util/errores";
    const previews = {
        previewCapt,previewCaptDate,previewCaptDateLoc,previewCaptLoc,
        preview,previewDate,previewDateLoc,previewLoc
    }
const SinTitulo = "Sin título";
var overlayStyle = {
    backgroundImage: `url(${overlay})`
};

class Configurar  extends Component{
    constructor(props){
        super(props);
        this.state = {valido:true,inicializado:false}
        if(!this.props.creando && !esDefinido(this.props.proyectoSeleccionado) ){
            this.props.dispatch({type:"ERROR_PROYECTO_INDEF"});
            return;
        }
        if(!this.props.creando)
            this.cargarConfiguracion();
        else{
            this.props.dispatch({ 
                type: "EDITAR_CONFIGURACION",
                mostrarUbicacion: false,
                mostrarFecha: false,
                mostrarCaption: false,
                nombre:"",
                tipoPasta: this.props.tipoPasta
            });
        }
        this.state = {valido:true,inicializado:false}
    }
    dismissModal = ()=>{    
        this.props.dispatch({type:"VISITADO_OVERLAY",overlay:"visitadoConfigurar"});
    }
    cargarConfiguracion = ()=>{
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        let cb =()=>{ this.setState(Object.assign({},this.state,{inicializado:true}))}        
        this.props.dispatch(cargarConfiguracion(dataAut,this.props.proyectoSeleccionado,cb));
    }
    guardarConfiguracion = ()=>{        
        if(!this.validar()){
            this.setState({valido:false})
            return;
        }
        let dataAut = getDataAut(this.props.token, this.props.uuid);
        let config = {
            mostrar_caption: this.props.mostrarCaption,
            mostrar_ubicacion: this.props.mostrarUbicacion,
            mostrar_fecha: this.props.mostrarFecha,
            nombre: this.props.nombre,
            pasta:this.props.tipoPasta,        
        } 
        this.props.dispatch(guardarConfiguracion(dataAut,this.props.proyectoSeleccionado,config,this.props.history));
    }
    validar = ()=>{
        return this.props.nombre.length > 0;
    }
    atras = ()=>{
        if(this.props.creando){
            this.props.history.push("/proyectos");
        }
        else{
            this.props.history.push("/proyectos/editar")
        }
    }
    preview = ()=>{
        let st = "preview";
        st+= this.props.mostrarCaption?"Capt":"";
        st+= this.props.mostrarFecha?"Date":"";
        st+= this.props.mostrarUbicacion?"Loc":"";
        return <img src = {previews[st]} alt = "preview"/>
    }
    handleNombreProy = (ev)=>{
        if(ev.target.value.length <= 30)
            this.props.dispatch({type:"EDITAR_NOMBRE",nombre:ev.target.value})
    }
    toggleTipoPasta = ()=>{
        this.props.dispatch({type:"TOGGLE_TIPO_PASTA"});
    }
    toggleUbicacion = ()=>{
        this.props.dispatch({type:"TOGGLE_MOSTRAR_UBICACION"});
    }
    toggleFecha = ()=>{
        this.props.dispatch({type:"TOGGLE_MOSTRAR_FECHA"});
    }
    toggleCaption = ()=>{
        this.props.dispatch({type:"TOGGLE_MOSTRAR_CAPTION"});
    }
    getErrores = ()=>{
        let errores = [];
        errores.push(ERROR_GUARDAR_CONFIG(this.props.errores === "ERROR",
            el=>this.props.dispatch({type:"LIMPIAR_ERRORES_PROYECTO"})))
        errores.push(ERROR_ENTRADA_PROYECTO(this.props.errores === "ERROR_INDEF",
        ()=>{
            this.props.dispatch({type:"LIMPIAR_ERRORES_PROYECTO"});
            this.props.history.push("/proyectos");
        }));
        errores.push(ERROR_CARGAR_CONFIG(this.props.errores === "ERROR_CARGAR_CONFIG",
        ()=>{ 
            this.props.dispatch({type:"LIMPIAR_ERRORES_PROYECTO"});
            this.props.history.push("/proyectos");
        }))
        return errores;
    }
    render(){
        return(
    <div>
        <Navegacion/>
        <div id = "inner-content-wrapper" className="d-flex flex-column">
                <Modal show = {!this.props.visitadoConfigurar && esDefinido(this.props.uuid)} 
                    onHide = {this.dismissModal}
                className=" modal-overlay" style={overlayStyle}>
                
                </Modal>
            <ModalesError modales = {this.getErrores()}/>
            <div className="title-bar">
                <span className="back-icon" onClick = {this.atras}> </span>
                {this.props.creando? " PROYECTO NUEVO":this.props.nombre}
            </div>
            <div  className="subtitle-bar">Configuración General</div>
            <div className = "formConfig inner-content flex-column flex-lg-row">
                
                     
                    <div className = "previewConfig">{this.preview()}</div>
                    <div className="opcionesConfig">
                        <div className="form-group">
                            <label>NOMBRE DEL PROYECTO (texto en el lomo)</label>
                            <input type = "text" className="form-control"  value = {this.props.nombre} onChange = {this.handleNombreProy}/>
                            {this.state.valido?null:<Alert variant = "danger">El nombre del proyecto no puede estar vacío</Alert>}

                        </div>
                        <label className="cl-switch">
                            <span className="label">Pasta Dura(+$50 / Volumen)</span>
                            <input type = "checkbox" onChange = {this.toggleTipoPasta}
                                checked = {this.props.tipoPasta === "dura"}/>
                          <span className="switcher"></span>
                        </label>
                        
                        <label className="cl-switch">
                            <span className="label">Mostrar Caption en Página</span>
                            <input type = "checkbox" onChange = {this.toggleCaption} checked = {this.props.mostrarCaption}/>
                          <span className="switcher"></span>

                        </label>
                        <label className="cl-switch">
                            <span className="label">Mostrar Fecha en Página</span>
                            <input type = "checkbox" onChange = {this.toggleFecha} checked = {this.props.mostrarFecha}/>
                          <span className="switcher"></span>
                            

                        </label>
                        <label className="cl-switch">
                            <span className="label">Mostrar Ubicación en Página</span>
                            <input type = "checkbox" onChange = {this.toggleUbicacion} checked = {this.props.mostrarUbicacion}/>
                          <span className="switcher"></span>
                        </label>
                    </div>  
                 </div>
            <a onClick = {this.guardarConfiguracion} className="btn btn-primary btn-general"> CONTINUAR</a>
               
        </div>
    </div>);
    }
}
const mapStateToProps = (state)=>{
return {
        creando: state.crearProyecto.creando,
        nombre: state.proyectos.nombre===SinTitulo?"":state.proyectos.nombre,
        tipoPasta: state.proyectos.tipoPasta,
        mostrarFecha: state.proyectos.mostrarFecha,
        mostrarCaption: state.proyectos.mostrarCaption,
        mostrarUbicacion: state.proyectos.mostrarUbicacion,
        proyectoSeleccionado: state.proyectos.proyectoSeleccionado,
        token: state.clientes.token,
        uuid: state.clientes.uuid,
        errores: state.proyectos.errores,
        conectado:true,
        cargando: state.proyectos.cargando,
        visitadoConfigurar: state.clientes.visitadoConfigurar
        }
}
export default connect(mapStateToProps)(Configurar);