import React,{Component} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
class ModalConfirmacion extends Component{
    render(){
        return <Modal
        show = {this.props.show}>
        <Modal.Header closeButton={false}>
            <Modal.Title>{this.props.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.mensaje}</Modal.Body> 

        <Modal.Footer>
            <Button variant="primary" onClick={this.props.confirmar}>
                {this.props.confirmarbtn}
            </Button>
            <Button variant="secondary" onClick={this.props.cancelar}>
                Cancelar
            </Button>
      </Modal.Footer>
    </Modal>    
    }
}

export default ModalConfirmacion;