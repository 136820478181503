import React,{Component} from "react";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";    
import {connect} from "react-redux";
import { esDefinido } from "../util/general";
import Modal from "react-bootstrap/Modal";
class RegistroLogin extends Component{
    constructor(props){
        super(props);
        //if(this.props.comprando && esDefinido(this.props.token)){
        //    this.props.history.push("/carrito/pag//}
    }
    cerrarModal = ()=>{
        this.props.cerrarModal()
    }
    render(){        
        return (<Modal show = {this.props.show} aria-labelledby="contained-modal-title-vcenter"
        centered>
            <Modal.Body className="modal-login-register">
                <Link className="btn btn-secondary btn-block" to = "/registrar" onClick = {this.cerrarModal}>SOY UN NUEVO USUARIO</Link>
                <Link className="btn btn-secondary btn-block" to = {{pathname: '/login', state: { prevPath: this.props.location }}} onClick = {this.cerrarModal}
                    >YA ESTOY REGISTRADO EN BOOKSNAP</Link>
            </Modal.Body>
        </Modal>)
    }
}
const mapStateToProps = (state)=>{
    return {
        comprando:state.carrito.comprando,
        token: state.clientes.token
    }
}
export default connect(mapStateToProps)(RegistroLogin);