import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import PromoImg from "../../images/tresmasdiez.jpg";
class Promo extends Component{
    constructor(props){
        super(props);
        this.state = {mostrar:false}
    }
    componentDidMount(){
        let promo = localStorage.getItem("promo");
        if(promo){
            this.setState({mostrar:false});
        }
        else{
            this.setState({mostrar:true})
        }
    }
    onHide = ()=>{
        localStorage.setItem("promo","done");
        this.setState({mostrar:false});
    }
    render(){
        
        return (            
       <Modal show = {false} onHide ={this.onHide} dialogClassName="modal-lg modal-dialog-centered ">
           <Modal.Body>
               <img src= {PromoImg} />
           </Modal.Body>
       </Modal>
        )
    }
}
export default Promo;