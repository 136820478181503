import {request} from "./axiosReqs";
import config from "./config";
export async function reqGuardarLomo(dataAut,volId,textoLomo){
    let url = `${config.urlBackend}/volumenes/${volId}/lomo_inferior`;
    const headers = Object.assign({'content-type': 'application/json'},dataAut)
    const options = {
        method:"POST",
        headers: headers,    
        data:{texto_inferior_lomo:textoLomo},
            url
        };
    
    return request(options);
}
