import React, {Component} from "react";
import Button from "react-bootstrap/Button";

import {cargarDatos,guardarDatos} from "../../util/asyncCliente";
import {connect} from "react-redux";
import { getDataAut } from "../../util/general";
import ModalesError from "../ModalesError";
import Alert from "react-bootstrap/Alert";
import {ERROR_CARGAR_DATOS, ERROR_GUARDAR_DATOS} from "../../util/errores";
class Cuenta extends Component{
    constructor(props){
        super(props);
        this.state = {
            nombre:"",
            email:"",
            password:"",
            editando:false,
            errores:{}
        }
    }
    componentDidMount(){
        
        this.cargar();
    }
    cargar = ()=>{
        
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        
        this.props.dispatch(cargarDatos(dataAut));
    }
    handle=(campo,valor)=>{
        let copia = Object.assign({},this.state);
        copia[campo] = valor;
        this.setState(copia);
    }
    editar = ()=>{
        
        
        this.setState({
            editando:true,
            nombre:this.props.nombre,
            email:this.props.email
        });
        
    }
    guardar = ()=>{
        let dataAut = getDataAut(this.props.token,this.props.uuid);
        let datos = {
            nombre:this.state.nombre,
            email:this.state.email,
            password:this.state.password
        }

        let error = (err)=>{
            
            
                this.setState({errores:err});
        }
        this.props.dispatch(guardarDatos(dataAut,datos,()=>{this.setState({editando:false,errores: {}})},error));
        
    }
    cancelar = ()=>{
        this.setState({editando:false})
    }
    getModales = ()=>{
        let errores = [];
        errores.push(ERROR_CARGAR_DATOS(this.props.errores === "CARGAR_DATOS",()=>{
            this.props.dispatch({type:"LIMPIAR_ERRORES_CLIENTE"});
            this.props.history.goBack();
        }));
        errores.push(ERROR_GUARDAR_DATOS(this.props.errores === "GUARDAR_DATOS",()=>{
            this.props.dispatch({type:"LIMPIAR_ERRORES_CLIENTE"});
        }));
        return errores;
    }
    alertas = ()=>{
        let nombres = {password:"Contraseña", email:"Correo Electrónico", nombre:"Nombre"};

        return Object.keys(this.state.errores).map(categoria=>{
            return this.state.errores[categoria].map(error=>{
                return <Alert variant = "warning">{`${nombres[categoria]}  ${error} `}</Alert>
            })
        }).flat();
    }
    render(){
        return (
            <div id = "inner-content-wrapper" class="d-flex flex-column">

                     <div className="title-bar">
                        <span className="back-icon" onClick = {this.atras}> </span> 
                        MI CUENTA 
                    </div>

            <ModalesError modales = {this.getModales()}/>
            

            <div className=" inner-content login d-flex flex-column flex-fill justify-content-center align-items-center">
                <div className="form-wrapper">
                    <div className="form-group"> 
                    <label>NOMBRE</label>
                    {!this.state.editando?
                         <input className="form-control disabled" type = "text" value = {this.props.nombre}  onClick = {this.editar}/>   
                    :
                        <input className="form-control" type = "text" value = {this.state.nombre} onChange = {(ev)=>this.handle("nombre",
                            ev.target.value)}/>                
                    }
                    </div>
                    <div className="form-group"> 
                    <label>CORREO ELECTRÓNICO</label>
                    {!this.state.editando?
                        <input className="form-control disabled" type = "text" value = {this.props.email}  onClick ={()=>this.editar("Email")}/>   
                    :
                        <input className="form-control" type = "text" value = {this.state.email} onChange = {(ev)=>this.handle("email",
                            ev.target.value)}/>                
                    }
                    </div>
                    <div className="form-group"> 
                    <label>CONTRASEÑA</label>
                    {!this.state.editando?
                        <input className="form-control disabled" type = "text" value = "********"  onClick ={()=>this.editar("Password")}/>   

                    :
                        <input className="form-control" type = "password" value = {this.state.password} onChange = {(ev)=>this.handle("password",      
                            ev.target.value)}/>
                    }
                    </div>
                    {this.alertas()}

 
                    

                    {this.state.editando?
                        <div className="row">
                            <div className="col">
                            <Button className='btn-block btn-lg btn btn-primary ' onClick = {this.guardar}>GUARDAR</Button>
                            </div>
                            <div className="col">                            
                            <Button className='btn-block btn-lg btn btn-primary ' onClick = {this.cancelar}>CANCELAR</Button>
                            </div>
                        </div>

                    :
                        <div className="row">
                            <div className="col">
                            <Button className='btn-block btn-lg btn btn-primary ' disabled>GUARDAR</Button>
                            </div>
                            <div className="col">                            
                            <Button className='btn-block btn-lg btn btn-primary ' disabled>CANCELAR</Button>
                            </div>
                        </div>
                        
                    }
                </div>
                
            </div>
        </div>)
    }
}
const mapStateToProps = (state)=>{
    return {
        token:state.clientes.token,
        uuid:state.clientes.uuid,
        nombre: state.clientes.nombre,
        email: state.clientes.email,
        errores: state.clientes.errores
    }
}
export default connect(mapStateToProps)(Cuenta);