import React,{Component} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "../images/logo-small.svg";
import iconoMenu from "../images/icon-menu.png";
import iconoCarrito from "../images/icon-cart.png";
import { LinkContainer } from 'react-router-bootstrap';
import {connect} from "react-redux";
class Navegacion extends Component{    
    constructor(props){
        super(props);
        this.state = {mostrarMenu:false};
    }
    toggle= ()=>{
        this.props.dispatch({type:"TOGGLE_SIDEBAR"})
    }
    render(){
        return <Navbar expand="lg">

            <Nav.Link href = "#" id="menu-toggle" onClick = {this.toggle}><img src={iconoMenu} alt = "icono-menu"/></Nav.Link>
            <Navbar.Brand href="#home"><img src = {logo} alt = "Booksnap"/></Navbar.Brand>
            <Nav.Link href = "#carrito"><img src={iconoCarrito} alt = "icono-carrito"/></Nav.Link>
       </Navbar>;
    }
}
const mapStateToProps =(state)=>{
    return {token: state.clientes.token}
}
export default connect(mapStateToProps)(Navegacion);