import React,{Component,createRef} from "react";
import Modal from "react-bootstrap/Modal";
import lomoBackground from "../../assets/preview-big-lomo@3x.png";
const ref = createRef();
let lastScrollY = 0;
let ticking = false;
class PreviewLomo extends Component{
    constructor(props){
        super(props);
        this.state = {
            width:0,
            height:0,
            bottom:0
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
      }
    
      componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
      }
      handleScroll = () => {
        let width = 0;
        let bottom = 0;
        let cur = ref.current;
        
        
        if(cur){
            let rect = cur.getBoundingClientRect()
             width = rect.left-37; //Math.round((cur.parentElement.clientWidth))
             bottom = rect.top + 368;
        }
        if(width!==this.state.width|| bottom !== this.state.bottom)
            this.setState({width:width,bottom:bottom});
      }
    componentDidUpdate(){
        let width = 0;
        let bottom = 0;
        let cur = ref.current;
        
        
        if(cur){
            let rect = cur.getBoundingClientRect()

             width = rect.left-37; //Math.round((cur.parentElement.clientWidth))
             bottom = rect.top + 368;
        }
        if(width!==this.state.width|| bottom !== this.state.bottom)
            this.setState({width:width,bottom:bottom});
    }
    render(){

        return <Modal show = {this.props.showPreviewLomo}
                    onHide = {this.props.cerrarPreviewLomo} dialogClassName="modal-lg">
                    <Modal.Header closeButton>Preview Lomo</Modal.Header>
                    <Modal.Body >
                        <div className="img-preview-lomo">
                        <p className ="nombre-preview-lomo"
                             
                        >{this.props.nombreProy}</p>
                        <p  

                                          
                        className = "texto-preview-lomo">{this.props.textoLomo}</p>
                        </div>
                        
                        
                        
                        
                        
                    </Modal.Body>
                </Modal>
    }
}

export default PreviewLomo;