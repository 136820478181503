import config from "../config";
import {generarCodigoError} from "../const";
import {writeLog} from "../logs";
export let gapi = {};
 let GoogleAuth = {};
const SCOPE = 'https://www.googleapis.com/auth/photoslibrary.readonly';
export function handleClientLoad(onSignInUpdate) {
    // Load the API's client and auth2 modules.
    // Call the initClient function after the modules load.
    return new Promise((resolve,reject)=>{
        gapi = window.gapi;
        gapi.load('client:auth2',()=>{ 
            
            initClient().then(resp=>{

                    console.log("GAPI SET");
                    GoogleAuth = gapi.auth2.getAuthInstance();
            
                    // Listen for sign-in state changes.
                    //GoogleAuth.isSignedIn.listen(onSignInUpdate);
            
                    // Handle initial sign-in state. (Determine if user is already signed in.)
                    var user = GoogleAuth.currentUser.get();
                    //setSigninStatus();

                    resolve(GoogleAuth.isSignedIn.get());
                    // Call handleAuthClick function when user clicks on
                    //      "Sign In/Authorize" button.

            })
        .catch(err=>{
            console.log("ERROR INIT CLIENT");
            console.log(err);
            let payload = {
                error:err,
                function: "util/google_api/auth/handleClientLoad",
                browser:window.navigator.appVersion,

            }
            
            writeLog(payload).then(resp=>console.log("SE HA CREADO LOG")).catch(err2=>{
                console.log("no se pudo crear log")
                console.log(err2);
            }
                
                );
            reject(new Error(generarCodigoError("googlePhotos","setup","conexionApi")));
        })});
    });    
}
function getSigninStatus(){
    var user = GoogleAuth.currentUser.get();
    var isAuthorized = user.hasGrantedScopes(SCOPE);
}
  function setSigninStatus(isSignedIn) {
    var user = GoogleAuth.currentUser.get();
    var isAuthorized = user.hasGrantedScopes(SCOPE);
    console.log(user);
    console.log(isAuthorized);
  }
  function updateSigninStatus(isSignedIn) {
    setSigninStatus();
  }
  function initClient(){    
    return gapi.client.init({
        apiKey: config.googlePhotos.apiKey  ,
        discoveryDocs: ["https://people.googleapis.com/$discovery/rest?version=v1"],
        clientId: config.googlePhotos.clientId,
        scope: SCOPE
    })
}

export function signIn(){
    if(!GoogleAuth.isSignedIn.get()){
        return GoogleAuth.signIn()
    }
    else return new Promise((resolve,reject)=>{
        
        resolve("");
    })
}
export function signOut(){
    if(GoogleAuth.isSignedIn.get()){
        return GoogleAuth.signOut()
    }
    else return new Promise((resolve,reject)=>{
        resolve("");
    })
}
function revokeAccess() {
    GoogleAuth.disconnect();
}
