import { combineReducers } from 'redux'
import clientesReducer from "./clientesReducer";
import proyectosReducer from "./proyectosReducer";
import seleccionarFotos from "./seleccionarFotos";
import crearProyecto from "./crearProyecto";
import fotosReducer from './fotosReducer';
import carritoReducer from './carritoReducer';
import navegacionReducer from "./navegacionReducer";
import forrosTematicosReducer from "./forrosTematicosReducer";
import appReducer from "./appReducer";
const mainReducer = combineReducers( {
 clientes:clientesReducer,
 proyectos:proyectosReducer,
 seleccionarFotos:seleccionarFotos,
 fotos: fotosReducer,
 crearProyecto: crearProyecto,
 carrito: carritoReducer,
 navegacion: navegacionReducer,
 forrosTematicos: forrosTematicosReducer,
 app: appReducer
})

export default mainReducer;